import { FC, useEffect, useState } from 'react';
import { Button } from 'elements';
import Cookies from "js-cookie";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styled';

import { ReactComponent as LockIcon } from "./assets/lock.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";

// @ts-ignore
import { useToken } from 'utils/utility';

// @ts-ignore
// import { SIGN_IN_PATH } from "utils/routes";

import { CloneDecision } from 'redux/DecisionMatrix/CloneDecision/action';

interface IProps {
  id: string;
  isCloning: string;
}

const ViewOnly: FC<IProps> = ({ id, isCloning }) => {
  const { token } = useToken();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isCloning) {
      setIsLoading(true);

      dispatch(CloneDecision({ hashed_decision_id: id }, data => {
        navigate(`/decision-manager/edit-decision/${data?.id}`, { replace: true });
      }));
    }
  }, [isCloning]);

  const handleClick = () => {
    if (!token) {
      Cookies.set("dm-to-clone", id);
      navigate(`/sign-in?app=dm`);
    }
    else {
      setIsLoading(true);

      dispatch(CloneDecision({ hashed_decision_id: id }, data => {
        navigate(`/decision-manager/edit-decision/${data?.id}`, { replace: true });
      }));
    }
  }

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.Col>
          <LockIcon />
          <Styled.Title>View Only</Styled.Title>
        </Styled.Col>

        <Button onClick={handleClick} disabled={isLoading}><CopyIcon />{isLoading ? "Copying..." : "Make a copy"}</Button>
      </Styled.Container>
    </Styled.Wrapper>
  );
}

export default ViewOnly;
