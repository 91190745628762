import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as yup from "yup";

import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeSlashIcon } from "assets/svg/eye-slash.svg";

import { ErrorFocus } from "../../../utils/ErrorFocus";
import useWindowSize from "../../../utils/useWindowSize";
import { login, resetBlockAuth } from "../../../modules/actions/AuthActions";

import * as Layout from "../styled";
import * as Styled from "./styled";
import { BREAKPOINTS } from "../../../theme/mixins";
import { BackNavigate } from "../Components/BackNavigate";

import { FORGOT_PASSWORD_PATH, SIGN_UP_PATH } from "utils/routes";

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const appParam = queryParams.get("app");

  const { width } = useWindowSize();

  const {
    login: { loading },
  } = useSelector(({ auth }) => auth);

  const [animate, setAnimate] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  /* useEffect(() => {
    if (!appParam || (appParam !== "pp" && appParam !== "dm")) {
      navigate("/");
    }
  }, [appParam]); */

  useEffect(() => {
    if (token) {
      dispatch(login(null, navigate, token, appParam));
    }
  }, [token, appParam]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        dispatch(resetBlockAuth({ blockType: "login" }));
      }, 3000);
    }
  }, [loading]);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  const $form = useRef(null);

  const onSubmitHandle = (form) => {
    dispatch(login(form, navigate, null, appParam));
  };

  const onPasswordVisibleClickHandle = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Layout.Screen>
      <BackNavigate to="/" />

      <Layout.Column $first>
        <Styled.FormWrapper>
          <Layout.Title $animate={animate} $animateDelay={100}>
            Great to see you again
          </Layout.Title>

          <Formik
            validationSchema={schema}
            onSubmit={onSubmitHandle}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            innerRef={$form}
          >
            {({ handleSubmit, handleReset, handleChange, values, errors }) => (
              <Styled.FormStyled noValidate onSubmit={handleSubmit}>
                <Styled.FormGroup $animate={animate} $animateDelay={150}>
                  <Styled.Label className="authLabel">Email*</Styled.Label>
                  <div className="flex-grow-1 position-relative">
                    <Styled.TextBoxs
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={errors.email}
                      aria-label="Email"
                    />
                    <Styled.FormError>{errors.email}</Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.FormGroup
                  className="mb-0"
                  $animate={animate}
                  $animateDelay={200}
                >
                  <Styled.Label className="d-flex align-items-center justify-content-between">
                    Password*
                  </Styled.Label>
                  <div className="flex-grow-1 position-relative w-100">
                    <Styled.TextBoxs
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={errors.password}
                      aria-label="Password"
                      data-testid="password-input"
                    />
                    <Styled.Eye
                      className="form-control-eye"
                      type="button"
                      onClick={onPasswordVisibleClickHandle}
                    >
                      {passwordVisible ? <EyeSlashIcon /> : <EyeIcon />}
                    </Styled.Eye>
                    <Styled.FormError>{errors.password}</Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.PasswordLink $animate={animate} $animateDelay={250}>
                  <Link to={`${FORGOT_PASSWORD_PATH}?app=${appParam}`}>
                    Forgot password?
                  </Link>
                </Styled.PasswordLink>

                <Styled.SubmitWrapper $animate={animate} $animateDelay={300}>
                  <Styled.SubmitStyled
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Log In
                  </Styled.SubmitStyled>
                </Styled.SubmitWrapper>

                <ErrorFocus />
              </Styled.FormStyled>
            )}
          </Formik>

          <Styled.LinkboxStyled $animate={animate} $animateDelay={350}>
            <Link to={`${SIGN_UP_PATH}?app=${appParam}`}>
              Create an Account
            </Link>
          </Styled.LinkboxStyled>
        </Styled.FormWrapper>
      </Layout.Column>

      {width > BREAKPOINTS.sm && (
        <Layout.Column $second $login>
          <Styled.Wrapper $animate={animate} $app={appParam}>
            {appParam === "pp" && (
              <img src="https://i.ibb.co/x7QctV5/project-Planner.png" alt="" />
            )}
            {appParam === "dm" && (
              <img src="https://i.ibb.co/g6J06MV/decision-Matrix.png" alt="" />
            )}
          </Styled.Wrapper>
        </Layout.Column>
      )}
    </Layout.Screen>
  );
};

export default SignIn;
