import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as CupIcon } from "./cup.svg";
import { ReactComponent as PlaceholderImg } from "./placeholder.svg";
import { AppLoading } from "components/AppLoading";

import lottie from "./lottie.json";
import classes from "./styles.module.scss";
import { Modal } from "antd";

const WinnerModal = ({ onClose }) => {
  // const { success: userDecisions } = useSelector(
  //   (state) => state.getDecisionReducer
  // );
  const { success: decision, loading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const { success: userOption } = useSelector(
    (state) => state.getOptionReducer
  );
  // const { userDecisions, userOption } = useSelector((state) => state.decision);

  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPlayAnimation(true);
    }, 150);
  }, []);

  // const decision = userDecisions?.find((d) => d.id === +decisionId);
  const optionId = decision?.final_option?.id;
  // const option = userOption?.find((o) => o.id === optionId);
  const option = decision?.final_option;
  if (!optionId && !option) return false;

  // const { name, adjusted_score, weighted_score, image } = option;

  return (
    <>
      <Modal
        open={true}
        footer={null}
        bodyStyle={{ padding: "0" }}
        closable={null}
        width={744}
        centered
      >
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <span>
              <CupIcon />
              Winner
            </span>
            <button
              type="button"
              className={classes.close}
              onClick={onClose}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>

          <div className={classes.body}>
            {loading ? (
              <AppLoading />
            ) : (
              <>
                <h1 className={classes.decision}>{decision?.problem}</h1>

                <div className={classes.content}>
                  {option?.image ? (
                    <img
                      className={classes.img}
                      src={option?.image}
                      width={369}
                      alt="img"
                    />
                  ) : (
                    <PlaceholderImg
                      className={classes.img}
                      width={369}
                      height={369}
                    />
                  )}

                  <p className={classes.option}>{option?.name}</p>

                  <div className={classes.scores}>
                    <p>
                      Adjusted score: <span>{option?.adjusted_score}</span>%
                    </p>
                    <p>
                      Average score: <span>{option?.weighted_score}</span>
                    </p>
                  </div>

                  {playAnimation && (
                    <div className={classes.lottie}>
                      <Lottie animationData={lottie} loop={false} />
                    </div>
                  )}

                  <p className={classes.copyright}>@arootahcoach</p>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { WinnerModal };
