import React from "react";
import styles from "./ChooseDecisionHeader.module.scss";
import { ReactComponent as ChevronIcon } from "./chevron.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ChooseDecisionHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      className={styles.header}
      style={{
        backgroundImage: `url(https://res.cloudinary.com/talaash/image/upload/v1676390506/Header_ws5ree.png)`,
      }}
    >
      <div className={styles.container}>
        <button
          className={styles.button}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronIcon />
        </button>
        <div className={styles.title}>
          {location?.pathname.includes("/decision-manager/edit-decision")
            ? "Edit Decision"
            : "Create Decision"}
        </div>
      </div>
    </div>
  );
};

export default ChooseDecisionHeader;
