import React, { useEffect, useState } from "react";
import classes from "./addOption.module.scss";

import { ReactComponent as CloseIcon } from "./close.svg";

import { useDispatch } from "react-redux";

import { useLocation, useParams } from "react-router-dom";
import useWindowSize from "../../../../utils/useWindowSize";
import { AddOptionRows } from "redux/DecisionMatrix/AddOptionRows/action";
import { UpdateOption } from "redux/DecisionMatrix/UpdateOptions/action";

const AddOptionLink = ({
  value,
  setIsAddOptions,
  setIsAddCriteria,
  setIsAddLink,
  option,
  setOption,
  isShortList,
}) => {
  const { decisionId, optionId } = useParams();
  const [valueInput, setValue] = useState("");
  // const [valueOptionInput, setOptionValue] = useState(0);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  pathname = pathname.split("/");
  useEffect(() => {
    if (value === "link") {
      setValue(option?.link || "");
    }
  }, [option]);

  const onClose = () => {
    if (value === "option") {
      setIsAddOptions(false);
    }
    if (value === "criteria") {
      setIsAddCriteria(false);
    }
    if (value === "link") {
      setIsAddLink(false);
    }
  };

  const onSave = (event) => {
    event.preventDefault();

    if (value === "option") {
      // dispatch(assignOption({decision: decisionId, name: valueInput}))
      dispatch(
        AddOptionRows({ num_rows: valueInput, decision_id: decisionId })
      );
      setIsAddOptions(false);
    }

    if (value === "link") {
      dispatch(
        UpdateOption({ id: optionId, body: { link: valueInput } }, isShortList)
      );
      setOption({ ...option, link: valueInput });
      setIsAddLink(false);
    }

    // if(value === 'criteria'){
    //
    //     dispatch(assignCriteria({decision:decisionId,title:valueInput}))
    //    close()
    //
    // }
  };
  return (
    <div className={classes.bg}>
      <form className={classes.wrapper} onSubmit={onSave}>
        <div className={classes.header}>
          <span>
            {option?.link ? "Edit" : "Add"} {value}
          </span>
          <button className={classes.close} type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classes.body}>
          <div style={{ color: "black" }}>
            {value === "option"
              ? " No.of rows"
              : value === "criteria"
              ? ""
              : "Link"}
          </div>
          <div>
            <input
              value={valueInput}
              onChange={(e) => setValue(e.target.value)}
              type={pathname.includes("option") ? "url" : "number"}
              max={5}
              min={1}
              placeholder={
                value === "option"
                  ? "Enter no. of options you want to add"
                  : value === "criteria"
                  ? "Enter criteria"
                  : "Enter URL of option"
              }
            />
          </div>
        </div>
        {width > 767 ? (
          <div className={classes.button}>
            <button>
              {option?.link ? "EDIT" : "ADD"}
            </button>
          </div>
        ) : (
          <div className={classes.buttonMob}>
            <button>{option?.link ? "EDIT" : "ADD"}</button>
          </div>
        )}
      </form>
    </div>
  );
};

export { AddOptionLink };
