import {
  GET_PROJECT_OWNER_REQUEST,
  INVITE_OWNER_REQUEST,
  CLOSE_INITIAL_SETUP_MODAL,
  REGISTER_FSM_DEVICE_REQUEST,
  GET_PROJECT_NOTIFICATIONS_REQUEST,
  READ_PROJECT_NOTIFICATION_REQUEST,
  READ_ALL_PROJECT_NOTIFICATION_REQUEST,
} from "../reducers/UserReducer";

export const getProjectOwners = (payload, plan) => ({
  type: GET_PROJECT_OWNER_REQUEST,
  payload,
  plan,
});

export const inviteOwner = (payload, handleOwnerModal, ownerPayload) => ({
  type: INVITE_OWNER_REQUEST,
  payload,
  handleOwnerModal,
  ownerPayload,
});

export const registerFSMDevice = (payload) => ({
  type: REGISTER_FSM_DEVICE_REQUEST,
  payload
});

export const readProjectNotification = (payload) => ({
  type: READ_PROJECT_NOTIFICATION_REQUEST,
  payload,
});

export const readAllProjectNotification = () => ({
  type: READ_ALL_PROJECT_NOTIFICATION_REQUEST,
});

export const getProjectNotifications = () => ({
  type: GET_PROJECT_NOTIFICATIONS_REQUEST,
});

export const closeInitialSetupModal = () => ({
  type: CLOSE_INITIAL_SETUP_MODAL,
});
