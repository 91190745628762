import { Popover } from "antd";
import { useProject } from "utils/utility";

import { ReactComponent as AppsIcon } from "assets/svg/appSwitcher.svg";
import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg";
import { ReactComponent as LogoGPS } from "assets/images/auth/logoGPS.svg";
import { ReactComponent as LogoHC } from "assets/images/auth/logoHC.svg";

import * as Styled from './styled';
import customStyles from './global.module.scss';

const getPath = () => {
  const location = window.location.href;

  // Array of staging urls
  const validURLs = [
    'project-planner-36424-staging.botics.co',
    'localhost',
    '127.0.0.1'
  ];

  if (process.env.NODE_ENV === 'development' || validURLs.some(url => location.includes(url))) {
    return {
      hcURL: 'hat-arootah-web-24408-staging.botics.co',
      gpsURL: 'goal-setting-workbo-37-staging.botics.co'
    }
  }

  return {
    hcURL: 'app.arootah.com',
    gpsURL: 'goals.arootah.com'
  }
}

const AppSwitcher = ({ app }) => {
  const { project } = useProject();
  const activeProject = project || app || null;

  const { hcURL, gpsURL } = getPath();

  return (
    <Popover
      overlayClassName={customStyles.popover}
      placement="bottomLeft"
      padding={0}
      content={
        <Styled.Box>
          <Styled.AppsList>
            <Styled.AppItem to="/decision-manager" $active={activeProject === 'decision-manager'}>
              <LogoDM />
              <Styled.AppItemTitle>Decision Coach</Styled.AppItemTitle>
            </Styled.AppItem>

            <Styled.AppItem to="/project-planner" $active={activeProject === 'project-planner'}>
              <LogoPP />
              <Styled.AppItemTitle>Project Manager</Styled.AppItemTitle>
            </Styled.AppItem>

            <Styled.AppLink href={`https://${hcURL}`} /* target="_blank" */>
              <LogoHC />
              <Styled.AppItemTitle>Habit Coach</Styled.AppItemTitle>
            </Styled.AppLink>

            <Styled.AppLink href={`https://${gpsURL}`} /* target="_blank" */>
              <LogoGPS />
              <Styled.AppItemTitle>Goal Manager</Styled.AppItemTitle>
            </Styled.AppLink>
          </Styled.AppsList>

          <Styled.Footer>
            <Styled.Link href="https://arootah.com" target="_blank">www.arootah.com</Styled.Link>
          </Styled.Footer>
        </Styled.Box>
      }
      trigger="click"
    >
      <Styled.Toggle $project={activeProject}>
        <AppsIcon />
      </Styled.Toggle>
    </Popover>
  );
}

export default AppSwitcher;
