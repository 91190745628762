import {
  Col,
  Slider,
  Button,
  Typography,
  Form,
  Input,
  Table,
  Row,
  Divider,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as DeleteIcon } from "assets/svg/deleteCategory.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  adjustCategoryWeight,
  changeCategoryItemsOrder,
  createActionItem,
  createPlanCategory,
  deletePlanCategory,
  updatePlanCategory,
} from "modules/actions/PlanActions";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { ReactComponent as DragIcon } from "assets/svg/drag.svg";
import styles from "./styles.module.scss";
import c from "classnames";
import { arrayMoveImmutable } from "array-move";
import DeleteModal from "../Modal/DeleteModal";

const { Paragraph } = Typography;
const EditableContext = React.createContext(null);
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  category,
  addNewActionItem,
  deleteCategory,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <>
        {category ? (
          <Row align="middle" className="deleteDiv c-pointer">
            <div style={{ marginTop: 2 }} onClick={toggleEdit}>
              {record?.name}
            </div>
            {/* <PlusOrange
            className='ml-2'
            onClick={() => addNewActionItem(record)}
          />
          <div
            className='hide deleteCategory'
            onClick={() => deleteCategory(record?.id, record?.project_plan)}
          >
            <DeleteIcon />
          </div> */}
          </Row>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{
              width: "auto",
              // paddingRight: 24
            }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        )}
      </>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr className="catSorted" {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const MyCustomTable = ({ columns, dataSource, handleAdd, ...rest }) => {
  const processedColumns = [...columns];

  return (
    <Table
      columns={processedColumns}
      key="loading-done"
      dataSource={dataSource}
      {...rest}
    />
  );
};

const WeightTable = ({ planDetails, handleChangeMain }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.plan.plan);
  const [state, setState] = useState({
    updatedWeight: [],
    catID: "",
    catName: "",
    deleteModalCat: false,
  });
  const { updatedWeight, catID, catName, deleteModalCat } = state;

  const prevCategoriesRef = useRef();

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const DragHandle = SortableHandle(() => <DragIcon />);

  const SortableItem = SortableElement((props) => <EditableRow {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        planDetails?.categories.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const categories = [];
      newData?.forEach((element, index) => {
        if (element) {
          categories?.push({
            id: element?.id,
            order_id: index,
          });
        }
      });
      const payload = {
        project_plan: planDetails?.id,
        categories,
      };
      dispatch(changeCategoryItemsOrder(payload, planDetails?.id));
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = (restProps) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const id = planDetails?.categories?.findIndex(
      (x) => x.id === restProps["data-row-key"]
    );
    return <SortableItem className="catSorted" index={id} {...restProps} />;
  };

  const handleSaveWeight = (weight, row) => {
    if (getTotalWeight() === 100) {
      if (updatedWeight?.length > 0) {
        const payload = {
          project_plan: planDetails?.id,
          categories: updatedWeight,
        };
        dispatch(adjustCategoryWeight(payload, planDetails?.id, handleClose));
      } else {
        handleClose();
      }
    } else {
      toast("Sum of all weights should be 100%", { type: "error" });
    }
  };

  useEffect(() => {
    const updatedWeights = prevCategoriesRef.current || [];

    const list = planDetails?.categories.map(category => {
      const updatedWeight = updatedWeights.find(uw => uw.id === category.id);

      return {
        id: category.id,
        weight: updatedWeight ? updatedWeight.weight : category.weight
      };
    });

    if (list) {
      handleChange("updatedWeight", list);
    }
  }, [planDetails?.categories]);

  const handleChangeWeight = (weight, row) => {
    const updatedWeight = prevCategoriesRef.current || [];
    const existingCategory = updatedWeight.find(e => e.id === row.id);

    if (existingCategory) {
      existingCategory.weight = weight;
    } else {
      updatedWeight.push({ id: row.id, weight });
    }

    prevCategoriesRef.current = updatedWeight;
    handleChange("updatedWeight", updatedWeight);
  };

  const formatter = (value) => `${(value * 100).toFixed(0)}%`;

  let defaultColumns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 60,
      align: "center",
      className: "drag-visible firstCell cat1Cell",
      render: () => <DragHandle />,
    },
    {
      title: "Project category",
      editable: true,
      dataIndex: "name",
      className: "c-word-break catCell",
      width: 400,
      rowClassName: "orangeRow",
      bordered: false,
      ellipsis: true,
      category: true,
    },
    {
      title: () => {
        return (
          <div className={styles.weightDiv}>
            <div>WEIGHT</div>
            <span
              className={c(
                styles.weightSum,
                getTotalWeight() == 100 ? styles.green : styles.red
              )}
            >
              Sum of all weights: {getTotalWeight()}%
            </span>
          </div>
        );
      },
      // title: "",
      dataIndex: "weight",
      className: "underline weightCell",
      render: (text, record) => {
        return (
          <Row className="ml-2 c-pointer">
            <Col span={20} className="mr-2">
              <Slider
                defaultValue={
                  getupdatedWeight(record?.id) !== "" &&
                    getupdatedWeight(record?.id) >= 0
                    ? getupdatedWeight(record?.id)
                    : text
                      ? Number(text)
                      : 0
                }
                // value={Number(getupdatedWeight(record?.id))}
                max={1}
                className="tooltip-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                trackStyle={{ backgroundColor: "#1271A6" }}
                tipFormatter={formatter}
                tooltipPlacement="top"
                handleStyle={{
                  backgroundColor: "#fff",
                  border: "1px solid #1271A6",
                  marginTop: -8,
                  width: 25,
                  backgroundImage:
                    "url(https://res.cloudinary.com/zaafir-solutions/image/upload/v1676582445/sliderDrag_crn5q6.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: 25,
                  height: 25,
                }}
                step={0.01}
                // onChange={value => handleChangeWeight(value, record)}
                onAfterChange={(value) => handleChangeWeight(value, record)}
              />
            </Col>
            <Col span={3} className="text-right">
              <Paragraph
                editable={{
                  onChange: (value) =>
                    value > 0 &&
                    handleChangeWeight(Number(value) / 100, record),
                  triggerType: "text",
                }}
              >
                {getupdatedWeight(record?.id) !== "" &&
                  getupdatedWeight(record?.id) >= 0
                  ? (getupdatedWeight(record?.id) * 100)?.toFixed(0)
                  : (Number(text) * 100)?.toFixed(0) || 0}
                %
              </Paragraph>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "Actions",
      align: "center",
      width: 100,
      dataIndex: "delegate",
      render: (text, record) => (
        <div
          className="c-pointer"
          onClick={() => deleteCategoryModal(record?.id, record?.name)}
        >
          <DeleteIcon />
        </div>
      ),
    },
  ];

  const [count, setCount] = useState(3);

  const addNewActionItem = (record) => {
    const payload = {
      name: "Action Item",
      start_line: moment().format("YYYY-MM-DD"),
      project_plan: record?.project_plan,
      category: record?.id,
    };
    dispatch(createActionItem(payload));
  };

  const deleteCategory = () => {
    dispatch(
      deletePlanCategory(catID, planDetails?.id, closeDeleteCategoryModal)
    );
  };

  const deleteCategoryModal = (id, name) => {
    handleChangeMain("catID", id);
    handleChangeMain("catName", name);
    handleChangeMain("deleteModalCat", true);
  };

  const closeDeleteCategoryModal = () => {
    handleChangeMain("catID", "");
    handleChangeMain("catName", "");
    handleChangeMain("deleteModalCat", false);
  };

  const handleAdd = () => {
    const payload = {
      name: "_",
      start_line: moment(new Date()).format("YYYY-MM-DD"),
      project_plan: planDetails?.id,
    };
    dispatch(createPlanCategory(payload));
    setCount(count + 1);
  };

  const handleSave = (row) => {
    dispatch(updatePlanCategory(row?.id, row));
  };

  const handleClose = () => {
    if (prevCategoriesRef && prevCategoriesRef.current)
      prevCategoriesRef.current = [];

    handleChangeMain("weightModal", false);
  };

  const getupdatedWeight = (id) => {
    const filtered = updatedWeight.filter((e) => e?.id === id);
    return filtered?.length > 0 && filtered[0]?.weight
      ? filtered[0]?.weight
      : "";
  };

  const getTotalWeight = () => {
    const nonUpdated = planDetails?.categories?.filter(
      (item) => !updatedWeight?.some((e) => e?.id === item?.id)
    );
    const finallist = updatedWeight?.concat(nonUpdated);
    let total = 0;
    for (let i = 0; i < finallist.length; i++) {
      const element = finallist[i];
      total = Number(element?.weight) + total;
    }
    total = Number(total) * 100;
    return Number(total.toFixed(0));
  };

  const components = {
    body: {
      wrapper: DraggableContainer,
      row: DraggableBodyRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        category: col.category,
        title: col.title,
        addNewActionItem: addNewActionItem,
        deleteCategory: deleteCategoryModal,
        handleSave,
      }),
    };
  });

  const getCategoriesData = (data) => {
    const list = [];

    if (data?.length > 0) {
      data?.forEach((element, index) => {
        if (element) {
          list.push({
            ...element,
            key: `${index}`,
          });
        }
      });
    }

    return list;
  };

  return (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        <MyCustomTable
          columns={columns}
          components={components}
          handleAdd={handleAdd}
          expandable={false}
          size={"small"}
          rowKey="id"
          key="loading-done"
          className={"categoryTable"}
          dataSource={getCategoriesData(planDetails?.categories) || []}
          rowClassName={() => "editable-row"}
          bordered
          scroll={{ y: 400 }}
          pagination={false}
        />
      </div>
      <Divider />
      <div className={styles.footer}>
        <div
          className={c(
            styles.weightSum,
            getTotalWeight() == 100 ? styles.green : styles.red
          )}
        >
          {/* Sum of all weights: {getTotalWeight()}% */}
        </div>
        <Button
          className="createPlanBtn"
          type="primary"
          style={{ borderRadius: 5 }}
          onClick={handleSaveWeight}
        >
          save
        </Button>
      </div>
      <DeleteModal
        visible={deleteModalCat}
        planDetails={planDetails}
        heading={"Delete Category"}
        onClose={() => handleChange("deleteModalCat", false)}
        descriptions={
          <span>
            Are you sure you want to delete the category "<b>{catName}</b>"?
            Deleting it will result in the loss of all related information.
          </span>
        }
        handleRemove={deleteCategory}
        loading={loading}
      />
    </div>
  );
};

export default WeightTable;
