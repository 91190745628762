import React, { memo } from "react";
import { Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import { ReactComponent as Decending } from "./decending.svg";
import { ReactComponent as Assending } from "./assending.svg";
import { ReactComponent as CheckedIcon } from "./checked.svg";
import { ReactComponent as InfoIcon } from "./info.svg";

import classes from "./decisiondetails.module.scss";
import cc from "classnames";
import isEqual from "lodash.isequal";

const CriteriaHead = ({ data: c, columnsToShow, onSort }) => {
  return (
    <div className="d-flex">
      <div
        className={cc(classes.column, classes.criteria, {
          [classes.short]: columnsToShow.criteriaDescription
        })}
      >
        <div className={classes.box3Title}>
          <div className="d-flex">
            <span
              className={
                columnsToShow.criteriaDescription
                  ? classes.descShort
                  : classes.descLong
              }
            >
              {c.title}
            </span>
            <div className={classes.optionWeight}>
              {c?.weight}%
            </div>
          </div>

          {!columnsToShow.criteriaDescription && (
            <div className={classes.infoPopover}>
              {c.description && (
                <>
                  <Popover
                    overlayClassName="decision_detail_popover"
                    content={
                      <div
                        className={
                          classes.infoPopoverContent
                        }
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              c.description.replaceAll(
                                "\n",
                                "<br />"
                              ),
                          }}
                        />
                      </div>
                    }
                    placement="top"
                  >
                    <InfoIcon />
                  </Popover>
                </>
              )}
            </div>
          )}

          <Popover
            overlayClassName="decision_detail_popover"
            placement="bottomLeft"
            content={
              <div className="popoverCriteriaWrapper">
                <p className="label">SORT SCORE</p>
                <div className="sortContainer">
                  <div
                    className={`cursor-pointer popoverCriteriaDiv ${columnsToShow?.isAscending &&
                      "activeButton"
                      }`}
                    onClick={() => onSort(
                      "weight",
                      "asc",
                      true
                    )}
                  >
                    <span className="assendingIcon">
                      <Assending />
                    </span>
                    Ascending
                    <CheckedIcon />
                  </div>
                  <div
                    className={`cursor-pointer popoverCriteriaDiv ${columnsToShow?.isAscending ===
                      false && "activeButton"
                      }`}
                    onClick={() => onSort(
                      "weight",
                      "desc",
                      false
                    )}
                  >
                    <span className="decendingIcon">
                      <Decending />
                    </span>
                    Descending
                    <CheckedIcon />
                  </div>
                </div>
              </div>
            }
            trigger="click"
          >
            <EllipsisOutlined
              className={classes.popover}
              rotate={90}
            />
          </Popover>
        </div>
      </div>
    </div>
  )
}

export default memo(CriteriaHead, (prevProps, nextProps) => {
  const prevPropsWithoutFunction = Object.entries(prevProps).reduce((acc, [key, value]) => {
    if (key !== 'onSort' && typeof value !== 'function') {
      acc[key] = value;
    }
    return acc;
  }, {});

  const nextPropsWithoutFunction = Object.entries(nextProps).reduce((acc, [key, value]) => {
    if (key !== 'onSort' && typeof value !== 'function') {
      acc[key] = value;
    }
    return acc;
  }, {});

  return isEqual(prevPropsWithoutFunction, nextPropsWithoutFunction);
});
