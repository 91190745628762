import {
  Breadcrumb,
  Card,
  Col,
  Dropdown,
  Menu,
  Progress,
  Row,
  Tooltip,
} from "antd";
import { COLORS } from "constants/colors";
import moment from "moment";
import { ReactComponent as Dots } from "assets/svg/dots.svg";
import { ReactComponent as ShareIcon } from "assets/svg/share.svg";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "scenes/PlanDetails/Modal/DeleteModal";
import { archivePlan, removePlan } from "modules/actions/PlanActions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import RenamePlanModal from "scenes/PlanDetails/Modal/RenamePlanModal";

export default function PlanCard({
  status,
  item,
  showAll,
  loadingUpdatePlan,
  // handleGetPlan,
  handleUpdatePlan,
  isHierarchy,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loadingDelete, loadingArchive } = useSelector(
    (state) => state.plan.plan
  );

  const { users } = useSelector((state) => state.user.projectOwners);

  const [state, setState] = useState({
    deleteModal: false,
    renamePlanModal: false,
    deleteArchiveModal: false,
    planName: item?.name || "",
  });

  const { deleteModal, renamePlanModal, planName, deleteArchiveModal } = state;

  const handleRemoveModal = (e) => {
    handleChange("deleteModal", true);
  };

  const handleArchive = (e) => {
    const payload = {
      project_plan: parseInt(item?.id),
      is_archived: !item?.is_archived,
    };

    dispatch(archivePlan(payload, goBack));
  };

  const handleArchiveModal = (e) => {
    handleChange("deleteArchiveModal", true);
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleShare = () => {
    navigate(`/project-planner/plan-details/${item?.id}/resources`);
  }

  const menu = (
    <Menu
      className="removeOption"
      items={[
        {
          key: "1",
          onClick: () => {
            handleChange("renamePlanModal", true);
            handleChange("planName", item?.name);
          },
          label: <span style={{ color: "#1B2A3D" }}>Rename</span>,
        },
        {
          key: "2",
          onClick: handleArchiveModal,
          label: (
            <span style={{ color: "#1B2A3D" }}>
              {item?.is_archived ? "Unarchive Plan" : "Archive Plan"}
            </span>
          ),
        },
        {
          key: "3",
          onClick: handleShare,
          disabled: item?.is_archived,
          label: (
            <span style={{ color: "#1B2A3D", display: 'flex', justifyContent: 'space-between', opacity: item?.is_archived ? 0.5 : 1 }}>
              Share
              <ShareIcon />
            </span>
          ),
        },
        {
          key: "4",
          onClick: handleRemoveModal,
          label: <span className="text_red">Delete Project</span>,
        },
      ]}
    />
  );

  const goBack = () => {
    // handleGetPlan(item?.is_archived);
    handleChange("deleteModal", false);
    handleChange("renamePlanModal", false);
    handleChange("deleteArchiveModal", false);
  };

  const handleRemove = (e) => {
    dispatch(removePlan(item?.id, goBack));
    // handleUpdatePlan()
  };

  const onClickHandle = (event) => {
    if (!item?.is_archived) {
      if (
        !event.target.closest(".ant-dropdown") &&
        !event.target.closest("#test")
      )
        navigate(
          `/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`
        );
    }
  };

  return (
    <>
      <div onClick={onClickHandle} className={styles.card} test-id="card">
        <div className={styles.head}>
          <Tooltip title={item?.name}>
            <h2 className={styles.title}>
              {item?.name?.slice(0, 25) +
                (item?.name?.length > 25 ? "..." : "")}
            </h2>
          </Tooltip>
          <div className={styles.actions} id="test">
            <div className="greyBox">
              {users.find(user => user.id === (item?.owner?.id || item?.owner))?.username?.slice(0, 2) ||
              users.find(user => user.id === (item?.owner?.id || item?.owner))?.name?.slice(0, 2)}
            </div>

            <Dropdown
              placement="bottomRight"
              click
              className={styles.menuDots}
              overlay={menu}
              trigger={["click"]}
            >
              <Dots />
            </Dropdown>
          </div>
        </div>

        <div
          className="progressBox"
        /* onClick={() =>
          navigate(`/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`)
        } */
        >
          <div className={styles.progressWrapper}>
            <div className={styles.head}>
              <div className={styles.title}>Goal Progress</div>
              <div className={styles.text}>
                {Number((item?.goal_progress || 0) * 100).toFixed(0)}%
              </div>
            </div>
            <Progress
              percent={Number(item?.goal_progress * 100 || 0).toFixed(0)}
              strokeColor={COLORS.sliderGreen}
              style={{ width: "100%" }}
              showInfo={false}
            />
          </div>

          <div className={styles.progressWrapper}>
            <div className={styles.head}>
              <div className={styles.title}>Plan progress</div>
              <div className={styles.text}>
                {Number((item?.plan_progress || 0) * 100).toFixed(0)}%
              </div>
            </div>
            <Progress
              percent={Number(item?.plan_progress * 100 || 0).toFixed(0)}
              strokeColor={COLORS.sliderGreen}
              style={{ width: "100%" }}
              showInfo={false}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <span className={styles.update}>
            Last update: {moment(item?.updated_at).fromNow()}
          </span>
          <span className={styles.deadline}>
            {moment(item?.dead_line).isSameOrAfter()
              ? "Due " + moment(item?.dead_line).fromNow()
              : moment(item?.dead_line).fromNow()}
          </span>
        </div>
      </div>

      <DeleteModal
        visible={deleteModal}
        heading={"Delete Plan"}
        onClose={() => handleChange("deleteModal", false)}
        descriptions={
          <span>
            Are you sure you want to delete <b>"{item?.name}"</b> project plan?
          </span>
        }
        planDetails={item}
        handleChange={handleChange}
        handleArchive={handleArchive}
        handleRemove={handleRemove}
        loadingArchive={loadingArchive}
        loading={loadingDelete}
      />

      <DeleteModal
        visible={deleteArchiveModal}
        isPlan
        item={item}
        heading={item?.is_archived ? "Send To Unarchive" : "Send To Archive"}
        onClose={() => handleChange("deleteArchiveModal", false)}
        descriptions={
          <span>
            Are you sure you want to{" "}
            {item?.is_archived ? "unarchive" : "archive"} <b>"{item?.name}"</b>{" "}
            project plan?
          </span>
        }
        planDetails={item}
        handleChange={handleChange}
        handleRemove={handleArchive}
        // handleArchive={handleArchive}
        loading={loadingArchive}
      />

      <RenamePlanModal
        visible={renamePlanModal}
        planDetails={item}
        handleChange={handleChange}
        handleSubmit={(payload) => handleUpdatePlan(item?.id, payload, goBack)}
        loading={loadingUpdatePlan}
      />
    </>
  );

  return (
    <Col span={6} className={"mb-2"}>
      <Card
        headStyle={{
          border: "none",
          marginBottom: -30,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        title={
          <Row
            onClick={() =>
              navigate(
                `/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`
              )
            }
          >
            {isHierarchy ? (
              <Breadcrumb separator=">" className="BreadcrumbHome">
                <Breadcrumb.Item className="text_primary font-12">
                  Health
                </Breadcrumb.Item>
                <Breadcrumb.Item className="text_primary font-12" href="">
                  Weight control
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <div className="text_400 font-18">{item?.name}</div>
            )}
          </Row>
        }
        extra={
          <Row align="middle">
            <div className="greyBox">
              {item?.owner?.username?.substring(0, 2)}
            </div>
            <Dropdown overlay={menu}>
              <Dots />
            </Dropdown>
          </Row>
        }
        style={{ borderRadius: 10, cursor: "pointer" }}
      >
        <Row
          justify="space-between"
          align="middle"
          onClick={() =>
            navigate(
              `/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`
            )
          }
        >
          <div>
            {isHierarchy && (
              <div className="text_400 font-18">{item?.name}</div>
            )}
            {/* <Row align='middle'>
              {showAll && (
                <div
                  style={{
                    padding: '2px 3px ',
                    borderRadius: 5,
                    marginRight: 5,
                    color:
                      status === 'Scheduled' ? COLORS.white : COLORS.fillButton,
                    backgroundColor:
                      status === 'In progress'
                        ? COLORS.inprogressBG
                        : status === 'Scheduled'
                        ? COLORS.scheduledBG
                        : COLORS.completedBG
                  }}
                  className='font-8'
                >
                  {status}
                </div>
              )}
            </Row> */}
          </div>
        </Row>
        <div
          className="progressBox"
          onClick={() =>
            navigate(
              `/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`
            )
          }
        >
          <Row justify="space-between" align="middle">
            <div className="font-8 text_blue_dark">Goal Progress</div>
            <div className="font-8 text_blue_dark">
              {Number((item?.goal_progress || 0) * 100).toFixed(2)}%
            </div>
          </Row>
          <Progress
            percent={Number(item?.goal_progress * 100 || 0).toFixed(0)}
            strokeColor={COLORS.sliderGreen}
            style={{ width: "100%" }}
            showInfo={false}
          />
          <Row justify="space-between" align="middle">
            <div className="font-8 text_blue_dark">Plan progress</div>
            <div className="font-8 text_blue_dark">
              {Number((item?.plan_progress || 0) * 100).toFixed(2)}%
            </div>
          </Row>
          <Progress
            percent={Number(item?.plan_progress * 100 || 0).toFixed(0)}
            strokeColor={COLORS.sliderGreen}
            style={{ width: "100%" }}
            showInfo={false}
          />
        </div>
        <Row
          onClick={() =>
            navigate(
              `/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`
            )
          }
          justify="space-between"
          align="middle"
          className="mt-1"
        >
          <div className="text_light_navy_light text_500 font-10">
            Last update: {moment(item?.updated_at).fromNow()}
          </div>
          <div style={{ color: "#FF0000" }} className=" text_bold font-10">
            {moment(item?.dead_line).fromNow()}
          </div>
        </Row>
      </Card>
      <DeleteModal
        visible={deleteModal}
        heading={"Delete Plan"}
        onClose={() => handleChange("deleteModal", false)}
        descriptions={"Are you sure you want to delete the project plan?"}
        planDetails={item}
        handleChange={handleChange}
        handleArchive={handleArchive}
        handleRemove={handleRemove}
        loadingArchive={loadingArchive}
        loading={loadingDelete}
      />
    </Col>
  );
}
