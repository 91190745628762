import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select, Col, Row, Collapse, Button } from "antd";
import moment from "moment";
import update from "immutability-helper";

// Main
import PlanCard from "./PlanCard";
// import NewUserModal from "./NewUserModal";
import SortBy from "./SortBy";
import SortByArea from "./SortByArea";
import CreatePlanModal from "./CreatePlanModal";
import SortByGroup from "./SortByGroup";
import ProjectTable from "./ProjectTable";
import SortByAreaTableView from "./SortByArea/TableView";
import { ReactComponent as SearchIcon } from "assets/svg/Search.svg";
import { ReactComponent as NoPlan } from "assets/svg/NoPlan.svg";

// Components
import { AppLayout } from "components";

// Modules
import { createPlan, getPlans, updatePlan } from "modules/actions/PlanActions";
import {
  closeInitialSetupModal,
  getProjectOwners,
  inviteOwner,
} from "modules/actions/UserActions";

// Icons
import { ReactComponent as DragIcon } from "assets/svg/drag.svg";
import { ReactComponent as ExpandIcon } from "assets/svg/expand.svg";

import { statuses } from "constants/index";
import { Card } from "./DragnDrop";

import styles from "./styles.module.scss";
import { AppLoading } from "components/AppLoading";
import {
  ArrowRightOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";

const { Panel } = Collapse;

const sortBy = (filteredPlans, selectedSortBy, isAscending) => {
  if (selectedSortBy === "Name") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? a?.name.localeCompare(b?.name)
        : b?.name.localeCompare(a?.name)
    );
  } else if (selectedSortBy === "Startline") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? new Date(a?.start_line) - new Date(b?.start_line)
        : new Date(b?.start_line) - new Date(a?.start_line)
    );
  } else if (selectedSortBy === "Deadline") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? new Date(a?.dead_line) - new Date(b?.dead_line)
        : new Date(b?.dead_line) - new Date(a?.dead_line)
    );
  } else if (selectedSortBy === "Goal Progress") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? Number(a?.goal_progress * 100 || 0) - Number(b?.goal_progress * 100 || 0)
        : Number(b?.goal_progress * 100 || 0) - Number(a?.goal_progress * 100 || 0)
    );
  } else if (selectedSortBy === "Plan Progress") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? Number(a?.plan_progress * 100 || 0) - Number(b?.plan_progress * 100 || 0)
        : Number(b?.plan_progress * 100 || 0) - Number(a?.plan_progress * 100 || 0)
    );
  }

  return filteredPlans;
};

const PAGINATION_LIMIT = 25;

const ProjectPlanner = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const filterRef = useRef();

  const { loading, allPlans, loadingUpdatePlan } = useSelector(
    (state) => state.plan.plan
  );

  const { user } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.user.projectOwners);
  const {
    loading: inviteLoading,
    // data,
    // showInitialSetupModal,
  } = useSelector((state) => state.user.invitedUser);

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [search, setSearch] = useState("");

  const [state, setState] = useState({
    activeKey: [],
    allAreas: [],
    // selectedStatus: [],
    selectedSortBy: "",
    selectedLocations: [],
    // filteredList: allPlans || [],
    visible: false,
    isTableView: false,
    name: "",
    start_line: "",
    dead_line: "",
    description: "",
    purpose: "",
    ownerText: "",
    inviteEmail: "",
    invited_owner: user?.data?.email,
    active: true,
    inviteModal: false,
    isHierarchy: false,
    isAscending: true,
    openFilter: false,
    openSort: false,
    isArchive: false,
    l: PAGINATION_LIMIT, o: 0
  });

  const {
    activeKey,
    allAreas,
    selectedSortBy,
    isAscending,
    selectedLocations,
    visible,
    isTableView,
    name,
    start_line,
    dead_line,
    description,
    active,
    purpose,
    invited_owner,
    isHierarchy,
    ownerText,
    inviteModal,
    inviteEmail,
    // filteredList,
    openFilter,
    isArchive,
    openSort,
    l, o
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleCollapsed = (value) => {
    handleChange("activeKey", value);
  };

  /* const handleNewUserModalChange = () => {
    dispatch(closeInitialSetupModal());
    navigate("/setup");
  }; */

  const handleArchive = () => {
    setSelectedStatus([]);
    if (isArchive) {
      handleChange("isArchive", false);
      handleGetPlan(false);
    } else {
      handleChange("isArchive", true);
      handleGetPlan(true);
    }
  };

  const handleCollapsedSingle = (value) => {
    if (activeKey.some((e) => e === value)) {
      const removed = activeKey.filter((e) => e !== value);
      handleChange("activeKey", removed);
    } else {
      const added = [...activeKey, value];
      handleChange("activeKey", added);
    }
  };

  const getOwnerIDByEmail = () => {
    const filtered = users?.filter((e) => e.email === invited_owner);
    return filtered?.length > 0 ? filtered[0]?.id : null;
  };

  const handleCreatePlan = (e) => {
    const payload = {
      name,
      start_line: moment(start_line).format("YYYY-MM-DD"),
      dead_line: moment(dead_line).format("YYYY-MM-DD"),
      description,
      // purpose,
      created_at: new Date(),
      updated_at: new Date(),
      invited_owner,
      owner: getOwnerIDByEmail(),
      areas: [1],
    };

    dispatch(createPlan(payload, handlePlanModal, resetState));
  };

  const handleClosePlanModal = (e) => {
    handleChange("name", "");
    handleChange("start_line", "");
    handleChange("dead_line", "");
    handleChange("description", "");
    handleChange("invited_owner", user?.data?.email);
    handleChange("visible", false);
  };

  const debounceDelay = 500; // Adjust this value to control the delay.

  useEffect(() => {
    let spread = false

    if (!search)
      return handleGetPlan(false, spread);

    const timer = setTimeout(() => {
      handleGetPlan(false, spread);
    }, debounceDelay);

    // Clear the timer on every change to reset the delay
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    let spread = true
    handleGetPlan(false, spread);
  }, [l, o]);

  const handleGetPlan = (isArchive, spread) => {
    const basePayload = isArchive ? "?is_archived=true" : "?is_archived=false";
    const payload = `${basePayload}&search=${search}${`&l=${l}&o=${o}`}`;

    dispatch(getPlans(payload, spread, search));
    dispatch(getProjectOwners(""));
  };

  const resetState = () => {
    handleChange("name", "");
    handleChange("start_line", "");
    handleChange("dead_line", "");
    handleChange("description", "");
    handleChange("purpose", "");
    handleChange("invited_owner", user?.data?.email);
  };

  const handleInvite = (e) => {
    const payload = { invited_owner: inviteEmail };
    dispatch(inviteOwner(payload, handleOwnerModal));
  };

  const handlePlanModal = (value, isNew) => {
    handleChange("visible", value);
    if (isNew) {
      const filtered = users?.filter((e) => e.email === invited_owner);
      handleChange(
        "invited_owner",
        filtered?.length > 0 ? filtered[0]?.username : ""
      );
    }
  };

  const handleOwnerModal = (value) => {
    handleChange("inviteModal", value);
  };

  const handleUpdatePlan = (id, payload, closeModal) => {
    dispatch(updatePlan(id, payload, closeModal, true));
  };

  const getPlansLengthNyStatus = (status) => {
    return allPlans?.filter(
      (e) => e?.status?.toLowerCase() === status?.toLowerCase()
    );
  };

  const getSTatusName = (status) => {
    const res = statuses?.filter(
      (e) => e?.value?.toLowerCase() === status?.toLowerCase()
    );
    return res[0]?.key;
  };

  const handleSearch = evt => {
    setSearch(evt.target.value);
  }

  const handleSeeMore = () => {
    setState((pre) => ({ ...pre, o: pre.o + PAGINATION_LIMIT }));
  }

  const filteredPlans = allPlans?.filter((entry) =>
    entry?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const sortedPlans = sortBy(filteredPlans, selectedSortBy, isAscending);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setSelectedStatus((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card?.id}
        index={index}
        id={card?.id}
        children={card?.children}
        moveCard={moveCard}
      />
    );
  }, []);

  const handleCloseFilter = useCallback((value) => {
    filterRef.current.focus(); //whenever a user triggers value change, we call `blur()` on `Select`
  }, []);

  return (
    <AppLayout>
      <div className={styles.header}>
        <Col span={12}>
          <Row align="middle">
            {/*  <Link className={styles.back} to="">
              <BackIcon />
            </Link> */}

            <h2 className={styles.title}>Dashboard</h2>

            <button
              ref={filterRef}
              className={styles.create}
              onClick={() => handlePlanModal(true)}
              type="button"
            >
              + new project
            </button>

            {/* <Button
              type='primary'
              className='createProjectButton'
              onClick={() => handlePlanModal(true)}
            >
              + new project
            </Button> */}

            {/* <Row
              align='middle'
              className={`${
                !isTableView ? 'isActiveTab' : 'inactiveTab'
              }  font-14 mr-1 c-pointer`}
              // style={{ opacity: isTableView ? 0.5 : 1 }}
              onClick={() => handleChange('isTableView', false)}
            >
              <span>GRID</span>
              <img
                src={!isTableView ? gridWhiteIcon : gridIcon}
                className={'ml-1'}
              />
            </Row>

            <Row
              align='middle'
              className={`${
                isTableView ? 'isActiveTab' : 'inactiveTab'
              }  font-14 mr-1 c-pointer`}
              onClick={() => handleChange('isTableView', true)}
            >
              <span>List</span>
              <TableIcon
                // src={isTableView ? tableWhiteIcon : tableIcon}
                className={'ml-1'}
              />
            </Row> */}
            {selectedStatus.length > 0 && (
              <Row
                align="middle"
                className={`${activeKey.length === 3 ? "isActiveTab" : "inactiveTab"
                  }  font-14 mr-1 ml-2 c-pointer`}
                // style={{ opacity: activeKey.length === 4 ? 0.5 : 1 }}
                onClick={() =>
                  handleCollapsed(
                    activeKey.length === 4 ? [] : ["1", "2", "3", "4"]
                  )
                }
              >
                <span>
                  {activeKey.length === 4 ? "Collapse all" : "Expand all"}
                </span>
                <ExpandIcon className={"ml-1"} />
              </Row>
            )}
          </Row>
        </Col>

        {/* <Row
            align='middle'
            className={`${
              activeKey.length === 0 ? 'isActiveTab' : 'inactiveTab'
            }  font-14 mr-1 c-pointer`}
            // style={{ opacity: activeKey.length === 0 ? 0.5 : 1 }}
            onClick={() => handleCollapsed([])}
            >
            <span>Collapse all</span>
            <img src={collapseIcon} className={'ml-1'} />
            </Row> */}

        <Col span={12}>
          <Row justify="space-between">
            <Col span={12}>
              <Row>
                <div className={styles.searchWrapper}>
                  <SearchIcon className={styles.icon} width={20} />
                  <input
                    type="text"
                    className={styles.search}
                    placeholder={"Search projects"}
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </Row>
            </Col>
            <Row align="middle">
              <Select
                className="mr-1 drowpdown"
                placeholder="SORT"
                // open={openSort}
                onClick={() => handleChange("openSort", !openSort)}
                dropdownMatchSelectWidth={false}
                onMouseDown={(e) => {
                  e.preventDefault();
                  return false;
                }}
                dropdownRender={(menu) => (
                  <SortBy
                    selectedSortBy={selectedSortBy}
                    isAscending={isAscending}
                    sorting
                    // sortBy={sortBy}
                    handleChange={handleChange}
                  />
                )}
              ></Select>
              <Select
                className="mr-1"
                // open={openFilter}
                // onClick={() =>
                // }
                placeholder="FILTER"
                dropdownMatchSelectWidth={false}
                dropdownRender={(menu) => (
                  <SortByGroup
                    handleChange={handleChange}
                    setSelectedStatus={setSelectedStatus}
                    onSave={handleCloseFilter}
                    handleArchive={handleArchive}
                    handleGetPlan={handleGetPlan}
                    isArchive={isArchive}
                    sorting
                    selectedStatus={selectedStatus}
                    selectedLocations={selectedLocations}
                  />
                )}
              />
              {/* <Select
                placeholder={`${
                  selectedStatus.length > 0 ? 'STATUS' : 'GROUP'
                }`}
                dropdownMatchSelectWidth={false}
                dropdownRender={menu => (
                  <SortByGroup
                    handleChange={handleChange}
                    groupBy
                    selectedStatus={selectedStatus}
                    selectedLocations={selectedLocations}
                  />
                )}
              ></Select> */}
            </Row>
          </Row>
        </Col>
      </div>

      <Row justify="space-between position-relative" className="mt-2 mr-2">
        <Col>
          <Row align="middle">
            {isHierarchy && (
              <>
                <div className="dropdownLabel">Show Areas:</div>
                {!isTableView && (
                  <Select
                    className="mr-1"
                    placeholder={`${allAreas?.join(", ") || "All"}`}
                    dropdownMatchSelectWidth={false}
                    dropdownRender={(menu) => (
                      <SortByArea
                        allAreas={allAreas}
                        handleChange={handleChange}
                      />
                    )}
                  ></Select>
                )}
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Col>
        {loading && (
          <div className={styles.loader}>
            <AppLoading />
          </div>
        )}

        {((!loading && sortedPlans?.length === 0 && sortedPlans?.length === 0) ||
          (sortedPlans?.length === 0 && search)) &&
          selectedStatus?.length === 0 && (
            <NoPlan className={styles.centered} />
          )}

        {selectedStatus.length > 0 ? (
          <>
            <Row gutter={6}>
              {isTableView && (
                <Col span={6}>
                  <SortByAreaTableView
                    allAreas={allAreas}
                    handleChange={handleChange}
                  />
                </Col>
              )}

              <Col span={isTableView ? 18 : 24}>
                {selectedStatus.map((selected, index) =>
                  renderCard(
                    {
                      id: index,
                      children: (
                        <Collapse
                          key={index}
                          defaultActiveKey={[`1`]}
                          activeKey={activeKey}
                          expandIconPosition="right"
                          expandIcon={(props) =>
                            props.isActive ? (
                              <DownOutlined
                                className="expendIcon"
                                onClick={() =>
                                  handleCollapsedSingle(`${index + 1}`)
                                }
                              />
                            ) : (
                              <RightOutlined
                                className="expendIcon"
                                onClick={() =>
                                  handleCollapsedSingle(`${index + 1}`)
                                }
                              />
                            )
                          }
                          ghost
                        >
                          <Panel
                            style={{ minHeight: 50, marginTop: 20 }}
                            header={
                              <div
                                className="font-18 text_500 mr-3"
                                onClick={() =>
                                  handleCollapsedSingle(`${index + 1}`)
                                }
                              >
                                <DragIcon className={"mr-1"} />
                                <span className="mr-1">
                                  {getSTatusName(selected)} (
                                  {getPlansLengthNyStatus(selected)?.length})
                                </span>
                              </div>
                            }
                            key={`${index + 1}`}
                          >
                            <Row gutter={16}>
                              {isTableView ? (
                                <ProjectTable />
                              ) : (
                                <>
                                  {getPlansLengthNyStatus(selected)?.map(
                                    (item, index) => (
                                      <PlanCard
                                        handleUpdatePlan={handleUpdatePlan}
                                        loadingUpdatePlan={loadingUpdatePlan}
                                        key={index}
                                        isHierarchy={isHierarchy}
                                        item={item}
                                      />
                                    )
                                  )}
                                </>
                              )}
                            </Row>
                          </Panel>
                        </Collapse>
                      ),
                    },
                    index
                  )
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div className={styles.wrapper}>
            {sortedPlans?.filter(item => isArchive ? item.is_archived : !item.is_archived).map((item, index) => (
              <PlanCard
                status={item?.status}
                handleGetPlan={handleGetPlan}
                handleUpdatePlan={handleUpdatePlan}
                loadingUpdatePlan={loadingUpdatePlan}
                isHierarchy={isHierarchy}
                item={item}
                showAll
                key={index}
              />
            ))}
          </div>
        )}

        {sortedPlans?.length === o + PAGINATION_LIMIT && <p onClick={handleSeeMore} className={styles.see_more}>Show More</p>}
      </Col>

      <CreatePlanModal
        name={name}
        inviteModal={inviteModal}
        users={users}
        inviteEmail={inviteEmail}
        ownerText={ownerText}
        start_line={start_line}
        dead_line={dead_line}
        inviteLoading={inviteLoading}
        description={description}
        active={active}
        userEmail={user?.data?.email}
        invited_owner={invited_owner}
        purpose={purpose}
        visible={visible}
        loading={loading}
        onClose={handleClosePlanModal}
        handleCreatePlan={handleCreatePlan}
        handleInvite={handleInvite}
        handleChange={handleChange}
      />
    </AppLayout>
  );
};

export default ProjectPlanner;
