import { Button, Divider, Modal, Row } from "antd";
import React, { useState } from "react";
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
import AppInput from "components/AppInput";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { COLORS } from "constants/colors";

export default function CustomModal({
  handleChange: onChange,
  visible,
  handleSubmit,
  loading,
  labelName,
  id,
  labelValue,
  selectedUnitValue,
}) {
  const removeUnit = (index) => {
    selectedUnitValue?.splice(index, 1);
    handleChange("selectedUnitValue", selectedUnitValue);
  };

  const handleChange = (maxWidth = 255) => (field, value) => {
    if (value.length > maxWidth) {
      value = value.slice(0, maxWidth);
    }

    onChange(field, value);
  };

  return (
    <Modal
      footer={null}
      closable={false}
      onCancel={() => onChange("openCustom", false)}
      title={false}
      visible={visible}
    >
      <Row justify="space-between" className="mb-2">
        <div className="font-16 text_black text_bold">Add Custom Unit</div>
        <Cross
          onClick={() => onChange("openCustom", false)}
          className={"c-pointer"}
        />
      </Row>
      <AppInput
        label={"Custom Label"}
        marginBottom={20}
        placeholder={"Enter Custom Label"}
        value={labelName}
        name="labelName"
        onChange={handleChange(12)}
      />
      <AppInput
        marginBottom={20}
        label={"Custom Unit"}
        placeholder={"Enter Custom Unit"}
        value={labelValue}
        name="labelValue"
        postfix={
          labelValue && (
            <Button
              onClick={() => {
                if (selectedUnitValue?.length === 0) {
                  onChange("selectedUnitValue", [
                    ...selectedUnitValue,
                    labelValue,
                  ]);
                } else if (selectedUnitValue?.some((e) => e !== labelValue)) {
                  onChange("selectedUnitValue", [
                    ...selectedUnitValue,
                    labelValue,
                  ]);
                }
                onChange("labelValue", "");
              }}
              color={COLORS.primary}
              type="primary"
              className={"b-1 mr-1 addRemoveButton"}
              icon={<PlusOutlined />}
            >
              <div className="removeText">ADD</div>
            </Button>
          )
        }
        onChange={handleChange(6)}
      />
      {selectedUnitValue?.map((unit, index) => (
        <Row key={index} justify="space-between" className="mb-2">
          <div>{unit}</div>
          <Button
            onClick={() => removeUnit(index)}
            type="primary"
            className={"b-1 RemoveButton"}
            icon={<MinusOutlined />}
          >
            <div className="removeText">REMOVE</div>
          </Button>
        </Row>
      ))}
      <Row justify="end" className="mt-3">
        <Button
          className="createPlanBtn"
          type="primary"
          disabled={!labelName || selectedUnitValue?.length === 0}
          loading={loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Row>
    </Modal>
  );
}
