import React from "react";
import { Button, Checkbox, Col, Divider, Row } from "antd";

const Filter = ({
  setDecision,
  sortBy,
  allDecisions,
  isAscending,
  setSortedList,
  filterBy,
  setFilterBy,
  handleChange,
  setFiltredList,
  filtredList,
}) => {
  return (
    <Col className={"sortByDiv"} onMouseDown={(e) => {}}>
      {
        // groupby?.map((item, index) => (
        <div className={"checkboxDivFilter"}>
          <div className="d-flex align-items-center  text_white mb-3 ">
            <input
              type="checkbox"
              className="option_checkbox me-3 mb-1 text_white"
              checked={filterBy.Active}
              onClick={(e) => {
                setFilterBy({
                  ...filterBy,
                  Active: !filterBy.Active,
                  All: false,
                });
                e.stopPropagation();
              }}
            />
            Active
          </div>
        </div>
        // ))
      }
      {/*{!groupBy &&*/}
      {/*    statuses?.map((item, index) => (*/}
      <div className={"checkboxDivFilter"}>
        <div className="d-flex align-items-center  text_white mb-3 ">
          <input
            type="checkbox"
            className="option_checkbox me-3 mb-1 text_white"
            checked={filterBy.Completed}
            onClick={(e) => {
              setFilterBy({
                ...filterBy,
                Completed: !filterBy["Completed"],
                All: false,
              });
              e.stopPropagation();
            }}
          />
          Completed
        </div>
      </div>
      {/*    ))}*/}
      <div className={"checkboxDivFilter"}>
        <div className="d-flex align-items-center  text_white  ">
          <input
            type="checkbox"
            className="option_checkbox me-3 mb-1 text_white"
            checked={filterBy.Future}
            onClick={(e) => {
              setFilterBy({
                ...filterBy,
                Future: !filterBy["Future"],
                All: false,
              });
              e.stopPropagation();
            }}
          />
          Future
        </div>
      </div>

      <Divider type="horizontal" className="sortDivider" />

      <Row justify="space-between" align="middle" className="mt-2">
        <div
          className="text_white c-pointer"
          onClick={(e) => {
            setFilterBy({
              ...filterBy,
              Future: false,
              Active: false,
              Completed: false,
              All: true,
            });
            e.stopPropagation();
          }}
        >
          Clear all filters
        </div>
        <Button
          type="primary"
          className="whiteButton"
          onClick={() => {
            handleChange("openFilter", false);
          }}
        >
          {/*{filterBy && filterBy !== 'All' ? "Filter" : "Close"}*/}
          Close
        </Button>
      </Row>
    </Col>
  );
};

export { Filter };
