import React, { useState } from "react";
// import { Select, Row, Collapse } from "antd";
// import SortByGroup from "scenes/ProjectPlanner/SortByGroup";
import ActionTable from "../ActionTable";

// const { Panel } = Collapse;

export default function ActionItems({ short, planDetails, isShared }) {
  /* const [state, setState] = useState({
    activeKey: [],
  }); */
  /* const { activeKey } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  }; */
  /* const handleCollapsed = (value) => {
    handleChange("activeKey", value);
  };

  const handleCollapsedSingle = (value) => {
    if (activeKey.some((e) => e === value)) {
      const removed = activeKey.filter((e) => e !== value);
      handleChange("activeKey", removed);
    } else {
      const added = [...activeKey, value];
      handleChange("activeKey", added);
    }
  }; */

  // const list = ["Equipment", "Initial actions"];

  return (
    <>
      <ActionTable short={short} planDetails={planDetails} isShared={isShared} />
      {/* {list.map((selected, index) => (
        <Collapse
          key={index}
          defaultActiveKey={[`1`]}
          activeKey={activeKey}
          expandIconPosition='right'
          ghost
        >
          <Panel
            style={{ minHeight: 50, marginTop: 20 }}
            header={
              <div
                className='font-18 text_500 mr-3'
                onClick={() => handleCollapsedSingle(`${index + 1}`)}
              >
                {selected} (4)
              </div>
            }
            key={`${index + 1}`}
          >
            <ActionTable />
          </Panel>
        </Collapse>
      ))} */}
    </>
  );
}
