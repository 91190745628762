import { Layout, Dropdown, Menu, Select } from "antd";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

import DMLogoMobile from "assets/images/LogoDMmobile.png";
import Burger from "assets/images/Burger.png";
import MSLogo from "assets/images/logo-old.png";

import { ReactComponent as NotificationIcon } from "assets/svg/notification-white.svg";
import { ReactComponent as LogoDM } from "assets/svg/logoDM_1_5.svg";
import { ReactComponent as PPLogo } from "assets/svg/logoPP_1_2.svg";
import { ReactComponent as AvatarIcon } from "assets/svg/avatar.svg";

import styles from "./Header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "modules/actions/AuthActions";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Notification from "./Notification";
import { getProjectNotifications } from "modules/actions/UserActions";

import useWindowSize from "../../../utils/useWindowSize";
import { Helmet } from "react-helmet";
import AppSwitcher from "components/AppSwitcher";
import { getToken } from "utils/utility";
import { useProject } from "../../../utils/utility";

const renderLogo = (project, width) => {
  if (project === "project-planner") {
    return (
      <Link to="/project-planner" className={styles.logo}>
        <PPLogo className={styles.pplogo} />
      </Link>
    );
  } else if (project === "decision-manager" && width >= 1200) {
    return (
      <Link to="/decision-manager" className={styles.logo}>
        <LogoDM className={styles.dmlogo} />
      </Link>
    );
  } else if (project === "decision-manager" && width < 1200) {
    return (
      <Link to="/decision-manager" className={styles.logo}>
        <img src={DMLogoMobile} alt="" />
      </Link>
    );
  } else if (project === "mission-statement") {
    return (
      <Link to="/mission-statement" className={styles.logo}>
        <img src={MSLogo} alt="" />
      </Link>
    );
  }
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Header({ sidebarOpen, setsidebarOpen }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const query = useQuery();

  const isShared = pathname.includes("shared-decision") || pathname.includes('shared-project');
  const secure_token = query.get("secure_token");

  const { id } = useParams();
  const { width } = useWindowSize();
  const { project, defaultProject } = useProject();

  const [state, setState] = useState({
    openNotification: false,
  });

  const { openNotification } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const { notifications } = useSelector((state) => state.user);

  const {
    user: { data: userData },
  } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout(() => {
      navigate(`/sign-in?app=${defaultProject}`);
    }));
  };

  const isLoggedIn = getToken();

  useLayoutEffect(() => {
    if (!isLoggedIn)
      return;
    dispatch(getProjectNotifications());
  }, [isLoggedIn]);

  useEffect(() => {
    if (id) {
      handleChange("id", id);
      handleChange("openNotification", false);
    }
  }, [id]);

  const onClose = (value) => {
    handleChange("openNotification", value);
  };

  const getCountNotification = () => {
    let count = 0;
    for (let i = 0; i < notifications?.data?.length; i++) {
      const element = notifications?.data[i];
      if (!element?.read) {
        count = count + 1;
      }
    }
    return count;
  };

  const menu = (
    <Menu
      className={"removeOption"}
      items={[
        {
          key: "1",
          onClick: () => navigate(`/${project}/profile`),
          label: <span className={styles.options}>View user profile</span>,
        },
        /* {
                  key: "2",
                  onClick: () => navigate("/settings"),
                  label: <span className={styles.options}>change settings</span>,
                }, */
        /* {
                  type: "divider",
                  style: { backgroundColor: "#4C545B" },
                }, */
        {
          key: "3",
          onClick: handleLogout,
          label: <span className={styles.optionRed}>Logout</span>,
        },
      ]}
    />
  );

  return (
    <Layout.Header className={styles.header}>
      <Helmet>
        <title>
          {project === "project-planner"
            ? "Project Planner"
            : "Decision Manager App - Optimize Your Decisions with Multi-Criteria Approach | AROOTAH"}
        </title>
      </Helmet>
      {/* <div
        className={styles.menuIcon}
        onClick={() => setsidebarOpen(!sidebarOpen)}
      >
        <MenuIcon />
      </div> */}
      <div className={styles.headerbody}>
        <div className={styles.logoWrapper}>
          {width < 1200 && project === "decision-manager" && (
            <div onClick={() => setsidebarOpen(!sidebarOpen)}>
              <img src={Burger} alt="" />
            </div>
          )}
          <div className={styles.row}>{renderLogo(project, width)}</div>
        </div>
        <div className={styles.profileView}>
          {project !== "decision-manager" && !isShared && (
            <>
              <Select
                className={styles.notificationIcon}
                bordered={false}
                open={openNotification}
                onClick={() =>
                  handleChange("openNotification", !openNotification)
                }
                onDropdownVisibleChange={(open) => onClose(false)}
                placement="bottomRight"
                dropdownMatchSelectWidth={false}
                dropdownRender={(menu) => (
                  <Notification
                    handleChange={handleChange}
                    open={openNotification}
                  />
                )}
              ></Select>

              <NotificationIcon className={styles.notification} />

              {getCountNotification() > 0 && (
                <div className={styles.count}>{getCountNotification()}</div>
              )}
            </>
          )}

          {!(isShared && !userData?.id) && <div className={styles.profileText}>
            <div className={styles.headerlogoTextDes1}>Welcome!</div>
            <div className={styles.headerlogoText}>
              {userData && userData.name}
              {secure_token && "GUEST"}
            </div>
          </div>}

          {!secure_token && !(isShared && !userData?.id) && (
            <Dropdown overlay={menu} trigger={["click"]}>
              {userData.display_picture ? (
                <img
                  className={styles.profilePicture}
                  src={userData.display_picture}
                />
              ) : (
                <AvatarIcon className={styles.profilePicture} />
              )}
            </Dropdown>
          )}

          {userData?.id && <AppSwitcher />}
        </div>
      </div>
    </Layout.Header>
  );
}
