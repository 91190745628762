import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tag,
  Upload,
  message,
  Button,
  Popover,
  Divider,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined as PlusOutlinedAnt,
} from "@ant-design/icons";
import { ReactComponent as PlusOutlined } from "assets/svg/Plus.svg";
import { COLORS } from "constants/colors";
import AppInput from "components/AppInput";
import { validateEmail } from "utils/utility";
import { inviteOwner } from "modules/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { createTool } from "modules/actions/PlanActions";
import { PDFURL } from "utils/constants";
import { FileIcon, defaultStyles } from "react-file-icon";

import PublicLink from './components/PublicLink';

/* const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  }); */

export default function Resources({
  handleChange,
  handleRemoveTool,
  planDetails,
  handleAddRFile,
  handleRemoveRFile,
  users,
  selectedUsers,
  handleSubmit,
  loading,
  peoplePop,
  toolPop,
  tools,
  selectedTools,
}) {
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const { loading: inviteLoading } = useSelector(
    (state) => state.user.invitedUser
  );
  const { loading: invitePlan } = useSelector((state) => state.plan.plan);
  const { user } = useSelector((state) => state.auth);
  const [state, setState] = useState({
    allTools: tools || [],
    allUsers: users || [],
    searchTool: "",
    searchUser: "",
    disabledToolButton: true,
    disabledPeopleButton: true,
  });
  const {
    allTools,
    allUsers,
    searchTool,
    searchUser,
    disabledToolButton,
    disabledPeopleButton,
  } = state;

  const handleChangeLocal = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const handleChangeFile = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const setFileFormat = () => {
    const list = [];
    planDetails?.resource?.files?.map((file) => {
      list.push({
        uid: file?.id,
        name: get_url_name(file?.url),
        status: "done",
        url: file?.url,
      });
    });
    return list || [];
  };

  useEffect(() => {
    if (planDetails) {
      setFileList(setFileFormat());
    }
  }, [planDetails]);

  useEffect(() => {
    if (users) {
      if (searchUser) {
        const newlyAddedUser = users?.find((e) => e?.email === searchUser);
        if (newlyAddedUser) {
          handleAddSelectedUsers(newlyAddedUser);
          handleChangeLocal("searchUser", "");
        }
      }
      handleChangeLocal("allUsers", users);
      handleChangeLocal("allTools", tools);
    }
  }, [users, tools]);

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  function get_url_name(url) {
    const a = url?.split("/");
    const b = a?.length > 0 && a[a?.length - 1];
    return b;
  }

  const props = {
    headers: {
      authorization: "authorization-text",
    },
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    name: "file",
    listType: "picture-card",
    fileList: fileList,
  };

  const removeAlready = () => {
    const removed = allUsers?.filter(
      (elem) => !selectedUsers?.find(({ id }) => elem.id === id)
    );
    return removed;
  };

  const removeToolsAlready = () => {
    const removed = allTools?.filter(
      (elem) => !selectedTools?.find(({ id }) => elem.id === id)
    );
    return removed;
  };

  const checkSameTool = (value) => {
    const found = allTools?.some((elem) => elem?.name === value);
    return found;
  };

  const handleAddSelectedUsers = (owner) => {
    handleChangeLocal("disabledPeopleButton", false);
    if (selectedUsers?.some((e) => e?.id === owner?.id)) {
      const removed = selectedUsers?.filter((e) => e?.id !== owner?.id);
      handleChange("selectedUsers", removed);
    } else {
      handleChange("selectedUsers", [...selectedUsers, owner]);
    }
  };

  const handleAddSelectedTools = (tool) => {
    handleChangeLocal("disabledToolButton", false);
    if (selectedTools?.some((e) => e?.id === tool?.id)) {
      const removed = selectedTools?.filter((e) => e?.id !== tool?.id);
      handleChange("selectedTools", removed);
    } else {
      handleChange("selectedTools", [...selectedTools, tool]);
    }
  };

  const handleAddPeople = () => {
    const ids = [];
    selectedUsers.forEach((element) => {
      ids?.push(element?.id);
    });
    const payload = { people: ids };
    handleSubmit(payload, "Peoples has been updated");
    handleChangeLocal("disabledPeopleButton", true);
  };

  const handleAddTool = () => {
    const ids = [];
    selectedTools.forEach((element) => {
      ids?.push(element?.id);
    });
    const payload = { tools: ids };
    handleSubmit(payload, "Tools has been updated");
    handleChangeLocal("disabledToolButton", true);
  };

  const filtered = (key, value, isTool) => {
    let newValue = value;

    if (newValue.length > 60)
      newValue = newValue.substring(0, 60);

    handleChangeLocal(key, newValue);

    const data = isTool ? tools : users;
    const selectedState = isTool ? "allTools" : "allUsers";

    if (newValue) {
      // const re = new RegExp(newValue, "i");
      var filtered = data?.filter((entry) =>
        entry[!isTool ? "email" : "name"]
          ?.toLowerCase()
          ?.includes(newValue?.toLowerCase())
      );

      handleChangeLocal(selectedState, filtered);
    } else {
      handleChangeLocal(selectedState, data);
    }
  };

  const handleAddNewTool = () => {
    const payload = {
      name: searchTool,
      project_plan: planDetails?.id,
    };

    dispatch(createTool(payload, handleOwnerModal));
  };

  const handleInvite = () => {
    const payload = { invited_owner: searchUser };
    const qs = ``;
    dispatch(inviteOwner(payload, handleOwnerInviteModal, qs));
  };

  const handleOwnerModal = () => {
    // handleChange('peoplePop', false)
    handleChangeLocal("searchUser", "");
    handleChangeLocal("searchTool", "");
  };

  const handleOwnerInviteModal = () => {
    // if (searchUser) {
    // }
    // handleChangeLocal("searchUser", "");
  };

  const uploadButton = (
    <div className="addattachmentPlus">
      <PlusOutlinedAnt />
    </div>
  );

  return (
    <>
      <Row justify="space-between" gutter={16}>
        <Col span={16}>
          <Card bordered={false}>
            <div className="text_blue_dark font-18">People</div>
            <Row className="mt-1">
              {planDetails?.resource?.people?.map(people => (
                <div key={people?.id} className="greyBox">
                  {people?.email?.substring(0, 2)}
                </div>
              ))}
              <Popover
                content={
                  <div>
                    <div className="text_bold font-16">Collaborators</div>
                    <div className="peopleHeight">
                      {selectedUsers?.map((people, index) => (
                        <Row
                          className="mt-1"
                          align="middle"
                          justify="space-between"
                          key={people?.id}
                        >
                          <Row align="middle">
                            <div key={index} className="greyBox">
                              {people?.email?.substring(0, 2)}
                            </div>
                            <div>{people?.email}</div>
                          </Row>
                          <Button
                            onClick={() => handleAddSelectedUsers(people)}
                            color={COLORS.primary}
                            className={"b-1 addedButton"}
                          >
                            ADDED <CloseOutlined />
                          </Button>
                        </Row>
                      ))}
                    </div>
                    <Divider />
                    <AppInput
                      label={"Add collaborator"}
                      value={searchUser}
                      name={"searchUser"}
                      onChange={filtered}
                      placeholder="Choose a collaborator"
                    />
                    <div className="peopleHeight">
                      {removeAlready()?.length === 0 && searchUser !== "" && (
                        <Row
                          className="mt-1 mb-1"
                          align="middle"
                          justify="space-between"
                        >
                          <Button
                            onClick={handleInvite}
                            loading={inviteLoading}
                            color={COLORS.primary}
                            type="primary"
                            disabled={
                              searchUser === user?.data?.email ||
                              !validateEmail(searchUser)
                            }
                            style={{ color: COLORS.white }}
                            className={"b-1 addButton"}
                            // icon={<PlusOutlined />}
                          >
                            <PlusOutlined style={{ marginTop: 2 }} />{" "}
                            {searchUser === user?.data?.email
                              ? "You cannot invite yourself"
                              : validateEmail(searchUser)
                              ? "Invite " + searchUser
                              : "No results. Enter email to invite"}
                          </Button>
                        </Row>
                      )}
                      {removeAlready()?.map(owner => (
                        <Row
                          className="mt-1 mb-1"
                          align="middle"
                          justify="space-between"
                          key={owner?.id}
                        >
                          <Row align="middle">
                            <div className="greyBox">
                              {owner?.email?.substring(0, 2)}
                            </div>
                            <div>{owner?.email}</div>
                          </Row>
                          <Button
                            onClick={() => handleAddSelectedUsers(owner)}
                            color={COLORS.primary}
                            type="primary"
                            className={"b-1 addButton"}
                          >
                            <PlusOutlined style={{ marginTop: 2 }} />
                            ADD
                          </Button>
                        </Row>
                      ))}
                    </div>
                    <Divider className="width110" />
                    <Row justify="end">
                      <Button
                        type="primary"
                        loading={loading}
                        disabled={disabledPeopleButton}
                        onClick={handleAddPeople}
                        className="createPlanBtn"
                      >
                        {"Save"}
                      </Button>
                    </Row>
                  </div>
                }
                trigger="click"
                overlayClassName="peoplePop"
                placement="bottomRight"
                open={peoplePop}
                onOpenChange={(value) => handleChange("peoplePop", value)}
              >
                <Button
                  type="text"
                  onClick={() => handleChange("peoplePop", true)}
                  className="text_primary b-1 py-0 bg_light_blue font-12"
                  style={{ height: 22 }}
                >
                  + Add
                </Button>
              </Popover>
            </Row>
            {/* </Card>
          <Card className='b-1 mt-2' bordered={false}> */}
            <Row className="mt-2">
              <div className="mr-2">
                <div className="text_blue_dark font-18">Project tools</div>
                <div className="tagsRow mt-1">
                  {planDetails?.resource?.tools?.map((tool, index) => (
                    <Tag
                      key={index}
                      // onClose={() => handleRemoveTool(tool?.id)}
                      color={COLORS.toolBG}
                      className={"b-1 text_tag"}
                    >
                      {tool?.name}
                    </Tag>
                  ))}
                  <Popover
                    content={
                      <div style={{ width: '100%', maxWidth: 480 }}>
                        <div className="text_bold font-16">Tools</div>
                        <div className="peopleHeight">
                          <div className="tagsRow">
                            {selectedTools?.map((tool, index) => (
                              <Tag
                                key={index}
                                closable
                                onClose={() => handleAddSelectedTools(tool)}
                                color={COLORS.toolBG}
                                className={"text_tag mr-0"}
                              >
                                {tool?.name}
                              </Tag>
                            ))}
                          </div>
                        </div>
                        <Divider />
                        <AppInput
                          label={"Add tool"}
                          value={searchTool}
                          name="searchTool"
                          onChange={(key, value) => filtered(key, value, true)}
                          placeholder="Choose a tool"
                        />
                        <div className="peopleHeight">
                          {removeToolsAlready()?.length === 0 &&
                            searchTool !== "" && (
                              <Row
                                className="mt-1 mb-1"
                                align="middle"
                                justify="space-between"
                              >
                                <Button
                                  onClick={handleAddNewTool}
                                  loading={invitePlan}
                                  color={COLORS.primary}
                                  type="primary"
                                  disabled={checkSameTool(searchTool)}
                                  style={{ color: COLORS.white }}
                                  className={"b-1 addButton"}
                                >
                                  <PlusOutlined style={{ marginTop: 2 }} />
                                  {checkSameTool(searchTool)
                                    ? "You already have this tool"
                                    : 'Add New Tool "' + searchTool + '"'}
                                </Button>
                              </Row>
                            )}
                          {removeToolsAlready()?.map((tool, index) => (
                            <Row
                              key={index}
                              className="mt-1 mb-1"
                              align="middle"
                              justify="space-between"
                            >
                              <Row align="middle">
                                <div>{tool?.name}</div>
                              </Row>
                              <Button
                                onClick={() => handleAddSelectedTools(tool)}
                                color={COLORS.primary}
                                type="primary"
                                className={"b-1 addButton"}
                                // icon={<PlusOutlined />}
                              >
                                <PlusOutlined style={{ marginTop: 2 }} /> ADD
                              </Button>
                            </Row>
                          ))}
                        </div>
                        <Divider className="width110" />
                        <Row justify="end">
                          <Button
                            type="primary"
                            loading={loading}
                            disabled={disabledToolButton}
                            onClick={handleAddTool}
                            className="createPlanBtn"
                          >
                            {"Save"}
                          </Button>
                        </Row>
                      </div>
                    }
                    trigger="click"
                    overlayClassName="toolPop"
                    placement="bottomLeft"
                    open={toolPop}
                    onOpenChange={(value) => handleChange("toolPop", value)}
                  >
                    <Button
                      type="text"
                      onClick={() => handleChange("toolPop", true)}
                      className="text_primary b-1 py-0 bg_light_blue font-12"
                      style={{ height: 22 }}
                    >
                      + Add
                    </Button>
                  </Popover>
                </div>
              </div>
            </Row>
            {/* </Card>
          <Card className='b-1 mt-2' bordered={false}> */}
            <div className="text_blue_dark font-18 mt-2 mb-1">
              Project documents
            </div>
            <div>
              <Upload
                {...props}
                // accept={
                //   "application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword, application/vnd.ms-excel"
                // }
                iconRender={(file) => {
                  return (
                    <div className="fileIcon">
                      <FileIcon
                        color="#1271a6"
                        extension={get_url_extension(file?.url)}
                      />
                    </div>
                  );
                }}
                onChange={(response) => {
                  // handleChangeFile(response)
                  if (response.file.status === "removed") {
                    handleRemoveRFile(response.file.uid);
                  } else {
                    const payload = new FormData();
                    payload.append("url", response.file?.originFileObj);
                    payload.append("project_plan", planDetails?.id);
                    handleAddRFile(payload);
                  }
                }}
              >
                {uploadButton}
                <div className="font-12 addattachment">Add attachment</div>
              </Upload>
            </div>

            <Divider />

            <div className="text_blue_dark font-18 mt-2 mb-1">
              Public Link
            </div>

            <PublicLink project={planDetails} />
          </Card>
        </Col>
        {/* <Col span={8}>
          <Card className=''>
            <Row justify='space-between'>
              <div className='text_grey'>06.03.2021</div>
              <DotsBorderLess />
            </Row>
            <div className='mt-1 text_500'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa
              ipsum at enim blandit morbi. Nisi id amet.
            </div>
          </Card>
          <Card className='mt-1'>
            <Row
              align='middle'
              className='c-pointer'
              onClick={() => {
                handleChange('noteModal', true)
                handleChange('notetype', 'resource')
              }}
            >
              <PlusOutlined className='text_primary' />
              <div className='text_primary ml-1'>Add new diary entry</div>
            </Row>
          </Card>
        </Col> */}
      </Row>
    </>
  );
}
