import { Button, Divider, Modal, Row } from 'antd'
import React, { useState } from 'react'
import { ReactComponent as Cross } from 'assets/svg/deleteIcon.svg'
import AppInput from 'components/AppInput'

export default function CreateToolModal ({
  handleChange,
  visible,
  handleSubmit,
  loading,
  toolName,
  id
}) {
  return (
    <Modal
      footer={null}
      closable={false}
      onCancel={() => handleChange('createToolModal', false)}
      title={false}
      visible={visible}
    >
      <Row justify='space-between'>
        <div className='font-16 text_black text_bold'>Add Tool</div>
        <Cross
          onClick={() => handleChange('createToolModal', false)}
          className={'c-pointer'}
        />
      </Row>
      <Divider />
      <AppInput
        label={'Tool Name'}
        placeholder={'Enter name'}
        value={toolName}
        name='toolName'
        onChange={handleChange}
      />
      <Button
        // icon={<MailOutlined />}
        className='inviteButton mt-2 buttonHeight b-1'
        type='primary'
        loading={loading}
        onClick={() =>
          handleSubmit({ name: toolName, project_plan: Number(id) })
        }
      >
        Add
      </Button>
    </Modal>
  )
}
