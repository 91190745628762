import React from "react";
import classes from "./deleteModal.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import useWindowSize from "../../../../utils/useWindowSize";
import { DeleteOption } from "redux/DecisionMatrix/DeleteOption/action";
import { DeleteDecision } from "redux/DecisionMatrix/DeleteDecision/action";
import { DeleteCriteria } from "redux/DecisionMatrix/DeleteCriteria/action";

const DeleteModal = ({ decisionId, value, item, setIsShowDeleteModal }) => {
  const closeModal = () => {
    setIsShowDeleteModal(false);
  };

  const dispatch = useDispatch();
  const deleteItem = async () => {
    if (value === "option") {
      dispatch(DeleteOption(item.id, decisionId));
    }

    if (value === "decision") {
      dispatch(DeleteDecision(item.id));
    }

    if (value === "criteria") {
      dispatch(DeleteCriteria(item.id, decisionId));
    }

    closeModal();
  };
  const { width } = useWindowSize();
  return (
    <div className={classes.bg}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <span>Delete {value}</span>
          <div onClick={closeModal} className={classes.close}>
            <CloseOutlined style={{ color: "white" }} />
          </div>
        </div>
        <div className={classes.body}>
          <div>
            Are you sure you want to delete this <span>{value}</span>?
          </div>
          {/* <div className={classes.item}>{item.name}</div> */}
        </div>

        {width > 767 ? (
          <div className={classes.button}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                deleteItem();
              }}
            >
              DELETE
            </button>
          </div>
        ) : (
          <div className={classes.buttonMob}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                deleteItem();
              }}
            >
              DELETE
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export { DeleteModal };
