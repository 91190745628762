import { Button, Col, TimePicker, Divider, Modal, Row, Select } from 'antd'
import React from 'react'
import { ReactComponent as Cross } from 'assets/svg/deleteIcon.svg'
import { Days, DAY_NUMBER, REPEAT } from 'utils/constants'

const { Option } = Select
export default function ReminderModal ({
  handleChange,
  visible,
  handleRemove,
  handleCreate,
  loading,
  repeat,
  reminder_time,
  repeat_day
}) {
  return (
    <Modal
      footer={null}
      className={'deleteModal'}
      closable={false}
      onCancel={() => handleChange('reminderModal', false)}
      title={false}
      visible={visible}
    >
      <Row justify='space-between' align='middle' className='pt-2 ph-2'>
        <div className='font-16 text_black text_bold'>Reminder</div>
        <Cross
          onClick={() => handleChange('reminderModal', false)}
          className={'c-pointer'}
        />
      </Row>
      <Divider />
      <div className='ph-3'>
        <Row align='middle' className='mb-2'>
          <Col span={8}>
            <div>Repeat</div>
          </Col>
          <Col span={16}>
            <Select
              className='fullSelect PlanSelect'
              // style={{ borderRadius: 0 }}
              size='large'
              placeholder='Select Repeat'
              defaultValue={repeat}
              onChange={value => {
                handleChange('repeat_day', '')
                handleChange('repeat', value)
              }}
            >
              {REPEAT.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        {(repeat === 'Weekly' ||
          repeat === 'Monthly' ||
          repeat === 'Custom') && (
          <Row align='middle' className='mb-2'>
            <Col span={8}>
              <div>Select day</div>
            </Col>
            <Col span={16}>
              <Select
                className='fullSelect PlanSelect'
                // style={{ borderRadius: 0 }}
                size='large'
                placeholder='Select Repeat'
                defaultValue={repeat_day}
                value={repeat_day}
                dropdownRender={menu =>
                  repeat === 'Monthly' || repeat === 'Custom' ? (
                    <DaysNumber handleChange={handleChange} menu={menu} />
                  ) : (
                    <>{menu}</>
                  )
                }
                onChange={value => handleChange('repeat_day', value)}
              >
                {repeat === 'Weekly' &&
                  Days.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.key}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        )}
        {repeat && (
          <Row align='middle' className='mb-2'>
            <Col span={8}>
              <label className='inputLabel'>Select time</label>
            </Col>
            <Col span={16}>
              <TimePicker
                placeholder='Time'
                className='fullSelect'
                value={reminder_time}
                name={'reminder_time'}
                style={{ fontSize: 14, height: 40 }}
                onChange={date => handleChange('reminder_time', date)}
              />
            </Col>
          </Row>
        )}
      </div>
      <div className='ph-2 pb-2'>
        <Button
          // icon={<MailOutlined />}
          style={{ backgroundColor: '#1689CA' }}
          className='inviteButton buttonHeight uppercase b-1'
          type='primary'
          loading={loading}
          onClick={handleCreate}
        >
          Save changes
        </Button>
      </div>
    </Modal>
  )
}

function DaysNumber ({ menu, handleChange }) {
  return (
    <Row gutter={16}>
      {DAY_NUMBER.map((day, index) => (
        <Col
          key={index}
          className='p-1'
          span={3}
          onClick={() => handleChange('repeat_day', day)}
        >
          <div className='text-center text_black c-pointer'>{day}</div>
        </Col>
      ))}
    </Row>
  )
}
