import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import c from "classnames";

import Footer from "components/AppLayout/Footer";
import Header from "components/AppLayout/Header";
import Sidebar from "components/AppLayout/Sidebar";
import SidebarMobile from "components/AppLayout/SidebarMobile";

import styles from "./AppLayout.module.scss";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";
import { getProject, PROJECT } from "utils/constants";
import { MobilePlaceholder } from "scenes/DecisionMatrix/components/MobilePlaceholder";
import { websocketConnection } from "utils/websocket";

const { Content } = Layout;

export default function AppLayout({ children, hideFooter }) {
  const location = useLocation();
  const curentPage = location.pathname.includes("/mission-statement");

  const project = getProject(location.pathname);
  const { width } = useWindowSize();

  // States
  const [sidebarOpen, setsidebarOpen] = useState(false);

  return (
    <Layout className={styles.container}>
      {project === PROJECT.DM && width <= 767 && <MobilePlaceholder />}

      <Header sidebarOpen={sidebarOpen} setsidebarOpen={setsidebarOpen} />
      {/* Main */}
      <Layout className={styles.main}>
        {/* Side menu */}
        {!curentPage && width >= 1200 && (
          <Sidebar /*sidebarOpen={sidebarOpen}*/ pathname={location.pathname} />
        )}
        {width < 1200 && sidebarOpen && (
          <SidebarMobile pathname={location.pathname} />
        )}

        {/* Main content */}
        <Content
          className={c(
            styles.content,
            { [styles.dm]: project === PROJECT.DM },
            { [styles.ms]: project === PROJECT.MS },
            { [styles.not_pp_profile]: !location.pathname.includes("/project-planner/profile") }
          )}
          style={
            project === PROJECT.DM
              ? {
                  backgroundImage: `url(https://res.cloudinary.com/talaash/image/upload/v1689152842/BG_heq6tw.png)`,
                  // backgroundImage: `url(https://res.cloudinary.com/talaash/image/upload/v1676390799/bgDM_sowoby.svg)`,
                  padding: "0px",
                }
              : {}
          }
        >
          <div className={styles.wrapper}>{children}</div>
          {/* Footer */}
          {!curentPage && width >= 1200 && <Footer />}
          {width < 1200 && project === PROJECT.DM && (
            <div className={styles.footer}>© Arootah Visit arootah.com</div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}
