import DashboardIcon from 'assets/svg/dashboard.svg'
/* import MyCoach from 'assets/svg/MyCoach.svg'
import Goals from 'assets/svg/Goals.svg'
import Blueprint from 'assets/svg/Blueprint.svg'
// import ProjectPlanner from 'assets/svg/ProjectPlanner.svg'
import ProjectPlannerIcon from 'assets/svg/sidebar/ProjectPlannerIcon'
import PriorityMatrix from 'assets/svg/PriorityMatrix.svg'
import HabitTracker from 'assets/svg/HabitTracker.svg' */
import DecisionTool from 'assets/svg/DecisionTool.svg'
/* import SettingsIcon from 'assets/svg/sidebar/SettingsIcon.svg'
import Profile from 'assets/svg/sidebar/Profile.svg'
import Journal from 'assets/svg/Journal.svg'
import Calendar from 'assets/svg/Calendar.svg'
import MissionOfStatement from 'assets/svg/MissionOfStatement.svg' */

const sidebarItems = [
  // Project planner:
  {
    icon: DashboardIcon,
    key: 'dashboard',
    path: '/project-planner',
    extraParams: ['id'],
    subMenuItems: [],
    title: 'Dashboard',
    visibility: true,
    public: true,
    project: 'project-planner'
  },
  {
    icon: DecisionTool,
    key: 'profile',
    path: '/project-planner/profile',
    subMenuItems: [],
    title: 'Profile',
    visibility: true,
    project: 'project-planner'
  },

  // Decision matrix:
  {
    icon: DashboardIcon,
    key: 'dashboard',
    path: '/decision-manager',
    extraParams: ['decisionId'],
    subMenuItems: [],
    title: 'Dashboard',
    visibility: true,
    public: true,
    project: 'decision-manager'
  },
  {
    icon: DecisionTool,
    key: 'profile',
    path: '/decision-manager/profile',
    subMenuItems: [],
    title: 'Profile',
    visibility: true,
    project: 'decision-manager'
  },

  // {
  //   icon: Goals,
  //   key: '/goals',
  //   path: '/goals',
  //   subMenuItems: [],
  //   title: 'Goals',
  //   visibility: true
  // },
  // {
  //   icon: Blueprint,
  //   key: '/blueprint',
  //   path: '/blueprint',
  //   subMenuItems: [],
  //   title: 'Blueprint',
  //   visibility: true
  // },
  // {
  //   icon: ProjectPlannerIcon,
  //   key: '/project-planner',
  //   path: '/project-planner',
  //   subMenuItems: [],
  //   title: 'Project Planner',
  //   visibility: true
  // },
  // {
  //   icon: PriorityMatrix,
  //   key: '/priority-matrix',
  //   path: '/priority-matrix',
  //   subMenuItems: [],
  //   title: 'Priority Matrix',
  //   visibility: true
  // },
  // {
  //   icon: HabitTracker,
  //   key: '/habit-tracker',
  //   path: '/habit-tracker',
  //   subMenuItems: [],
  //   title: 'Habit tracker',
  //   visibility: true
  // },
  // {
  //   icon: Journal,
  //   key: '/learn',
  //   path: '/learn',
  //   subMenuItems: [],
  //   title: 'Learn',
  //   visibility: true
  // },
  // {
  //   icon: MyCoach,
  //   key: '/my-coach',
  //   path: '/my-coach',
  //   subMenuItems: [],
  //   title: 'My coach',
  //   visibility: true
  // },
  
  // {
  //   icon: Calendar,
  //   key: '/calendar',
  //   path: '/calendar',
  //   subMenuItems: [],
  //   title: 'Calendar',
  //   visibility: true
  // },
  
  // {
  //   icon: SettingsIcon,
  //   key: '/settings',
  //   path: '/settings',
  //   subMenuItems: [],
  //   title: 'Settings',
  //   visibility: true
  // }
]

export default sidebarItems
