import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DownOutlined,
  MailOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Col,
  Button,
  Typography,
  Form,
  Input,
  Collapse,
  Table,
  Row,
  Select,
  DatePicker,
  Modal,
  Tooltip,
} from "antd";
import { ReactComponent as Down } from "assets/svg/down.svg";
import { ReactComponent as DateIcon } from "assets/svg/dateIcon.svg";
import { ReactComponent as AddIcon } from "assets/svg/addIcon.svg";
import { ReactComponent as DragIcon } from "assets/svg/drag.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/delete.svg";
import { ReactComponent as ExpandIcon } from "assets/svg/expand.svg";
import { ReactComponent as ResizeIcon } from "assets/svg/Resize.svg";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  createActionItem,
  createPlanCategory,
  deletePlanCategory,
  updatePlanCategory,
  upateActionItem,
  deleteActionItem,
  upateNotes,
  changeActionItemsOrder,
  createNotes,
  deleteNotes,
} from "modules/actions/PlanActions";
import AdjustWeight from "../AdjustWeight";
import { weightsList, categoryCount } from "constants/index";
import { getTime, getTimeText, validateEmail } from "utils/utility";
import DeleteModal from "../Modal/DeleteModal";
import { durationList } from "utils/constants";
import { arrayMoveImmutable } from "array-move";
import AddNoteModal from "../Modal/AddNoteModal";
import { inviteOwner } from "modules/actions/UserActions";

const EditableContext = React.createContext(null);
const { Panel } = Collapse;
const { Option } = Select;
const { Paragraph } = Typography;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  category,
  addNewActionItem,
  addNewLocalActionItem,
  deleteCategory,
  setExpandedKeys,
  isAction,
  expandedNoteKeys,
  toggleExpandedNoteKeys,
  handleNotesSave,
  _deleteActionItem,
  dropdown,
  durationDropdown,
  handleAddCategory,
  handleChangeMain,
  removeNewList,
  inviteEmail,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [inviteEmailLocal, setInviteEmail] = useState("");
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { users: planUsers } = useSelector((state) => state.user.projectOwners);
  const { planDetails } = useSelector((state) => state.plan.plan);

  useEffect(() => {
    const handleClick = function (evt) {
      if (evt.target.classList.contains('ant-picker-cell-inner')) {
        if (evt.target.parentNode.classList.contains('ant-picker-cell-selected')) {
          setEditing(false);
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (editing && record?.name) {
      /* setTimeout(() => {
        inputRef?.current?.focus()
        inputRef?.current?.click()
      }, 250) */
      // inputRef?.current?.click()
    }
    if (editing && !record?.name && title === "Category & Action item") {
      inputRef?.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (!record?.name && title === "Category & Action item") {
      setIsNew(!isNew);
      setEditing(!editing);
      form.setFieldsValue({
        name: "",
      });
    }
  }, [record?.name]);

  const toggleEdit = () => {
    setEditing(!editing);

    if (title === "Due Date" || title === "Start Date") {
      if (record[dataIndex]) {
        form.setFieldsValue({
          [dataIndex]: moment(record[dataIndex]),
        });
      }
    } else if (title === "PLAN PROGRESS") {
      form.setFieldsValue({
        [dataIndex]: Number(record[dataIndex]),
      });
    } else {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };
  const toggleExpandedKeys = (key, openalways, isActionOpen) => {
    setExpandedKeys((prev) => {
      const outArr = [...prev];
      if (outArr?.includes(key) && !openalways) {
        if (!isActionOpen) {
          return outArr.filter((e) => e !== key);
        } else return outArr;
      } else {
        outArr.push(key);
        return outArr;
      }
    });
  };

  const save = async rawValue => {
    setEditing(false)

    try {
      const values = await form.validateFields();

      if (!values?.name && isNew) {
        removeNewList();
        return;
      }

      let payload;

      //Weekly duration validation function

      // if (title == "Weekly duration") {
      //   const currentCategory = planDetails?.categories?.find(
      //     (e) => e?.id === record?.category
      //   );
      //   let totalNumberOfHours = 0;
      //   currentCategory?.action_items?.map((item) => {
      //     if (item?.id !== record?.id) {
      //       totalNumberOfHours =
      //         getTotalHours(item?.duration) + totalNumberOfHours;
      //     }
      //   });
      //   totalNumberOfHours =
      //     getTotalHours(values?.duration) + totalNumberOfHours;
      //   if (totalNumberOfHours > 168) {
      //     toast("You should not select more than 168 hours under category", {
      //       type: "error",
      //     });
      //     return;
      //   }
      // }

      if (
        // (title === "Due Date" && !values?.dead_line) ||
        // (title === "Start Date" && !values?.start_line) ||
        (title === "PERSON RESPONSIBLE" && values?.responsible_person?.email)
      ) {
        return;
      }

      if (
        title !== "PERSON RESPONSIBLE" &&
        title !== "Due Date" &&
        title !== "Start Date"
      ) {
        delete record.responsible_person;
        delete record.start_line;
        delete record.dead_line;
        payload = { ...record, ...values };
      } else if (title === "Due Date") {
        delete record.start_line;
        delete record.responsible_person;

        if (rawValue === null) {
          payload = {
            ...record,
            dead_line: null,
          };
        }
        else if (rawValue && Boolean(rawValue.isValid && rawValue.isValid())) {
          payload = {
            ...record,
            dead_line: moment(rawValue).format("YYYY-MM-DD"),
          };
        }
        else {
          payload = {
            ...record
          }
        }
      } else if (title === "Start Date") {
        delete record.dead_line;
        delete record.responsible_person;

        if (rawValue === null) {
          payload = {
            ...record,
            start_line: null,
          };
        }
        else if (Boolean(rawValue.isValid && rawValue.isValid())) {
          payload = {
            ...record,
            start_line: moment(rawValue).format("YYYY-MM-DD"),
          };
        }
        else {
          payload = {
            ...record
          }
        }
      } else {
        payload = { ...record, ...values };
      }
      if (isNew) {
        if (isAction) {
          addNewActionItem(values?.name, record, toggleExpandedKeys);
        } else {
          handleAddCategory(values?.name);
        }
      } else {
        handleSave({ ...payload, duration_type: "custom" });
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const getCategoryByID = (c_id) => {
    return planDetails?.categories?.find((e) => e?.id === c_id);
  };

  let childNode = children;

  function isDateDisabled(current, title, record, planDetails) {
    if (!current) return true;

    const category = record?.category;
    const categoryDetails = category ? getCategoryByID(category) : null;

    const beforeCategoryStart = categoryDetails && current.isBefore(moment(categoryDetails.start_line), 'day');
    const afterCategoryDeadline = categoryDetails && current.isAfter(moment(categoryDetails.dead_line), 'day');

    const beforePlanStart = current.isBefore(moment(planDetails?.start_line), 'day');
    const afterPlanDeadline = current.isAfter(moment(planDetails?.dead_line), 'day');

    const afterRecordDeadline = current.isAfter(moment(record?.dead_line), 'day');

    if (title === "Start Date") {
      return beforeCategoryStart ||
        beforePlanStart ||
        afterCategoryDeadline ||
        afterPlanDeadline ||
        afterRecordDeadline;
    } else {
      return beforeCategoryStart ||
        beforePlanStart ||
        afterPlanDeadline ||
        (afterCategoryDeadline && current.diff(moment(categoryDetails.dead_line), 'days') > 0) ||
        current.isBefore(moment(record?.start_line), 'day');
    }
  }

  if (editable && !record?.isNewAction) {
    childNode = editing ? (
      <Row align="middle">
        <Form.Item
          style={{
            width: isNew ? "80%" : "100%",
            marginRight: isNew ? 20 : 0,
            margin: 0,
          }}
          name={dataIndex}
        /* rules={[
          {
            required: isNew ? false : true,
            message: `${title} is required.`
          }
        ]} */
        >
          {title === "Due Date" || title === "Start Date" ? (
            <>
              <DatePicker
                className="font-14"
                format={"MM/DD/YYYY"}
                disabledDate={(current) => isDateDisabled(current, title, record, planDetails)}
                autoFocus={true}
                open={editable}
                ref={inputRef}
                value={title === 'Due Date' ? record?.dead_line && moment(record?.dead_line) : record?.start_line && moment(record?.start_line)}
                onChange={save}
                onBlur={save}
              />
            </>
          ) : dropdown ? (
            <Select
              ref={inputRef}
              open={editable}
              autoFocus={true}
              className={"fullSelect PlanSelect"}
              placeholder="0"
              dropdownMatchSelectWidth={false}
              dropdownRender={(menu) => (
                <div className="weightlist">{menu}</div>
              )}
              onPressEnter={save}
              onChange={save}
              onBlur={save}
            >
              {(title === "WEIGHT" ? weightsList : categoryCount).map((count, index) => (
                <Option
                  key={index}
                  className=""
                  value={
                    title !== "WEIGHT" && title !== "PLAN PROGRESS"
                      ? count.value
                      : count.key
                  }
                >
                  {count.key}
                </Option>
              ))}
            </Select>
          ) : title === "PERSON RESPONSIBLE" ? (
            <Select
              open={editable}
              autoFocus={true}
              onSelect={save}
              onPressEnter={save}
              onBlur={save}
              ref={inputRef}
              onSearch={(e) => setInviteEmail(e)}
              className="fullSelect PlanSelect"
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ minWidth: 200 }}
              size="large"
              showSearch
              notFoundContent={
                validateEmail(inviteEmailLocal) ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      handleChangeMain("inviteModal", true);
                      handleChangeMain("inviteEmail", inviteEmailLocal);
                      handleChangeMain("selectedRecord", record);
                      // handleChange('inviteEmail', ownerText)
                      // handleChange('invited_owner', ownerText)
                    }}
                  >
                    Invite {inviteEmailLocal}
                  </Button>
                ) : (
                  "No results. Enter email to invite"
                )
              }
              placeholder="Select user"
            >
              {planUsers?.map((owner, index) => (
                <Option key={index} value={owner?.id}>
                  {owner?.email}
                </Option>
              ))}
            </Select>
          ) : durationDropdown ? (
            <Select
              open={editable}
              onChange={save}
              autoFocus={true}
              // onPressEnter={save}
              onBlur={save}
              dropdownMatchSelectWidth={false}
              ref={inputRef}
              className="fullSelect PlanSelect"
              size="large"
              placeholder="Select duration"
            >
              <Option value={""}>Select</Option>
              {durationList?.map((owner, index) => (
                <Option key={index} value={owner?.value}>
                  {owner?.key}
                </Option>
              ))}
            </Select>
          ) : (
            <Input
              ref={inputRef}
              autoFocus={true}
              onPressEnter={save}
              onBlur={save}
            />
          )}
        </Form.Item>
        {isNew && !record?.isNewAction && (
          <div className="deleteCategory" onClick={() => removeNewList()}>
            <DeleteIcon className="deleteIcon" />
          </div>
        )}
      </Row>
    ) : (
      <>
        {category ? (
          <Row justify="start" align="top" className="deleteDiv">
            <Tooltip title={record?.name}>
              <div
                style={{ marginTop: 2, height: 40 }}
                className="catName c-word-break"
                onClick={toggleEdit}
              >
                {record?.name}
              </div>
            </Tooltip>
            {record?.name && (
              <>
                <div className="hide">
                  <button
                    className="addAction"
                    onClick={() => {
                      addNewLocalActionItem(record, toggleExpandedKeys);
                      // addNewActionItem(record, toggleExpandedKeys)
                    }}
                  >
                    + ACTION ITEM
                  </button>
                  <button
                    type="button"
                    className="hide deleteCategory positionEnd"
                    onClick={() => deleteCategory(record?.id, record?.name)}
                    disabled={planDetails.categories.length <= 1}
                  >
                    <DeleteIcon className="deleteIcon" />
                  </button>
                </div>
              </>
            )}
          </Row>
        ) : (
          <>
            {!record?.isNewAction && isAction ? (
              <Collapse
                activeKey={expandedNoteKeys}
                defaultActiveKey={[`${record.id}`]}
                className={"actionItemCollapse"}
                ghost
              >
                <Panel
                  key={`${record.id}`}
                  style={{ width: "100%" }}
                  showArrow={false}
                  className="deleteDiv c-pointer"
                  header={
                    <Row justify="start" align="top" className="">
                      <div
                        onClick={toggleEdit}
                        style={{
                          textDecoration:
                            Number(record?.plan_progress) === 10
                              ? "line-through"
                              : "none",
                        }}
                        className="actionName c-word-break"
                      >
                        {record?.name}
                      </div>
                      {expandedNoteKeys.includes(`${record.id}`) ? (
                        <DownOutlined
                          style={{
                            fontSize: 10,
                            marginTop: 8,
                            color: "#FEAB3D",
                          }}
                          className="positionCaret"
                          onClick={() => toggleExpandedNoteKeys(`${record.id}`)}
                        />
                      ) : (
                        <RightOutlined
                          className="positionCaret"
                          style={{
                            fontSize: 10,
                            marginTop: 8,
                            color: "#FEAB3D",
                          }}
                          onClick={() => toggleExpandedNoteKeys(`${record.id}`)}
                        />
                      )}
                      <div
                        className="hide deleteCategory positionRight"
                        onClick={() =>
                          _deleteActionItem(record?.id, record?.name)
                        }
                      >
                        <DeleteIcon />
                      </div>
                    </Row>
                  }
                >
                  <div className="ml-1" style={{ width: 300 }}>
                    {record?.notes?.length > 0 ? (
                      <>
                        {record?.notes[0].context === "ADD NOTE" ? (
                          <div
                            className="addNote"
                            onClick={() => {
                              handleChangeMain(
                                "openNote",
                                record?.notes[0]?.id
                              );
                              handleChangeMain("noteText", "");
                              handleChangeMain("createNoteText", true);
                              handleChangeMain("createNote", false);
                            }}
                          >
                            <PlusOutlined className="plusIcon" />{" "}
                            {record?.notes[0]?.context?.slice(0, 85) +
                              (record?.notes[0]?.context?.length > 85
                                ? "..."
                                : "")}
                          </div>
                        ) : (
                          <Paragraph
                            className="font-12 notesText c-word-break"
                            style={{
                              marginTop: -10,
                              marginLeft: 2,
                              width: 300,
                            }}
                            onClick={() => {
                              handleChangeMain(
                                "openNote",
                                record?.notes[0]?.id
                              );
                              handleChangeMain("createNoteText", false);
                              handleChangeMain("createNote", false);
                              handleChangeMain(
                                "noteText",
                                record?.notes[0]?.context
                              );
                            }}
                          // editable={{
                          //   onChange: value =>
                          //     handleNotesSave(
                          //       record?.notes?.length > 0 && record?.notes[0]?.id,
                          //       { context: value }
                          //     ),
                          //   triggerType: 'text'
                          // }}
                          >
                            {record?.notes[0]?.context?.slice(0, 85) +
                              (record?.notes[0]?.context?.length > 85
                                ? "..."
                                : "")}
                          </Paragraph>
                        )}
                      </>
                    ) : (
                      <div
                        className="addNote"
                        onClick={() => {
                          handleChangeMain("openNote", record?.id);
                          handleChangeMain("createNote", true);
                          handleChangeMain("createNoteText", true);
                        }}
                      >
                        <PlusOutlined className="plusIcon" /> ADD NOTE
                      </div>
                    )}

                    {/* <Paragraph
                      className='font-12'
                      style={{ marginTop: -10, marginLeft: 2 }}
                      editable={{
                        onChange: value =>
                          handleNotesSave(
                            record?.notes?.length > 0 && record?.notes[0]?.id,
                            { context: value }
                          ),
                        triggerType: 'text'
                      }}
                    >
                      {(record?.notes?.length > 0 &&
                        record?.notes[0].context) ||
                        'Add note +'}
                    </Paragraph> */}
                  </div>
                </Panel>
              </Collapse>
            ) : (
              <>
                {record?.isNewAction ? (
                  <div></div>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{
                      width: "auto",
                      // paddingRight: 24
                    }}
                    onClick={toggleEdit}
                  >
                    {children}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const MyCustomTable = ({
  expandable,
  columns,
  dataSource,
  handleAdd,
  expandedKeys,
  setExpandedKeys,
  expendAllCat,
  planDetails,
  isShared,
  ...rest
}) => {
  const processedColumns = [...columns];

  processedColumns.unshift({
    title: (
      <div className="d-flex align-items-center">
        <Button
          type="text"
          onClick={handleAdd}
          style={{ marginLeft: -5 }}
          icon={<AddIcon />}
          disabled={isShared}
        />
        <Button
          type="text"
          onClick={() =>
            planDetails?.categories?.length === expandedKeys?.length
              ? expendAllCat("")
              : expendAllCat("expend")
          }
          // style={{ marginLeft: -10 }}
          icon={
            planDetails?.categories?.length === expandedKeys?.length ? (
              <ResizeIcon />
            ) : (
              <ExpandIcon />
            )
          }
        />
      </div>
    ),
    key: "expand",
    align: "center",
    className: "firstCell",
    width: 60,
    render: (_, record) =>
      expandedKeys.includes(record?.key) ? (
        <DownOutlined
          style={{ fontSize: 10, color: "#FEAB3D" }}
          onClick={() => {
            toggleExpandedKeys(record?.key);
          }}
        />
      ) : (
        <RightOutlined
          style={{ fontSize: 10, color: "#FEAB3D" }}
          onClick={() => {
            toggleExpandedKeys(record?.key);
          }}
        />
      ),
  });

  const toggleExpandedKeys = (key) => {
    setExpandedKeys((prev) => {
      const outArr = [...prev];
      if (outArr.includes(key)) {
        return outArr.filter((e) => e !== key);
      } else {
        outArr.push(key);
        return outArr;
      }
    });
  };

  return (
    <Table
      columns={processedColumns}
      dataSource={dataSource}
      key="loading-done"
      className="sticky"
      expandable={{
        ...expandable,
        defaultExpandAllRows: true,
        expandedRowKeys: expandedKeys,
        expandIconColumnIndex: -1,
      }}
      {...rest}
    />
  );
};

const ActionTable = ({ planDetails, short, isShared }) => {
  const dispatch = useDispatch();
  const { loadingDelete, loading } = useSelector((state) => state.plan.plan);
  const { loading: inviteLoading } = useSelector(
    (state) => state.user.invitedUser
  );
  const [isexpended, setIsexpended] = useState(true);
  const [state, setState] = useState({
    weightModal: false,
    deleteModalCat: false,
    deleteModalAction: false,
    openNote: "",
    createNoteText: false,
    createNote: false,
    noteText: "",
    id: "",
    catID: "",
    catName: "",
    actionID: "",
    actionName: "",
    tempCategoryList: [],
    tempActionItemList: [],
    updatedWeight: [],
  });
  const {
    weightModal,
    updatedWeight,
    actionID,
    actionName,
    deleteModalAction,
    openNote,
    createNoteText,
    createNote,
    noteText,
    catID,
    catName,
    deleteModalCat,
    id,
    tempCategoryList,
    tempActionItemList,
    inviteEmail,
    inviteModal,
    selectedRecord,
  } = state;

  const handleChangeMain = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleInvite = (e) => {
    const payload = { invited_owner: inviteEmail };
    dispatch(inviteOwner(payload, handleOwnerModal));
  };

  const onCloseNote = (e) => {
    handleChangeMain("openNote", "");
    handleChangeMain("noteText", "");
  };

  const handleOwnerModal = (a, b, users) => {
    if (users?.some((e) => e?.email === inviteEmail)) {
      const rp = users?.find((e) => e?.email === inviteEmail);
      const row = { ...selectedRecord, responsible_person: rp?.id };
      dispatch(upateActionItem(row?.id, row, planDetails?.id, true));
    }
    handleChangeMain("inviteModal", false);
  };

  let defaultColumns = [
    {
      title: "Category & Action item",
      editable: !isShared,
      dataIndex: "name",
      width: 350,
      rowClassName: "orangeRow",
      bordered: false,
      ellipsis: true,
      className: "underline",
      category: true,
      // align: 'center'
    },
    {
      title: "Weekly duration",
      className: "column-money",
      dataIndex: "duration",
      align: "center",
      width: 150,
      render: (text) => <div>{text ? getTime(text) : "-"}</div>,
    },
    {
      title: "Weight",
      dataIndex: "weight",
      width: 100,
      className: "underline",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            className: (Number(text) === 0 ? "ml-2 weightBG" : "ml-2") + (isShared ? '' : ' c-pointer'),
          },
          children: (
            <div
              style={{ pointerEvents: isShared ? 'none' : 'initial' }}
              onClick={() => {
                handleChangeMain("weightModal", true);
                handleChangeMain("id", record?.project_plan);
              }}
            >
              {text ? (Number(text) * 100).toFixed(2) : (0).toFixed(2)}%
            </div>
          ),
        };
      },
    },
    {
      title: "Plan Progress",
      align: "center",
      dataIndex: "plan_progress",
      width: 100,
      render: (text) => (
        <div>{text ? (Number(text) * 100).toFixed(0) : 0}%</div>
      ),
    },
    {
      title: "Results Progress",
      width: 100,
      align: "center",
      dataIndex: "category_progress",
      render: (text, record) => (
        <>
          {record?.isNewAction ? (
            ""
          ) : (
            <div>
              {text ? (Number(text) * 100).toFixed(2) : (0).toFixed(2)}%
            </div>
          )}
        </>
      ),
    },
    {
      title: "Person Responsible",
      width: 120,
      align: "center",
      dataIndex: "person",
      render: (text, record) => <div className=""></div>,
    },
    {
      title: "Start Date",
      align: "center",
      width: 150,
      // className: 'underline',
      editable: !isShared,
      dataIndex: "start_line",
      render: (text, record) => (
        <>
          {record?.isNewAction ? (
            ""
          ) : (
            <div className={isShared ? "table-cell-value" : "table-cell-value cursor-pointer"}>
              {record?.start_line
                ? moment(record?.start_line).format("MM/DD/YYYY")
                : "-"}{" "}
              {text && <DateIcon style={{ marginBottom: -2 }} />}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Due Date",
      align: "center",
      width: 150,
      // className: 'underline',
      editable: !isShared,
      dataIndex: "dead_line",
      render: (text, record) => (
        <>
          {record?.isNewAction ? (
            ""
          ) : (
            <div className={isShared ? "table-cell-value" : "table-cell-value cursor-pointer"}>
              {text ? moment(text).format("MM/DD/YYYY") : "-"}{" "}
              {text && <DateIcon style={{ marginBottom: -2 }} />}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Start Value",
      align: "center",
      width: 100,
      editable: !isShared,
      // className: 'underline',
      dataIndex: "start",
      render: (text, record) => <div>{text || "-"}</div>,
    },
    {
      title: "Current Value",
      align: "center",
      width: 100,
      editable: !isShared,
      // className: 'underline',
      dataIndex: "current",
      render: (text, record) => <div>{text || "-"}</div>,
    },
    {
      title: "Target value",
      align: "center",
      width: 100,
      editable: !isShared,
      // className: 'underline',
      dataIndex: "target",
      render: (text, record) => <div>{text || "-"}</div>,
    },
  ];

  const [count, setCount] = useState(3);

  const getCategoryByID = (c_id) => {
    return planDetails?.categories?.find((e) => e?.id === c_id);
  };

  // const addNewActionItem = (name, record, toggleExpandedKeys) => {
  //   const payload = {
  //     name,
  //     start_line: moment().format("YYYY-MM-DD"),
  //     // dead_line: moment(getCategoryByID(record?.category)?.dead_line).format(
  //     //   "MM/DD/YYYY"
  //     // ),
  //     project_plan: record?.project_plan,
  //     category: record?.category,
  //   };
  //   getCategoryByID(record?.category)?.dead_line &&
  //     (payload.dead_line = moment(
  //       getCategoryByID(record?.category)?.dead_line
  //     ).format("YYYY-MM-DD"));
  //   dispatch(createActionItem(payload, removeNewList));
  //   toggleExpandedKeys(record?.key);
  // };
  const addNewActionItem = (name, record, toggleExpandedKeys) => {
    const payload = {
      name,
      start_line: moment(planDetails?.start_line ?? new Date()).format("YYYY-MM-DD"),
      project_plan: record?.project_plan,
      category: record?.category,
    };

    if (getCategoryByID(record?.category)?.dead_line) {
      payload.dead_line = null;
    }

    dispatch(createActionItem(payload, removeNewList));
    toggleExpandedKeys(record?.key);
  };

  const addNewLocalActionItem = (record, toggleExpandedKeys) => {
    const payload = {
      name: "",
      start_line: "",
      project_plan: record?.project_plan,
      category: record?.id,
    };
    handleChangeMain("tempActionItemList", [...record?.action_items, payload]);
    toggleExpandedKeys && toggleExpandedKeys(record?.key, false, true);
  };

  const deleteCategory = () => {
    dispatch(
      deletePlanCategory(catID, planDetails?.id, closeDeleteCategoryModal)
    );
  };

  const deleteCategoryModal = (id, name) => {
    handleChangeMain("catID", id);
    handleChangeMain("catName", name);
    handleChangeMain("deleteModalCat", true);
  };

  const closeDeleteCategoryModal = () => {
    handleChangeMain("catID", "");
    handleChangeMain("catName", "");
    handleChangeMain("deleteModalCat", false);
  };

  const deleteActionsModal = (id, name) => {
    handleChangeMain("actionID", id);
    handleChangeMain("actionName", name);
    handleChangeMain("deleteModalAction", true);
  };

  const closeDeleteActionsModal = () => {
    handleChangeMain("actionID", "");
    handleChangeMain("actionName", "");
    handleChangeMain("deleteModalAction", false);
  };

  const _deleteActionItem = () => {
    dispatch(
      deleteActionItem(actionID, planDetails?.id, closeDeleteActionsModal)
    );
  };

  const [expandedNoteKeys, setExpandedNoteKeys] = useState([]);

  useEffect(() => {
    const actionItems = [];

    if (planDetails && planDetails.categories) {
      for (let i = 0; i < planDetails.categories.length; i++) {
        for (
          let x = 0;
          x < planDetails.categories[i].action_items.length;
          x++
        ) {
          actionItems.push(
            planDetails.categories[i].action_items[x].id.toString()
          );
        }
      }
    }

    setExpandedNoteKeys(actionItems);
  }, [planDetails]);

  const handleNotesSave = (id, row) => {
    if (createNote) {
      const payload = {
        ...row,
        action_item: id,
      };
      dispatch(createNotes(payload, planDetails?.id, handleNotesModal));
    } else {
      dispatch(upateNotes(id, row, planDetails?.id, handleNotesModal));
    }
  };

  const handleRemoveNote = (noteid) => {
    dispatch(deleteNotes(noteid, planDetails?.id, handleNotesModal));
  };

  const handleNotesModal = () => {
    handleChangeMain("openNote", "");
    handleChangeMain("createNoteText", false);
    handleChangeMain("noteText", "");
  };

  const expandedRowRender = (props) => {
    const toggleExpandedNoteKeys = (key) => {
      setExpandedNoteKeys((prev) => {
        const outArr = [...prev];
        if (outArr?.includes(key)) {
          return outArr.filter((e) => e !== key);
        } else {
          outArr.push(key);
          return outArr;
        }
      });
    };
    const handleNotesSave = (id, row) => {
      dispatch(upateNotes(id, row, planDetails?.id));
    };

    let ActionColumns = [
      {
        title: "Sort",
        dataIndex: "sort",
        width: 60,
        align: "center",
        className: "drag-visible firstCell",
        render: (text, record) =>
          record?.isNewAction ? (
            <PlusOutlined
              className={`mb-2 ${!isShared ? 'c-pointer' : ''}`}
              style={isShared ? { pointerEvents: 'none' } : {}}
              onClick={() => addNewLocalActionItem(props)}
            />
          ) : isShared ? null : <DragHandle />,
      },
      {
        title: "Category & Action item",
        editable: !isShared,
        dataIndex: "name",
        // className: 'underline',
        width: 350,
        isAction: true,
        ellipsis: true,
        expandedNoteKeys: expandedNoteKeys,
        toggleExpandedNoteKeys: toggleExpandedNoteKeys,
        handleNotesSave: handleNotesSave,
        _deleteActionItem: deleteActionsModal,
      },
      {
        title: "Weekly duration",
        className: "column-money",
        dataIndex: "duration",
        width: 150,
        durationDropdown: true,
        // className: 'underline',
        editable: !isShared,
        align: "center",
        render: (text, record) => (
          <>
            {record?.isNewAction ? (
              ""
            ) : (
              <div className={isShared ? "table-cell-value" : "table-cell-value cursor-pointer"}>
                {text && text !== "00:00:00" ? getTimeText(text) : "-"}
              </div>
            )}
          </>
        ),
      },
      {
        title: "WEIGHT",
        dataIndex: "weight",
        dropdown: true,
        width: 100,
        className: "orangeText",
        editable: !isShared,
        // className: 'underline',
        align: "center",
        render: (text, record) => (
          <>
            {record?.isNewAction ? (
              ""
            ) : (
              <div
                style={{ color: Number(text) > 7 ? "orange" : "black" }}
                className={isShared ? "table-cell-value" : "table-cell-value cursor-pointer"}
              >
                {text} <Down className="downIcon" />
              </div>
            )}
          </>
        ),
      },
      {
        title: "PLAN PROGRESS",
        align: "center",
        dataIndex: "plan_progress",
        dropdown: true,
        width: 100,
        // className: 'underline',
        editable: !isShared,
        render: (text, record) => (
          <>
            {record?.isNewAction ? (
              ""
            ) : (
              <div className={isShared ? "table-cell-value" : "table-cell-value cursor-pointer"}>
                {text ? Number(text) : 0} <Down className="downIcon" />
              </div>
            )}
          </>
        ),
      },
      {
        title: "Results Progress",
        // editable: true,
        width: 100,
        align: "center",
        dataIndex: "category_progress",
        render: (text, record) => (
          <>
            {record?.isNewAction ? (
              ""
            ) : (
              <div>{text ? (Number(text) * 100).toFixed(2) : 0}%</div>
            )}
          </>
        ),
      },
      {
        title: "PERSON RESPONSIBLE",
        align: "center",
        width: 120,
        editable: !isShared,
        dataIndex: "responsible_person",
        render: (text, record) => (
          <>
            {record?.isNewAction ? (
              ""
            ) : (
              <div className="greyBoxCategory">
                {record?.responsible_person?.email?.substring(0, 2)}
              </div>
            )}
          </>
        ),
      },
      {
        title: "Start Date",
        align: "center",
        width: 150,
        // className: 'underline',
        editable: !isShared,
        dataIndex: "start_line",
        render: (text, record) => (
          <>
            {record?.isNewAction ? (
              ""
            ) : (
              <div className={isShared ? "table-cell-value" : "table-cell-value cursor-pointer"}>
                {record?.start_line
                  ? moment(record?.start_line).format("MM/DD/YYYY")
                  : "-"}{" "}
                {text && <DateIcon style={{ marginBottom: -2 }} />}
              </div>
            )}
          </>
        ),
      },
      {
        title: "Due Date",
        align: "center",
        width: 150,
        // className: 'underline',
        editable: !isShared,
        dataIndex: "dead_line",
        render: (text, record) => {
          return (
            <>
              {record?.isNewAction ? (
                ""
              ) : (
                <div className={isShared ? "table-cell-value" : "table-cell-value cursor-pointer"}>
                  {text ? moment(text).format("MM/DD/YYYY") : "-"}{" "}
                  {text && <DateIcon style={{ marginBottom: -2 }} />}
                </div>
              )}
            </>
          )
        },
      },
      {
        title: "Start Value",
        align: "center",
        width: 100,
        editable: !isShared,
        dataIndex: "start",
        render: (text, record) => (
          <div>{record?.isNewAction ? "" : text || "-"}</div>
        ),
      },
      {
        title: "Current Value",
        align: "center",
        width: 100,
        editable: !isShared,
        dataIndex: "current",
        render: (text, record) => (
          <div>{record?.isNewAction ? "" : text || "-"}</div>
        ),
      },
      {
        title: "TARGET",
        width: 100,
        align: "center",
        editable: !isShared,
        dataIndex: "target",
        render: (text, record) => (
          <div>{record?.isNewAction ? "" : text || "-"}</div>
        ),
      },
    ];

    if (short === "short") {
      ActionColumns = ActionColumns?.filter(
        (e) =>
          e.title !== "Results Progress" &&
          e.title !== "Start Value" &&
          e.title !== "Current Value" &&
          e.title !== "TARGET"
      );
    }
    const columns = ActionColumns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          isAction: col.isAction,
          dropdown: col.dropdown,
          durationDropdown: col.durationDropdown,
          addNewActionItem: addNewActionItem,
          removeNewList: removeNewList,
          handleChangeMain: handleChangeMain,
          setExpandedKeys: setExpandedKeys,
          handleSave: handleActionSave,
          expandedNoteKeys: col.expandedNoteKeys,
          toggleExpandedNoteKeys: col.toggleExpandedNoteKeys,
          handleNotesSave: col.handleNotesSave,
          handleRemoveNote: handleRemoveNote,
          _deleteActionItem: col._deleteActionItem,
          handleInvite: handleInvite,
          inviteEmail: inviteEmail,
          inviteModal: inviteModal,
        }),
      };
    });

    const handleActionSave = (row) => {
      dispatch(upateActionItem(row?.id, row, planDetails?.id, true));
    };

    const DragHandle = SortableHandle(() => <DragIcon />);

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable(
          props?.action_items?.slice(),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        const actions = [];
        newData?.forEach((element, index) => {
          if (element) {
            actions?.push({
              id: element?.id,
              order_id: index,
            });
          }
        });
        const payload = {
          category: props?.id,
          actions,
        };
        dispatch(changeActionItemsOrder(payload, planDetails?.id));
      }
    };

    const DraggableContainer = (props) => (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      let id =
        props?.action_items?.length > 0
          ? [
            ...props?.action_items,
            { id: props?.action_items?.length, isNewAction: true },
          ]
          : [{ id: props?.action_items?.length, isNewAction: true }];
      id = id?.findIndex((x) => x?.id === restProps["data-row-key"]);
      return <SortableItem index={id} {...restProps} />;
    };

    return (
      <Table
        locale={{ emptyText: <p className="mb-0"></p> }}
        columns={columns}
        rowKey="id"
        key="loading-done"
        expandable={{ defaultExpandAllRows: true }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
            cell: EditableCell,
          },
        }}
        showHeader={false}
        className={"actionItemHeader"}
        dataSource={
          tempActionItemList?.length > 0 &&
            tempActionItemList[tempActionItemList?.length - 1]?.category ===
            props?.id
            ? tempActionItemList
            : props?.action_items?.length > 0
              ? [
                ...props?.action_items,
                { id: props?.action_items?.length, isNewAction: true },
              ]
              : [{ id: props?.action_items?.length, isNewAction: true }]
        }
        pagination={false}
      />
    );
  };

  const handleAdd = () => {
    const payload = {
      name: "",
      start_line: "",
      project_plan: planDetails?.id,
    };
    handleChangeMain("tempCategoryList", [...planDetails?.categories, payload]);
    setCount(count + 1);
  };

  const handleAddCategory = (name) => {
    const payload = {
      name,
      start_line: moment(planDetails?.start_line ?? new Date()).format("YYYY-MM-DD"),
      project_plan: planDetails?.id,
    };
    dispatch(createPlanCategory(payload, removeNewList));
    setCount(count + 1);
  };

  const removeNewList = () => {
    handleChangeMain("tempCategoryList", []);
    handleChangeMain("tempActionItemList", []);
  };

  const handleSave = (row) => {
    dispatch(updatePlanCategory(row?.id, row, true));
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const [expandedKeys, setExpandedKeys] = useState([]);
  if (short === "short") {
    defaultColumns = defaultColumns?.filter(
      (e) =>
        e.title !== "Results Progress" &&
        e.title !== "Start Value" &&
        e.title !== "Current Value" &&
        e.title !== "Target value"
    );
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleAddCategory: handleAddCategory,
        handleChangeMain: handleChangeMain,
        removeNewList: removeNewList,
        category: col.category,
        setExpandedKeys: setExpandedKeys,
        title: col.title,
        durationDropdown: col.durationDropdown,
        addNewActionItem: addNewActionItem,
        addNewLocalActionItem: addNewLocalActionItem,
        deleteCategory: deleteCategoryModal,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    if (planDetails) {
      expendAllCat("firstime");
    }
  }, [planDetails]);

  const expendAllCat = (type) => {
    const list = [];
    if (type === "firstime") {
      planDetails?.categories?.forEach((element, index) => {
        if (element) {
          list.push(`${index}`);
        }
      });
      if (list.length > 0 && isexpended) {
        setExpandedKeys(list);
        setIsexpended(false);
      }
    } else if (type === "expend") {
      planDetails?.categories?.forEach((element, index) => {
        if (element) {
          list.push(`${index}`);
        }
      });
      if (list.length > 0) {
        setExpandedKeys(list);
      }
    } else {
      setExpandedKeys([]);
    }
  };

  const getCategoriesData = (data) => {
    const list = [];
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        const element = data[i];
        if (element) {
          list.push({
            ...element,
            key: `${i}`,
          });
        }
      }
    }

    return list;
  };
  return (
    <Col span={24} className={"mt-2"}>
      {/* {planDetails?.categories?.length > 0 && (
        <Row
          align="middle"
          className={`${
            planDetails?.categories?.length === 3 ? "isActiveTab" : "inactiveTab"
          }  font-14 mr-1 ml-2 c-pointer`}
          // style={{ opacity: activeKey.length === 4 ? 0.5 : 1 }}
          // onClick={() =>
          //   handleCollapsed(activeKey.length === 4 ? [] : ["1", "2", "3", "4"])
          // }
        >
          <span>{planDetails?.categories?.length === 4 ? "Collapse all" : "Expand all"}</span>
          <ExpandIcon className={"ml-1"} />
        </Row>
      )} */}
      <MyCustomTable
        columns={columns}
        components={components}
        expandedKeys={expandedKeys}
        planDetails={planDetails}
        setExpandedKeys={setExpandedKeys}
        handleAdd={handleAdd}
        expendAllCat={expendAllCat}
        handleAddCategory={handleAddCategory}
        size={"small"}
        dataSource={
          tempCategoryList?.length > 0
            ? getCategoriesData(tempCategoryList) || []
            : getCategoriesData(planDetails?.categories) || []
        }
        expandable={
          planDetails?.categories?.length === 0
            ? false
            : {
              expandedRowRender,
              // expandedRowKeys
              // defaultExpandedRowKeys: ['0']
            }
        }
        scroll={{ x: 1300, y: "65vh" }}
        rowClassName={() => "editable-row"}
        bordered
        pagination={false}
        isShared={isShared}
      />
      <AdjustWeight
        handleChangeMain={handleChangeMain}
        weightModal={weightModal}
        updatedWeight={updatedWeight}
        id={id}
      />
      <AddNoteModal
        visible={openNote}
        createNoteText={createNoteText}
        noteText={noteText}
        planDetails={planDetails}
        handleChangeMain={handleChangeMain}
        onClose={onCloseNote}
        handleNotesSave={handleNotesSave}
        handleRemoveNote={handleRemoveNote}
        loading={loading}
      />
      <DeleteModal
        visible={deleteModalCat}
        planDetails={planDetails}
        heading={"Delete Category"}
        onClose={() => handleChangeMain("deleteModalCat", false)}
        descriptions={
          <span>
            Are you sure you want to delete the category "<b>{catName}</b>"?
            Deleting it will result in the loss of all related information.
          </span>
        }
        handleRemove={deleteCategory}
        loading={loading}
      />
      <DeleteModal
        visible={deleteModalAction}
        planDetails={planDetails}
        // actionName={actionName}
        isAction
        heading={"Delete Action Item"}
        onClose={() => handleChangeMain("deleteModalAction", false)}
        descriptions={
          <span>
            Are you sure you want to delete the "<b>{actionName}</b>" action
            item? All related information pertaining to the current action item
            will be lost.
          </span>
        }
        // descriptions={
        //   'Are you sure you want to delete the project plan action item?'
        // }
        handleRemove={_deleteActionItem}
        loading={loadingDelete}
      />
      <Modal
        footer={null}
        onCancel={() => handleChangeMain("inviteModal", false)}
        title={false}
        visible={inviteModal}
      >
        <div className="font-18 mb-1">Share this action</div>
        <div>
          {/* Sharing <b className='underline'>{record?.name}</b> action */}
        </div>
        <div className="ownerModalEmail">
          <div className="ownerEmailDiv">{inviteEmail}</div>
        </div>
        <Button
          icon={<MailOutlined />}
          className="inviteButton"
          type="primary"
          loading={inviteLoading}
          disabled={!inviteEmail}
          onClick={handleInvite}
        >
          Invite
        </Button>
      </Modal>
    </Col>
  );
};

export default ActionTable;
