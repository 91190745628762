import { Col, Row, Card, Progress, Modal, Divider, Button } from "antd";
import React, { useEffect, useState } from "react";
import { AppLayout } from "components";
import { ReactComponent as BackIcon } from "assets/svg/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getPlanByID } from "modules/actions/PlanActions";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "constants/colors";
import WeightTable from "./WeightTable";
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
import ActionTable from "./ActionTable";

const AdjustWeight = ({ weightModal, handleChangeMain, updatedWeight, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { planDetails } = useSelector((state) => state.plan.plan);
  // const { id } = useParams()
  const [state, setState] = useState({
    updatedWeight: [],
  });
  // const { updatedWeight } = state
  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleGetPlan = (e) => {
    dispatch(getPlanByID(id));
  };
  useEffect(() => {
    if (id) {
      handleGetPlan();
    }
  }, [id]);

  return (
    // <AppLayout>
    <Modal
      footer={null}
      width={"90%"}
      className={"deleteModal"}
      style={{ top: 20 }}
      closable={false}
      onCancel={() => handleChangeMain("weightModal", false)}
      // title={'Adjust Weight'}
      visible={weightModal}
    >
      <Row justify="space-between" align="middle" className="pt-2 ph-2">
        <div className="font-16 text_black text_bold">Adjust Weight</div>
        <Cross
          onClick={() => handleChangeMain("weightModal", false)}
          className={"c-pointer"}
        />
      </Row>
      <Divider />
      {/* <Row justify='space-between' className='mt-2 '>
        <Col>
          <Row align='middle'>
            <BackIcon
              className='mr-2 c-pointer'
              onClick={() => navigate(-1)}
              style={{ width: 12, height: 12, marginTop: 3 }}
            />
            <div className='font-18 text_500'>{'Adjust Weight'}</div>
          </Row>
        </Col>
      </Row>
      <Row className='mb-2 mt-1' gutter={0}>
        <Col className=''>
          <Card className='mt-1 b-2' bordered={false}>
            <div className='font-16  mb-1'>Sum of All Weights</div>
            <div className=''>
              <Row justify='space-between' align='middle'>
                <Progress
                  percent={Number(planDetails?.progress).toFixed(0)}
                  strokeColor={COLORS.sliderGreen}
                  style={{ width: '65%' }}
                  showInfo={false}
                />
                <div className='font-8 text_red'>{planDetails?.progress}%</div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row> */}
      <WeightTable
        planDetails={planDetails}
        handleChangeMain={handleChangeMain}
      />
    </Modal>
    // </AppLayout>
  );
};

export default AdjustWeight;
