import { Button, Divider, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as Cross } from "assets/svg/cross.svg";
import AppInput from "components/AppInput";

export default function RenamePlanModal({
  handleChange: handleChangeMain,
  visible,
  handleSubmit,
  planDetails,
  loading,
}) {
  const [name, setName] = useState("");

  const handleChange = (_, value) => {
    setName(value);
  };

  useEffect(() => {
    if (visible) {
      setName(planDetails?.name)
    }
  }, [visible, planDetails?.id]);

  const closeAll = () => {
    handleChangeMain("renamePlanModal", false);
  };

  return (
    <Modal
      footer={null}
      closable={false}
      onCancel={closeAll}
      title={false}
      open={visible}
    >
      <Row justify="space-between">
        <div className="font-16 text_black text_bold">Rename Plan</div>
        <Cross onClick={closeAll} className={"c-pointer"} />
      </Row>
      <Divider />
      <AppInput
        placeholder={"Plan Name"}
        value={name}
        onChange={handleChange}
      />
      <Button
        // icon={<MailOutlined />}
        className="inviteButton mt-2 buttonHeight b-1"
        type="primary"
        loading={loading}
        onClick={() => handleSubmit({ name })}
      >
        Save
      </Button>
    </Modal>
  );
}
