import { Button, Divider, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
import AppInput from "components/AppInput";

export default function NoteModal({
  visible,
  handleSubmit,
  loading,
  context: mainContext,
  isEdit,
  closeModal,
}) {
  const [state, setState] = useState({
    context: mainContext || "",
  });
  const { context } = state;
  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    if (isEdit && mainContext) {
      handleChange("context", mainContext);
    }
  }, [mainContext]);
  const clearState = () => {
    handleChange("context", "");
  };

  const closeAll = () => {
    clearState();
    closeModal();
  };
  return (
    <Modal
      footer={null}
      closable={false}
      onCancel={closeAll}
      title={false}
      visible={visible}
    >
      <Row justify="space-between">
        <div className="font-16 text_black text_bold">
          {isEdit ? "Update Note" : "Add New Note"}
        </div>
        <Cross onClick={closeAll} className={"c-pointer"} />
      </Row>
      <Divider />
      <AppInput
        label={"Note"}
        placeholder={"Enter note"}
        borderRadius={4}
        marginBottom={30}
        value={context}
        multiple
        name="context"
        onChange={handleChange}
      />
      <Button
        // icon={<MailOutlined />}
        disabled={!context}
        className="inviteButton mt-2 buttonHeight b-1"
        type="primary"
        loading={loading}
        onClick={() => handleSubmit(context, clearState)}
      >
        {isEdit ? "Update" : "Add"}
      </Button>
    </Modal>
  );
}
