import {
  CREATE_ACTION_ITEM_REQUEST,
  CREATE_NOTES_REQUEST,
  CREATE_PLAN_CATEGORY_REQUEST,
  CREATE_PLAN_REQUEST,
  CREATE_R_FILE_REQUEST,
  CREATE_TOOL_REQUEST,
  DELETE_ACTION_ITEM_REQUEST,
  DELETE_GOAL_REQUEST,
  DELETE_NOTES_REQUEST,
  DELETE_PLAN_CATEGORY_REQUEST,
  DELETE_R_FILE_REQUEST,
  DELETE_TOOL_REQUEST,
  GET_PLAN_BY_ID_REQUEST,
  GET_PLAN_REQUEST,
  REMOVE_PLAN_REQUEST,
  UPDATE_ACTION_ITEM_REQUEST,
  UPDATE_GOAL_REQUEST,
  UPDATE_NOTES_REQUEST,
  UPDATE_PLAN_CATEGORY_REQUEST,
  UPDATE_PLAN_REQUEST,
  UPDATE_RESOURCES_REQUEST,
  UPDATE_R_FILE_REQUEST,
  UPDATE_TOOL_REQUEST,
  CREATE_REMINDER_REQUEST,
  ARCHIVE_PLAN_REQUEST,
  ADJUST_CATEGORY_WEIGHT_REQUEST,
  GET_TOOL_REQUEST,
  CHANGE_ACTION_ITEM_ORDERS_REQUEST,
  CHANGE_PLAN_CATEGORY_ORDERS_REQUEST,
  CREATE_LABEL_AND_UNITS_REQUEST,
  CLONE_PLAN_REQUEST
} from '../reducers/PlanReducer'

export const createPlan = (payload, closeModal, resetState) => ({
  type: CREATE_PLAN_REQUEST,
  payload,
  closeModal,
  resetState
})

export const getPlans = (payload, spread, search) => ({
  type: GET_PLAN_REQUEST,
  payload: { payload, spread, search }
})

export const getPlanByID = (payload, callback) => ({
  type: GET_PLAN_BY_ID_REQUEST,
  payload,
  callback
})

export const updatePlan = (id, payload, closeModal, noAlert) => ({
  type: UPDATE_PLAN_REQUEST,
  id,
  payload,
  closeModal,
  noAlert
})

export const removePlan = (payload, goBack) => ({
  type: REMOVE_PLAN_REQUEST,
  payload,
  goBack
})

export const archivePlan = (payload, goBack) => ({
  type: ARCHIVE_PLAN_REQUEST,
  payload,
  goBack
})

export const createPlanCategory = (payload, removeNewList) => ({
  type: CREATE_PLAN_CATEGORY_REQUEST,
  payload,
  removeNewList
})

export const updatePlanCategory = (id, payload, noAlert) => ({
  type: UPDATE_PLAN_CATEGORY_REQUEST,
  id,
  payload,
  noAlert
})

export const adjustCategoryWeight = (payload, project_plan, closeModal) => ({
  type: ADJUST_CATEGORY_WEIGHT_REQUEST,
  closeModal,
  project_plan,
  payload
})

export const deletePlanCategory = (payload, project_plan, closeModal) => ({
  type: DELETE_PLAN_CATEGORY_REQUEST,
  payload,
  project_plan,
  closeModal
})

export const createActionItem = (payload, goBack) => ({
  type: CREATE_ACTION_ITEM_REQUEST,
  payload,
  goBack
})

export const upateActionItem = (id, payload, project_plan, noAlert) => ({
  type: UPDATE_ACTION_ITEM_REQUEST,
  id,
  project_plan,
  payload,
  noAlert
})

export const deleteActionItem = (payload, project_plan, closeModal) => ({
  type: DELETE_ACTION_ITEM_REQUEST,
  payload,
  project_plan,
  closeModal
})

export const changeActionItemsOrder = (payload, project_plan) => ({
  type: CHANGE_ACTION_ITEM_ORDERS_REQUEST,
  payload,
  project_plan
})

export const changeCategoryItemsOrder = (payload, project_plan) => ({
  type: CHANGE_PLAN_CATEGORY_ORDERS_REQUEST,
  payload,
  project_plan
})

export const createNotes = (payload, project_plan, goBack) => ({
  type: CREATE_NOTES_REQUEST,
  payload,
  project_plan,
  goBack
})

export const upateNotes = (id, payload, project_plan, closeModal) => ({
  type: UPDATE_NOTES_REQUEST,
  id,
  project_plan,
  payload,
  closeModal
})

export const deleteNotes = (payload, project_plan, closeModal) => ({
  type: DELETE_NOTES_REQUEST,
  payload,
  project_plan,
  closeModal
})

export const getTools = () => ({
  type: GET_TOOL_REQUEST
})

export const createTool = (payload, closeModal) => ({
  type: CREATE_TOOL_REQUEST,
  payload,
  closeModal
})

export const upateTool = (id, payload, project_plan) => ({
  type: UPDATE_TOOL_REQUEST,
  id,
  project_plan,
  payload
})

export const deleteTool = (payload, project_plan) => ({
  type: DELETE_TOOL_REQUEST,
  payload,
  project_plan
})

export const createRFile = (payload, project_plan) => ({
  type: CREATE_R_FILE_REQUEST,
  payload,
  project_plan
})

export const upateRFile = (id, payload, project_plan) => ({
  type: UPDATE_R_FILE_REQUEST,
  id,
  project_plan,
  payload
})

export const updateResources = (
  id,
  payload,
  project_plan,
  closeModal,
  message
) => ({
  type: UPDATE_RESOURCES_REQUEST,
  id,
  project_plan,
  payload,
  closeModal,
  message
})

export const deleteRFile = (payload, project_plan) => ({
  type: DELETE_R_FILE_REQUEST,
  payload,
  project_plan
})

export const updateGoal = (id, payload, project_plan, key) => ({
  type: UPDATE_GOAL_REQUEST,
  id,
  project_plan,
  payload,
  key
})

export const deleteGoal = (id, project_plan) => ({
  type: DELETE_GOAL_REQUEST,
  id,
  project_plan
})

export const createReminder = (payload, project_plan, closeModal) => ({
  type: CREATE_REMINDER_REQUEST,
  payload,
  project_plan,
  closeModal
})

export const createCustomLabelAndUnits = (
  payload,
  selectedUnitValue,
  project_plan,
  closeModal
) => ({
  type: CREATE_LABEL_AND_UNITS_REQUEST,
  payload,
  project_plan,
  selectedUnitValue,
  closeModal
})

export const clonePlan = (payload, callback) => ({
  type: CLONE_PLAN_REQUEST,
  payload,
  callback
});

