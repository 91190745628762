import styles from './index.module.scss'

export default function AppInput ({
  value,
  placeholder,
  onChange,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  className,
  error,
  errorMessage,
  postfix,
  prefix,
  label,
  multiple,
  borderRadius,
  backgroundColor,
  borderColor,
  name,
  width,
  onBlur,
  height,
  paddingLeft,
  color,
  onSubmit,
  ...rest
}) {
  return (
    <>
      {label && <label className={styles.inputLabel}>{label}</label>}

      <div
        className={styles.container}
        style={{
          backgroundColor: backgroundColor || '#fff',
          borderRadius: borderRadius || 0,
          padding: borderRadius ? '0px 15px' : '',
          border: `1px solid ${borderColor || '#d7d9db'}`,
          minHeight: multiple ? 100 : height || 'auto',
          width: width || '100%',
          height: multiple ? 'auto' : height || 'auto',
          marginBottom: marginBottom || 0,
          marginTop: marginTop || label ? 5 : 0
        }}
      >
        {prefix && prefix}
        <div className={styles.inputDiv}>
          {multiple ? (
            <textarea
              value={value}
              placeholder={placeholder}
              onBlur={e => onBlur && onBlur(name, e.target.value)}
              onChange={e => onChange(name, e.target.value)}
              className={styles.textarea}
              {...rest}
            />
          ) : (
            <input
              value={value}
              onSubmit={onSubmit}
              placeholder={placeholder}
              onBlur={e => onBlur && onBlur(name, e.target.value)}
              onChange={e => onChange(name, e.target.value)}
              className={styles.input}
              style={{
                height: multiple ? 'auto' : height - 2 || 38,
                paddingLeft: paddingLeft || 15,
                backgroundColor: backgroundColor || '#fff',
                color: color || '#000',
              }}
              {...rest}
            />
          )}
          {error && errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
        </div>
        {postfix && postfix}
      </div>
    </>
  )
}
