import { Button, Checkbox, Radio, Col, Divider, Row, Collapse } from "antd";
import { COLORS } from "constants/colors";
import React, { useState } from "react";
import { ReactComponent as Health } from "assets/svg/sortgroup/Health.svg";
import { ReactComponent as Partner } from "assets/svg/sortgroup/Partner.svg";
import { ReactComponent as Growth } from "assets/svg/sortgroup/Growth.svg";
import { ReactComponent as Career } from "assets/svg/sortgroup/Career.svg";
import { ReactComponent as Contribution } from "assets/svg/sortgroup/Contribution.svg";
import { ReactComponent as Finance } from "assets/svg/sortgroup/Finance.svg";
import { ReactComponent as Social } from "assets/svg/sortgroup/Social.svg";
import { ReactComponent as Sorting } from "assets/svg/sortingIcon.svg";
import { ReactComponent as Leisure } from "assets/svg/sortgroup/Leisure.svg";
import { ReactComponent as Environment } from "assets/svg/sortgroup/Environment.svg";
import { sortbylist, statuses } from "constants/";

const { Panel } = Collapse;

export default function SortBy({
  selectedSortBy,
  // selectedLocations,
  isAscending,
  handleChange,
  // sorting,
  // sortBy,
  decision,
  setDecision,
}) {
  const sortByItem = (item) => {
    if (item === "Name") {
      if (isAscending) {
        setDecision([...decision.sort((a, b) => a.problem - b.problem)]);
        return;
      }
      setDecision([...decision.sort((a, b) => b.problem - a.problem)]);
      return;
    }
    if (item === "Startline") {
      if (isAscending) {
        setDecision([...decision.sort((a, b) => a.startline - b.startline)]);
        return;
      }
      setDecision([...decision.sort((a, b) => b.startline - a.startline)]);
      return;
    }
    if (item === "Deadline") {
      if (isAscending) {
        setDecision([...decision.sort((a, b) => a.deadline - b.deadline)]);
        return;
      }
      setDecision([...decision.sort((a, b) => b.deadline - a.deadline)]);
      return;
    }

    if (item === "Goal Progress") {
      if (isAscending) {
        setDecision([...decision.sort((a, b) => a.deadline - b.deadline)]);
        return;
      }
      setDecision([...decision.sort((a, b) => b.deadline - a.deadline)]);
      return;
    }
  };
  const handleStatus = (title) => {
    if (selectedSortBy === title) {
      handleChange("selectedSortBy", "");
    } else {
      handleChange("selectedSortBy", title);
    }
  };
  // const handleLocation = title => {
  //   if (selectedLocations?.some(e => e === title)) {
  //     const removed = selectedLocations?.filter(e => e !== title)
  //     handleChange('selectedLocations', removed)
  //   } else {
  //     handleChange('selectedLocations', [...selectedLocations, title])
  //   }
  // }
  //
  // const clearFilter = () => {
  //   handleChange('selectedLocations', [])
  //   handleChange('selectedSortBy', '')
  // }

  return (
    <Col className={"sortByDiv"}>
      <div className="text_white mb-1">Sort by</div>
      {sortbylist?.map((item, index) => (
        <div key={index} className={"checkboxDivFilter"}>
          <Radio
            checked={selectedSortBy === item}
            className="mb-1 sortRadio text_white"
            onClick={() => handleStatus(item)}
          >
            {item}
          </Radio>
        </div>
      ))}
      <Divider type="horizontal" className="sortDivider" />
      {/*{!sorting && */}
      {/* ( */}
      <>
        {/* <Divider type='horizontal' /> */}
        {/*{list?.map((item, index) => (*/}
        {/*  <Collapse*/}
        {/*    defaultActiveKey={[]}*/}
        {/*    // activeKey={activeKey}*/}
        {/*    expandIconPosition='right'*/}
        {/*    ghost*/}
        {/*  >*/}
        {/*    <Panel*/}
        {/*      style={{ padding: 0 }}*/}
        {/*      header={*/}
        {/*        <div key={index} className={'checkboxCollapseFilter'}>*/}
        {/*          <Checkbox*/}
        {/*            checked={selectedLocations?.some(e => e === item.title)}*/}
        {/*            className='text_black'*/}
        {/*            onChange={() => handleLocation(item.title)}*/}
        {/*          >*/}
        {/*            {item.title}*/}
        {/*          </Checkbox>*/}
        {/*        </div>*/}
        {/*      }*/}
        {/*      key={index}*/}
        {/*    >*/}
        {/*      {item.sub.map((subItem, subInd) => (*/}
        {/*        <Collapse*/}
        {/*          defaultActiveKey={[]}*/}
        {/*          // activeKey={activeKey}*/}
        {/*          expandIconPosition='right'*/}
        {/*          ghost*/}
        {/*        >*/}
        {/*          <Panel*/}
        {/*            header={*/}
        {/*              <Checkbox*/}
        {/*                className={'checkboxDivFilterSub'}*/}
        {/*                checked={selectedLocations?.some(*/}
        {/*                  e => e === item.title + subItem.title*/}
        {/*                )}*/}
        {/*                onChange={() =>*/}
        {/*                  handleLocation(item.title + subItem.title)*/}
        {/*                }*/}
        {/*              >*/}
        {/*                {subItem.title}*/}
        {/*              </Checkbox>*/}
        {/*            }*/}
        {/*            className='ml-1'*/}
        {/*            key={subInd}*/}
        {/*          ></Panel>*/}
        {/*        </Collapse>*/}
        {/*      ))}*/}
        {/*    </Panel>*/}
        {/*  </Collapse>*/}
        {/*))}*/}
      </>
      {/* )} */}
      <Row justify="space-between" align="middle" className="mt-2">
        <div
          className="AscendingBox"
          onClick={() => handleChange("isAscending", !isAscending)}
        >
          {isAscending ? "Descending" : "Ascending"}
          <Sorting className="ml-1 color-white" />
        </div>
        <Button
          type="primary"
          // onClick={sortBy}
          style={{ borderRadius: 5 }}
          className="whiteButton"
        >
          Sort
        </Button>
      </Row>
    </Col>
  );
}
