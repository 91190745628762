// Collection types
import sidebarItems from './sidebarItems'

export const dummyProfile =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1667416550/Avatar_uxzobx.png'
export const dummyAvatar =
  'https://www.seekpng.com/png/detail/428-4287240_no-avatar-user-circle-icon-png.png'
export const ProfileImageBackground =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1668178319/picture_hbpyyp.png'
export const ProfileImageBackgroundSm =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1668178319/picture-sm_lbx0cw.png'
export const PlusIcon =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1668178319/plus_a2bfmh.svg'

const statuses = [
  { key: 'Active', value: 'in_progress' },
  // { key: 'On hold', value: 'on_hold' },
  { key: 'Completed', value: 'completed' },
  { key: 'Future', value: 'scheduled' }
]
const groupby = ['Status', 'Heirarchy', 'Prepared by']
const sortbylist = [
  'Name',
  // 'Priority',
  'Startline',
  'Deadline',
  'Goal Progress',
  'Plan Progress'
]

const weightsList = [
  { key: '10', value: 1 },
  { key: '9', value: 0.9 },
  { key: '8', value: 0.8 },
  { key: '7', value: 0.7 },
  { key: '6', value: 0.6 },
  { key: '5', value: 0.5 },
  { key: '4', value: 0.4 },
  { key: '3', value: 0.3 },
  { key: '2', value: 0.2 },
  { key: '1', value: 0.1 }
];

const categoryCount = [
  { key: '0', value: 0 },
  { key: '1', value: 0.1 },
  { key: '2', value: 0.2 },
  { key: '3', value: 0.3 },
  { key: '4', value: 0.4 },
  { key: '5', value: 0.5 },
  { key: '6', value: 0.6 },
  { key: '7', value: 0.7 },
  { key: '8', value: 0.8 },
  { key: '9', value: 0.9 },
  { key: '10', value: 1 }
]

const GOAL_INFO =
  'Your Goal Progress shows how close you are to reaching your goal. It measures your current results compared to the results you desire.'
const PLAN_INFO =
  'Your Plan Progress shows how thoroughly you are working on your plan. It measures the completeness of action items.'
const CATEGORY_INFO =
  'Your Result Progress shows how well you navigate the moving parts to reach your goal. It measures the completeness of categories.'

export {
  sidebarItems,
  weightsList,
  categoryCount,
  statuses,
  sortbylist,
  groupby,
  GOAL_INFO,
  PLAN_INFO,
  CATEGORY_INFO
}
