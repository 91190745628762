import { Button, Divider, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
import AppInput from "components/AppInput";
import { onlySpaces } from "utils/utility";
import { toast } from "react-toastify";

export default function AddNoteModal({
  handleChangeMain,
  createNoteText,
  visible,
  handleNotesSave,
  loading,
  noteText,
  id,
  onClose,
  handleRemoveNote,
}) {
  const [state, setState] = useState({
    context: noteText || "",
  });
  const { context } = state;
  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleSubmit = () => {
    if (onlySpaces(context)) {
      toast("Please enter notes", { type: "error" });
      return;
    }
    handleNotesSave(visible, { context });
  };

  useEffect(() => {
    if (noteText) {
      handleChange("context", noteText);
    }
  }, [noteText]);
  const clearState = () => {
    handleChange("context", "");
  };

  const closeAll = () => {
    clearState();
    onClose();
  };

  const handleSubmitLocal = () => {
    handleSubmit();
    clearState();
  };

  return (
    <Modal
      footer={null}
      closable={false}
      onCancel={closeAll}
      title={false}
      visible={visible !== ""}
    >
      <Row justify="space-between">
        <div className="font-16 text_black text_bold">
          {createNoteText ? "Add Note" : "Update Note"}
        </div>
        <Cross onClick={closeAll} className={"c-pointer"} />
      </Row>
      <Divider />
      <AppInput
        label={"Note"}
        placeholder={"Enter Note"}
        borderRadius={4}
        marginBottom={30}
        value={context}
        multiple
        name="context"
        onChange={handleChange}
      />
      <Divider className="width110 mt-4" />
      <Row justify="end" className="mt-3">
        {!createNoteText && (
          <Button
            className="createPlanBtn redButton mr-1"
            type="primary"
            loading={loading}
            onClick={() => handleRemoveNote(visible)}
          >
            Delete
          </Button>
        )}
        <Button
          className="createPlanBtn"
          type="primary"
          disabled={!context}
          loading={loading}
          onClick={handleSubmitLocal}
        >
          Save
        </Button>
      </Row>
    </Modal>
  );
}
