import { Button, Divider, Modal, Row } from 'antd'
import React from 'react'
import { ReactComponent as Cross } from 'assets/svg/deleteIcon.svg'

export default function DeleteNoteModal ({
  visible,
  handleRemove,
  loading,
  heading,
  descriptions,
  onClose,
}) {
  return (
    <Modal
      footer={null}
      className={'deleteModal'}
      closable={false}
      onCancel={onClose}
      title={false}
      visible={visible}
    >
      <Row justify='space-between' align='middle' className='pt-2 ph-2'>
        <div className='font-16 text_black text_bold'>{heading}</div>
        <Cross onClick={onClose} className={'c-pointer'} />
      </Row>
      <Divider />
      <div className='ph-2'>
        <div className='mt-2 mb-2'>{descriptions}</div>
        <Row justify='space-between'>
          <Button
            className='outlinePrimaryButton uppercase halfWidth mb-2'
            type='primary'
            onClick={onClose}
          >
            {'Cancel'}
          </Button>
          <Button
            // icon={<MailOutlined />}
            className='inviteButton buttonHeight redButton uppercase halfWidth b-1'
            type='primary'
            loading={loading}
            onClick={handleRemove}
          >
            Delete permenantly
          </Button>
        </Row>
      </div>
    </Modal>
  )
}
