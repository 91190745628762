import { Layout } from "antd";
import dayjs from "dayjs";
import React from "react";
import { ReactComponent as Linkedin } from "assets/svg/footer/Linkedin.svg";
import { ReactComponent as Youtube } from "assets/svg/footer/Youtube.svg";
import { ReactComponent as Twitter } from "assets/svg/footer/TwitterX.svg";
import { ReactComponent as TwitterOutline } from "assets/svg/footer/TwitterXOutline.svg";
import { ReactComponent as Facebook } from "assets/svg/footer/Facebook.svg";
import { ReactComponent as Instagram } from "assets/svg/footer/Instagram.svg";
import { ReactComponent as Tiktok } from "assets/svg/footer/tiktok.svg";
import { ReactComponent as Pinterest } from "assets/svg/footer/pinterest.svg";

import styles from "./Footer.module.scss";
import useWindowSize from "../../../utils/useWindowSize";
import { useLocation } from "react-router-dom";
import { getProject, PROJECT } from "utils/constants";

export default function Footer() {
  const { REACT_APP_GIT_COMMIT, REACT_APP_GIT_BRANCH } = process.env;
  const { width } = useWindowSize();
  const { pathname } = useLocation();

  const project = getProject(pathname);
  return (
    <>
      {project === PROJECT.DM ? (
        <div className={styles.footer_dark}>
          {width < 1200 && project === PROJECT.DM && (
            <p className={styles.copyright}>
              @arootahcoach for daily inspiration
            </p>
          )}
          <div className={styles.left}>
            {width >= 1200 && (
              <p className={styles.follow}>Follow us @arootahcoach</p>
            )}
            <a href="https://www.instagram.com/arootahcoach/" target={"_blank"}>
              <Instagram className={styles.icon} />
            </a>
            <a href="https://www.facebook.com/arootahcoach" target={"_blank"}>
              <Facebook className={styles.icon} />
            </a>
            <a href="https://twitter.com/arootahcoach" target={"_blank"}>
              <Twitter className={styles.icon} />
            </a>
            {width >= 1200 && (
              <a
                href="https://www.youtube.com/channel/UCq5fhJkfKRUGmjzTvMsFU-w"
                target={"_blank"}
              >
                <Youtube className={styles.icon} />
              </a>
            )}
            <a
              href="https://www.linkedin.com/company/arootahcoach"
              target={"_blank"}
            >
              <Linkedin className={styles.icon} />
            </a>
            {width >= 1200 && (
              <>
                <a href="https://pinterest.com/arootahcoach/" target={"_blank"}>
                  <Pinterest className={styles.icon} />
                </a>
                <a
                  href="
                https://www.tiktok.com/@arootahcoach"
                  target={"_blank"}
                >
                  <Tiktok className={styles.icon} />
                </a>
              </>
            )}
          </div>
          <div>
            {width >= 1200 && (
              <p className={styles.copyright}>
                © Arootah
                {/* {dayjs().year()}. */}&nbsp; Visit arootah.com
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.footer}>
          {width < 1200 && project === PROJECT.DM && (
            <p className={styles.copyright}>
              @arootahcoach for daily inspiration
            </p>
          )}
          <div className={styles.left}>
            {width >= 1200 && (
              <p className={styles.follow}>Follow us @arootahcoach</p>
            )}
            <a href="https://www.instagram.com/arootahcoach/" target={"_blank"}>
              <Instagram className={styles.icon} />
            </a>
            <a href="https://www.facebook.com/arootahcoach" target={"_blank"}>
              <Facebook className={styles.icon} />
            </a>
            <a href="https://twitter.com/arootahcoach" target={"_blank"}>
              <TwitterOutline className={styles.icon} />
            </a>
            {width >= 1200 && (
              <a
                href="https://www.youtube.com/channel/UCq5fhJkfKRUGmjzTvMsFU-w"
                target={"_blank"}
              >
                <Youtube className={styles.icon} />
              </a>
            )}
            <a
              href="https://www.linkedin.com/company/arootahcoach"
              target={"_blank"}
            >
              <Linkedin className={styles.icon} />
            </a>
          </div>
          <div>
            {width >= 1200 && (
              <p className={styles.copyright}>
                Copyright © Arootah.com {dayjs().year()}. All Rights Reserved
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
}
