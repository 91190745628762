import { Button, Col, Row } from 'antd'
import { COLORS } from 'constants/colors'
import React from 'react'
import { ReactComponent as Health } from 'assets/svg/sortgroup/Health.svg'
import { ReactComponent as Partner } from 'assets/svg/sortgroup/Partner.svg'
import { ReactComponent as Growth } from 'assets/svg/sortgroup/Growth.svg'
import { ReactComponent as Career } from 'assets/svg/sortgroup/Career.svg'
import { ReactComponent as Contribution } from 'assets/svg/sortgroup/Contribution.svg'
import { ReactComponent as Finance } from 'assets/svg/sortgroup/Finance.svg'
import { ReactComponent as Social } from 'assets/svg/sortgroup/Social.svg'
import { ReactComponent as Leisure } from 'assets/svg/sortgroup/Leisure.svg'
import { ReactComponent as Environment } from 'assets/svg/sortgroup/Environment.svg'

export default function SortByArea ({ allAreas, handleChange }) {
  const list = [
    { title: 'View all areas' },
    { title: 'Health', icon: <Health /> },
    { title: 'Partner', icon: <Partner /> },
    { title: 'Growth', icon: <Growth /> },
    { title: 'Career', icon: <Career /> },
    { title: 'Contribution', icon: <Contribution /> },
    { title: 'Finance', icon: <Finance /> },
    { title: 'Social', icon: <Social /> },
    { title: 'Leisure', icon: <Leisure /> },
    { title: 'Environment', icon: <Environment /> }
  ]
  const handleArea = title => {
    if (title === 'View all areas') {
      handleChange('allAreas', ['View all areas'])
    } else if (allAreas?.includes(title)) {
      const removed = allAreas?.filter(e => e !== title)
      handleChange('allAreas', removed)
    } else {
      const removed = allAreas?.filter(e => e !== 'View all areas')
      handleChange('allAreas', [...removed, title])
    }
  }
  return (
    <Col className={'mb-2 p-1'}>
      <div className='text_black mb-1'>Show areas</div>
      {list?.map((item, index) => (
        <div
          key={index}
          className={'areaListBox'}
          onClick={() => handleArea(item.title)}
          style={{
            backgroundColor: allAreas?.includes(item.title)
              ? COLORS.fillButton
              : COLORS.grey1,
            color: allAreas?.includes(item.title)
              ? COLORS.white
              : COLORS.fillButton
          }}
        >
          {item?.icon}
          <div className='ml-1'>{item.title}</div>
        </div>
      ))}
      <Row justify='space-between' align='middle' className='mt-2'>
        <div
          className='text_black c-pointer'
          onClick={() => handleChange('allAreas', [])}
        >
          Clear all filters
        </div>
        <Button type='primary' className='fillButton'>
          Save
        </Button>
      </Row>
    </Col>
  )
}
