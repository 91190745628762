import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import classes from "./decisiondetailHeader.module.scss";
import { Col, Dropdown, Popover, Row, Switch, Tooltip } from "antd";
// import { ReactComponent as Logo } from "./logo.svg";
// import { ReactComponent as Car } from "./car.svg";
// import { ReactComponent as WinnerRank } from "./cup_winner.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ChevronIcon } from "./chevron.svg";
import { ReactComponent as OptionsIcon } from "./options.svg";
import { ReactComponent as CupIcon } from "./cup.svg";

import Placeholder from "./Placeholder.jpg";
import { ReactComponent as Cup } from "./cup.svg";
import moment from "moment";
import useWindowSize from "../../../../utils/useWindowSize";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { UpdateDecision } from "redux/DecisionMatrix/UpdateDecision/action";
import { DeleteDecision } from "redux/DecisionMatrix/DeleteDecision/action";
import { ReactComponent as Print } from "./print.svg";
import { ReactComponent as Share } from "./ShareIcon.svg";
import { ReactComponent as Weight } from "./weight.svg";
import debounce from "lodash.debounce";
import cc from "classnames";
import { getCookie, removeCookie, setCookie } from "utils/StorageVariables";
import ReactToPrint from "react-to-print";
import PrintSummary from "../PrintSummary";
import PrintComparison from "../ComparisonSummary";
import PrintFinalist from "../PrintFinalist";
// import ShareModal from "components/ShareModal";
import { ShareDecision } from "redux/DecisionMatrix/ShareDecision/action";

import PublicLinkModal from 'components/PublicLinkModal';

const formatDaysLeft = (days) => {
  if (days < 0) return `0 days left`; // ${Math.abs(days)} days ago
  else if (days > 1) return `${days} days left`;
  else if (days === 0) return `Ends today`;
  else return `Started today`;
};

const Summary = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <PrintSummary
        finalizeOption={props?.finalizeOption}
        decisionId={props?.decisionId}
        decision={props?.decision}
        activeCriterias={props?.activeCriterias}
      />
    </div>
  );
});

const Comparison = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <PrintComparison
        decisionId={props?.decisionId}
        decision={props?.decision}
        activeCriterias={props?.activeCriterias}
      />
    </div>
  );
});
const Finalist = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <PrintFinalist
        decisionId={props?.decisionId}
        decision={props?.decision}
        activeCriterias={props?.activeCriterias}
      />
    </div>
  );
});

const DecisionDetailHeader = ({
  setIsShowFinalizeDecisin,
  options,
  handleColumnToShow,
  columnsToShow,
  isShortList,
  setIsShortList,
  sort,
  isShared,
  isFinalists,
  setIsFinalists,
  isModifyView = true,
  activeCriterias,
}) => {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const componentRef = useRef();
  const comparisonRef = useRef();
  const finalistRef = useRef();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const query = useQuery();
  const secure_token = query.get("secure_token");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { decisionId } = useParams();
  const { width } = useWindowSize();

  const { success: decision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const { success: shareDecision } = useSelector(
    (state) => state.getShareDecisionReducer
  );

  let topOptions = options
    ?.filter((item) => item?.shortlist)
    ?.sort((a, b) => b.adjusted_score - a.adjusted_score)
    ?.slice(0, 3);

  const [finalizeOption, setFinalizeOption] = useState({});
  const [description, setDescription] = useState("");
  const [purpose, setPurpose] = useState("");
  const [tab, setTab] = useState(1);
  const [activeButton, setActiveButton] = useState("finalize_decision");
  const [email, setEmail] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleButtonClick = (tab) => {
    setActiveButton(tab);
  };

  const $input = useRef(null);
  /* const handleShare = () => {
    dispatch(
      ShareDecision(
        {
          email: email,
          decision: decision?.id,
        },
        setIsSuccessful
      )
    );
  }; */
  useEffect(() => {
    if (options && decision) {
      setFinalizeOption({
        ...options?.find(
          (o) => o?.id === decision?.final_option?.id
          // userDecisions?.find((d) => d?.id === decision?.id)?.final_option?.id
        ),
      });

      setDescription(
        decision?.description && !description
          ? decision?.description
          : description
      );
      setPurpose(decision?.purpose && !purpose ? decision?.purpose : purpose);
    }
  }, [options, decision]);

  const updateDecisionHendle = (e) => {
    dispatch(
      UpdateDecision(
        {
          id: decision?.id,
          body: { description, purpose },
        },
        true
      )

      /* SetDecisionDetails({
        id: decision?.id,
        body: { description, purpose },
      }) */
    );
  };

  const onDescriptionChangeHandle = e => {
    let input = e.target.value;

    if (input.length > 256) {
      input = input.slice(0, 256);
    }

    setDescription(input);
    UpdateDescription(description, decision?.id);
  };

  const onDescriptionKeyDownHandle = (evt) => {
    if (evt.keyCode === 13) updateDecisionHendle();
    else if (evt.keyCode === 27) setDescription(decision.description);
  };

  const onPurposeChangeHandle = e => {
    let input = e.target.value;

    if (input.length > 256) {
      input = input.slice(0, 256);
    }

    setPurpose(input);
    UpdatePurpose(purpose, decision?.id);
  };

  const onPurposeKeyDownHandle = (evt) => {
    if (evt.keyCode === 13) updateDecisionHendle();
    else if (evt.keyCode === 27) setPurpose(decision.description);
  };

  const toEditDecision = (e, decision) => {
    e.stopPropagation();
    navigate(`/decision-manager/edit-decision/${decision?.id}`);
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={(e) => toEditDecision(e, decision)}
          >
            Edit
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv "
            onClick={(e) => {
              setIsModalOpen(true);
            }}
          >
            Delete
          </div>
        </>
      ),
    },
  ];

  const UpdateDescription = useCallback(
    debounce((description, id) => {
      dispatch(
        UpdateDecision(
          {
            id,
            body: { description },
          },
          true
        )
      );
    }, 500),
    []
  );

  const UpdatePurpose = useCallback(
    debounce((purpose, id) => {
      dispatch(
        UpdateDecision(
          {
            id,
            body: { purpose },
          },
          true
        )
      );
    }, 500),
    [] // Add dispatch to the dependencies array if you're using it from the component props or from useDispatch hook.
  );

  useEffect(() => {
    let sortingType = JSON.parse(getCookie("isAscending"));
    let obj = sortingType?.find((item) => item?.decId === decisionId);
    if (obj?.decId === decisionId) {
      setIsShortList(obj?.shortlist);
    }
  }, []);

  const daysLeft = moment(decision?.deadline, "YYYY-MM-DD")
    .startOf("day")
    .diff(moment().startOf("day"), "days");
  const handleView = (key) => {
    let updatedIsShortList = isShortList;
    let updatedIsFinalist = isFinalists;

    if (key === "shortlist") {
      updatedIsShortList = !isShortList;
      updatedIsFinalist = false;
      setIsShortList(updatedIsShortList);
      setIsFinalists(updatedIsFinalist);
    } else if (key === "finalist") {
      updatedIsFinalist = !isFinalists;
      updatedIsShortList = false;
      setIsFinalists(updatedIsFinalist);
      setIsShortList(updatedIsShortList);
    }

    const existingCookies = JSON?.parse(getCookie("isAscending")) || [];
    const existingIndex = existingCookies?.findIndex(
      (cookie) => cookie?.decId === decisionId
    );

    let updatedCookies;
    if (existingIndex !== -1) {
      updatedCookies = existingCookies?.map((cookie) => {
        if (cookie?.decId === decisionId) {
          return {
            ...cookie,
            shortlist: updatedIsShortList,
            finalist: updatedIsFinalist,
          };
        }
        return cookie;
      });
    } else {
      updatedCookies = [
        ...existingCookies,
        {
          type: sort.type,
          value: sort.value,
          decId: decisionId,
          shortlist: updatedIsShortList,
          finalist: updatedIsFinalist,
        },
      ];
    }

    setCookie("isAscending", JSON?.stringify(updatedCookies));
  };
  useEffect(() => {
    const existingCookies = JSON?.parse(getCookie("isAscending")) || [];
    const existingIndex = existingCookies?.findIndex(
      (cookie) => cookie?.decId === decisionId
    );

    if (existingIndex !== -1) {
      const cookie = existingCookies[existingIndex];
      setIsShortList(cookie?.shortlist);
      setIsFinalists(cookie?.finalist);
    }
  }, []);

  const PrintItems = [
    {
      key: "1",
      label: (
        <>
          <div
            style={{
              width: "8rem",
              backgroundColor: "#1271a6",
              paddingTop: "8px",
            }}
          >
            {!decision?.final_option ||
              decision?.is_sample ||
              shareDecision?.shared_view ||
              secure_token ? null : (
              <ReactToPrint
                trigger={() => (
                  <div className="cursor-pointer popoverDiv text-nowrap m-auto">
                    Print Winner
                  </div>
                )}
                content={() => componentRef.current}
              />
            )}
            <div style={{ display: "none" }}>
              <Summary
                finalizeOption={finalizeOption}
                ref={componentRef}
                decisionId={decisionId}
                decision={decision}
                activeCriterias={activeCriterias}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div style={{ width: "8rem", backgroundColor: "#1271a6" }}>
            {decision?.is_sample ||
              shareDecision?.shared_view ||
              secure_token ? null : (
              <ReactToPrint
                trigger={() => (
                  <div className="cursor-pointer popoverDiv text-nowrap m-auto">
                    Print Summary
                  </div>
                )}
                content={() => comparisonRef.current}
              />
            )}
            <div style={{ display: "none" }}>
              <Comparison
                activeCriterias={activeCriterias}
                decisionId={decisionId}
                decision={decision}
                ref={comparisonRef}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <div
            style={{
              width: "8rem",
              backgroundColor: "#1271a6",
              paddingBottom: "8px",
            }}
          >
            {decision?.options?.filter((item) => item?.finalist)?.length > 0 ? (
              <ReactToPrint
                trigger={() => (
                  <div className="cursor-pointer popoverDiv text-nowrap m-auto">
                    Print Finalist
                  </div>
                )}
                content={() => finalistRef.current}
              />
            ) : null}
            <div style={{ display: "none" }}>
              <Finalist
                activeCriterias={activeCriterias}
                decisionId={decisionId}
                decision={decision}
                ref={finalistRef}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  // console.log(columnsToShow?.criteriaDescription, "hhhhhhh");

  return (
    <div>
      <ConfirmationModal
        data={decision}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleDelete={() => {
          dispatch(DeleteDecision(decision?.id, setIsModalOpen));
          navigate("/decision-manager");
        }}
      />

      {width > 1199 && (
        <div
          className={classes.wrapper}
          style={{
            backgroundImage: `url(https://res.cloudinary.com/talaash/image/upload/v1676390506/Header_ws5ree.png)`,
          }}
        >
          <div className={classes.leftContent}>
            <div className={classes.leftTopContent}>
              {isShared ? null : <button
                className={classes.back}
                onClick={() => {
                  if (pathname.split("/")?.includes("option")) {
                    navigate(`/decision-manager/${decisionId}`);
                    return;
                  }
                  navigate("/decision-manager");
                }}
              >
                <ChevronIcon />
              </button>}

              <div className={classes.titleWrapper}>
                <div className={classes.title}>
                  <Tooltip title={decision?.problem}>
                    <p>
                      {/* {decision?.problem?.slice(0, 10) +
                        (decision?.problem?.length > 10 ? "..." : "")} */}
                      {decision?.problem}
                    </p>
                  </Tooltip>
                  {decision?.is_sample ||
                    shareDecision?.shared_view ||
                    isShared ||
                    secure_token ? null : (
                    <Dropdown
                      overlayClassName="decision_header_dropdown"
                      menu={{ items }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <OptionsIcon className={classes.optionIcon} />
                      </a>
                    </Dropdown>
                  )}
                </div>
              </div>
              <div className={classes.deadlineWrapper}>
                <span>Deadline:</span>
                <div>{moment(decision?.deadline).format("MM/DD/YYYY")}</div>
              </div>
              <div className={classes.duration}>{formatDaysLeft(daysLeft)}</div>
              {!isModifyView ||
                decision?.is_sample ||
                shareDecision?.shared_view ||
                isShared ||
                secure_token ? null : (
                <div className="me-2">
                  <Popover
                    overlayClassName="detail_header_popup"
                    placement="bottomLeft"
                    content={PrintItems?.map((item) => (
                      <React.Fragment key={item.key}>
                        {item.label}
                      </React.Fragment>
                    ))}
                    trigger="hover"
                  >
                    <div className={classes.printBtn}>
                      <Print />
                      <p className="ps-1">Print</p>
                    </div>
                  </Popover>
                </div>
              )}

              {isShared ? null : <div className="mx-1">
                <div
                  className={classes.printBtn}
                  onClick={() =>
                    setIsShareModalOpen(
                      decision?.is_sample ||
                        shareDecision?.shared_view ||
                        secure_token
                        ? false
                        : true
                    )
                  }
                >
                  <Share />
                  <p className="ps-1">Share</p>
                </div>
              </div>}

              {isShared ? null : <div className="ms-2">
                <button
                  disabled={
                    decision?.is_sample ||
                    shareDecision?.shared_view ||
                    secure_token
                  }
                  className={classes.printBtn}
                  onClick={() => {
                    navigate(
                      `/decision-manager/${decisionId}/option-comparison`
                    );
                  }}
                >
                  <Weight />
                  <p className="ps-1 text-nowrap">Compare Options</p>
                </button>
              </div>}
            </div>

            <div className={classes.leftBottomContent}>
              <div className={classes.solve}>
                <button
                  className={cc(classes.solveBtn, {
                    [classes.active]: tab === 1,
                  })}
                  onClick={() => setTab(1)}
                >
                  Objective
                </button>
                <button
                  className={cc(classes.solveBtn, {
                    [classes.active]: tab === 2,
                  })}
                  onClick={() => setTab(2)}
                >
                  Purpose
                </button>
              </div>

              <div className={classes.linkWrapper}>
                <div className={classes.link}>
                  {tab === 1 && (
                    <div>
                      <textarea
                        className={classes.input}
                        onBlur={(e) => updateDecisionHendle(e)}
                        type="text"
                        rows={2}
                        value={description}
                        ref={$input}
                        onChange={(e) => onDescriptionChangeHandle(e)}
                        onKeyDown={onDescriptionKeyDownHandle}
                        readOnly={
                          decision?.is_sample ||
                          shareDecision?.shared_view ||
                          secure_token ||
                          isShared
                        }
                      />
                    </div>
                  )}

                  {tab === 2 && (
                    <div>
                      <textarea
                        className={classes.input}
                        onBlur={(e) => updateDecisionHendle(e)}
                        type="text"
                        rows={2}
                        value={purpose}
                        ref={$input}
                        onChange={(e) => onPurposeChangeHandle(e)}
                        onKeyDown={onPurposeKeyDownHandle}
                        readOnly={
                          decision?.is_sample ||
                          shareDecision?.shared_view ||
                          secure_token
                        }
                      />
                    </div>
                  )}
                </div>

                <div className={classes.btn_container}>
                  {isShared ? null : <button
                    type="button"
                    className={classes.button}
                    onClick={() =>
                      navigate(
                        `/decision-manager/edit-decision/${decision?.id}`
                      )
                    }
                    disabled={
                      decision?.is_sample ||
                      shareDecision?.shared_view ||
                      secure_token
                    }
                  >
                    Manage Criteria
                  </button>}

                  {isModifyView && (
                    <Popover
                      overlayClassName="modifyViewPopover"
                      placement="bottomLeft"
                      content={
                        <div
                          className="popoverWrapper"
                          style={{ width: "180px" }}
                        >
                          <div className="cursor-pointer popoverDiv">
                            Hide Notes{" "}
                            <span>
                              <Switch
                                checked={columnsToShow?.criteriaDescription}
                                size="small"
                                onChange={(e) =>
                                  handleColumnToShow("criteriaDescription")
                                }
                              />
                            </span>
                          </div>
                          <div className="cursor-pointer popoverDiv text-nowrap">
                            View Shortlist
                            <span>
                              <Switch
                                checked={isShortList}
                                size="small"
                                onChange={() => handleView("shortlist")}
                              />
                            </span>
                          </div>
                          {/* <div className="cursor-pointer popoverDiv text-nowrap">
                          View Finalists
                          <span>
                            <Switch
                              checked={isFinalists}
                              size="small"
                              onChange={() => handleView("finalist")}
                            />
                          </span>
                        </div> */}
                        </div>
                      }
                      trigger="click"
                    >
                      <button type="button" className={classes.modifyButton}>
                        Modify View
                      </button>
                    </Popover>
                  )}
                </div>
              </div>
            </div>

            {finalizeOption.id ? (
              <div className={classes.rightContent}>
                <div className={classes.img}>
                  <img src={finalizeOption.image || Placeholder} alt="" />
                </div>
                <div className={classes.content}>
                  <div className={classes.winnion}>
                    <CupIcon />
                    <span>WINNING OPTION</span>
                  </div>
                  <div className={classes.title}>{finalizeOption.name}</div>
                  <div className={classes.scoreWrapper}>
                    <div className={classes.score}>
                      <div className={classes.block}>
                        Adjusted score:{" "}
                        <div>
                          {isShortList
                            ? `${parseInt(
                              Math.ceil(
                                finalizeOption.second_stage_adjusted_score
                              )
                            )}%`
                            : `${parseInt(
                              Math.ceil(finalizeOption.adjusted_score)
                            )}%`}
                        </div>
                      </div>
                      <div className={classes.block}>
                        Weighted Avg. Score:{" "}
                        <div>
                          {isShortList
                            ? parseInt(finalizeOption.second_stage_weighted_score)
                            : parseInt(finalizeOption.weighted_score)}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className={classes.button}
                      onClick={() => setIsShowFinalizeDecisin(true)}
                      disabled={
                        decision?.is_sample ||
                        shareDecision?.shared_view ||
                        secure_token
                      }
                    >
                      Finalize Decision
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.rightContentTop}>
                <div className={classes.content}>
                  <div className={classes.winnion}>
                    <div>
                      <CupIcon />
                      <span>TOP 3 SHORTLIST OPTIONS</span>
                    </div>
                    {/* <button
                      type="button"
                      className={
                        activeButton === "view_finalist"
                          ? classes.button
                          : classes.inActive
                      }
                      onClick={() => {
                        // setIsShowFinalizeDecisin(true);
                        handleButtonClick("view_finalist");
                      }}
                      disabled={
                        decision?.is_sample || !shareDecision?.shared_view
                      }
                    >
                      VIEW FINALISTS
                    </button> */}
                    <button
                      type="button"
                      className={
                        activeButton === "finalize_decision"
                          ? classes.button
                          : classes.inActive
                      }
                      onClick={() => {
                        setIsShowFinalizeDecisin(true);
                        handleButtonClick("finalize_decision");
                      }}
                      disabled={decision?.is_sample || shareDecision?.shared_view || isShared}
                    >
                      FINALIZE DECISION
                    </button>
                  </div>
                  <div className={classes.topOptionSection}>
                    {activeButton === "finalize_decision" ? (
                      <div>
                        {topOptions?.map((item) => (
                          <div className={classes.optionContainer} key={item?.id}>
                            <img src={item?.image || Placeholder} alt="img" />
                            <div className={classes.content}>
                              <h4>{item?.name}</h4>
                              <p>
                                {isShortList
                                  ? Math.ceil(item?.second_stage_adjusted_score)
                                  : Math.ceil(item?.adjusted_score)}
                                % &nbsp;{" "}
                                {isShortList
                                  ? `${Math.ceil(
                                    item?.second_stage_weighted_score
                                  )}.0`
                                  : Math.ceil(item?.weighted_score)}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p> FINALISTS</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {width < 1199 && (
        <div className={classes.wrapperTablet}>
          <div className={classes.leftContentTabletWrapper}>
            <div>
              <button
                className={classes.backWrapper}
                onClick={() => {
                  if (pathname.split("/").includes("option")) {
                    navigate(`/decision-manager/${decisionId}`);
                    return;
                  }
                  navigate("/decision-manager");
                }}
              >
                <ChevronIcon />
              </button>
            </div>

            <div className={classes.leftContentTablet}>
              <div className={classes.titleTablet}>{decision?.problem}</div>
              <div className={classes.dedlineTabletWrapper}>
                <div>Deadline:</div>
                <div className={classes.dedlineTablet}>
                  {moment(decision?.deadline).format("MM/DD/YYYY")}
                </div>

                <span className={classes.duration}>
                  {formatDaysLeft(daysLeft)}
                </span>
              </div>
            </div>
          </div>

          <div className={classes.rightContentTablet}>
            <button
              type="button"
              className={classes.button}
              onClick={() =>
                navigate(`/decision-manager/edit-decision/${decision?.id}`)
              }
              disabled={decision?.is_sample || !shareDecision?.shared_view}
            >
              Manage Criteria
            </button>

            <Popover
              overlayClassName="modifyViewPopover"
              placement="bottomLeft"
              content={
                <div className="popoverWrapper" style={{ width: "180px" }}>
                  <div className="cursor-pointer popoverDiv">
                    Hide Notes{" "}
                    <span>
                      <Switch
                        checked={columnsToShow?.criteriaDescription}
                        size="small"
                        onChange={(e) =>
                          handleColumnToShow("criteriaDescription")
                        }
                      />
                    </span>
                  </div>
                  <div className="cursor-pointer popoverDiv text-nowrap">
                    View Shortlist
                    <span>
                      <Switch
                        checked={columnsToShow?.isShortlisted}
                        size="small"
                        onChange={() => setIsShortList(!isShortList)}
                      />
                    </span>
                  </div>
                </div>
              }
              trigger="click"
            >
              <button type="button" className={classes.modifyButton}>
                Modify View
              </button>
            </Popover>

            {/* <button
              type="button"
              className={classes.buttonTablet}
              onClick={() => dispatch(TogglePopup())}
            >
              Manage Criteria
            </button> */}
            <button
              type="button"
              className={classes.buttonWinningTablet}
              onClick={() => setIsShowFinalizeDecisin(true)}
            >
              <Cup />
              <span>WINNING OPTION</span>
            </button>

            <Dropdown
              overlayClassName="decision_header_dropdown"
              menu={{ items }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <OptionsIcon />
              </a>
            </Dropdown>
            {/* <Popover
              className={classes.popoverTablet}
              content={content}
              trigger="click"
            >
              <OptionsIcon />
            </Popover> */}
          </div>
        </div>
      )}

      {/* {isShareModalOpen && (
        <ShareModal
          setEmail={setEmail}
          email={email}
          isShareModalOpen={isShareModalOpen}
          setIsShareModalOpen={setIsShareModalOpen}
          onClick={handleShare}
          decision={decision}
          isSuccessful={isSuccessful}
          setIsSuccessful={setIsSuccessful}
        />
      )} */}

      <PublicLinkModal
        isOpen={isShareModalOpen}
        decision={decision}
        onClose={() => setIsShareModalOpen(false)}
      />
    </div>
  );
};

export { DecisionDetailHeader };
