import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Menu, Dropdown } from "antd";
import { ReactComponent as DotsBorderLess } from "assets/svg/dotsBorderLess.svg";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import DeleteNoteModal from "../Modal/DeleteNoteModal";
import styles from "./styles.module.scss";

export default function ProjectPlanDetails({
  planDetails,
  handleUpdatePlan,
  handleChangeMain,
  deleteNoteModal,
  handleRemoveNoteModal,
  handleRemoveNotePress,
  loadingNoteDelete,
  handleUpdateGoal,
  handleEditNoteModal,
}) {
  const [state, setState] = useState({
    purpose: planDetails?.purpose || "",
    name: planDetails?.name || "",
    duration: planDetails?.duration || "",
    description: planDetails?.description || "",
    start_line: moment(planDetails?.start_line) || moment(),
    dead_line: moment(planDetails?.dead_line) || moment(),
    objective: planDetails?.goal?.name || "",
    idea: planDetails?.idea || "",
    note: planDetails?.note || "N/A",
  });

  const {
    purpose,
    name,
    description,
    objective,
    duration,
    start_line,
    dead_line,
    idea,
  } = state;

  useEffect(() => {
    if (moment(start_line).isAfter(moment(dead_line))) {
      handleChangeState("dead_line", "");
    }
  }, [state]);

  useEffect(() => {
    if (planDetails) {
      handleChangeState("name", planDetails?.name);
      handleChangeState("objective", planDetails?.goal?.name);
    }
  }, [planDetails]);

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
    if (key === "start_line" || key === "dead_line") {
      handleUpdate(key, moment(value).format("YYYY-MM-DD"));
    } else {
      handleUpdate(key, value);
    }
  };

  const handleChangeState = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleUpdate = (key, value) => {
    const payload = {
      [key]: value,
    };
    handleUpdatePlan(payload);
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.form}>
          <div className={styles.inputGroup}>
            <label className={styles.label}>Project Name</label>
            <input
              className={styles.input}
              value={name}
              name={"name"}
              onChange={(value) =>
                handleChangeState(value.target.name, value.target.value)
              }
              onBlur={(key, value) =>
                name ? handleUpdate("name", name) : console.log("key,value")
              }
            />
            {!name && (
              <label className={styles.error}>Project name is required</label>
            )}
          </div>

          <Row className="mb-1" gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="mr-2">
                <div className="text_blue_dark mb-1">Start date</div>
                <DatePicker
                  value={start_line}
                  /* disabledDate={(current) => {
                    let customDate = moment().format("MM/DD/YYYY");
                    return (
                      current && current < moment(customDate, "MM/DD/YYYY")
                    );
                  }} */
                  name={"start_line"}
                  format={"MM/DD/YYYY"}
                  clearIcon={false}
                  style={{ width: "100%" }}
                  onChange={(date) => handleChange("start_line", date)}
                  placeholder="MM.DD.YYYY"
                />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              {/* <Card className='b-1 detailsCard radius-2' bordered={false}> */}
              <div className="text_blue_dark mb-1">End date</div>
              <DatePicker
                clearIcon={false}
                status={dead_line ? "" : "error"}
                format={"MM/DD/YYYY"}
                value={dead_line}
                disabledDate={(current) => {
                  // let customDate = moment().format("MM/DD/YYYY");
                  return (
                    (current && current < moment(start_line, "MM/DD/YYYY"))/*  ||
                    (current && current < moment(customDate, "MM/DD/YYYY")) */
                  );
                }}
                style={{ width: "100%" }}
                name={"dead_line"}
                onChange={(date) => handleChange("dead_line", date)}
                placeholder="MM/DD/YYYY "
              />
              {/* </Card> */}
            </Col>
          </Row>

          {/* <Card className='mt-1 b-1 detailsCard radius-2' bordered={false}> */}
          {/* <div className='mb-2'>
            <div className='text_blue_dark mb-1'>Duration</div>
            <AppInput
              value={duration}
              name='duration'
              onChange={handleChangeState}
              onBlur={handleUpdate}
            />
          </div> */}

          <div className={styles.inputGroup}>
            <label className={styles.label}>Objective</label>
            <input
              className={styles.input}
              value={objective}
              name={"objective"}
              onChange={event => {
                let input = event.target.value;

                if (input.length > 255) {
                  input = input.slice(0, 255);
                }

                handleChangeState(event.target.name, input);
              }}
              onBlur={() => handleUpdateGoal("name", objective)}
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Purpose</label>
            <input
              className={styles.input}
              value={purpose}
              name={"purpose"}
              onChange={event => {
                let input = event.target.value;

                if (input.length > 500) {
                  input = input.slice(0, 500);
                }

                handleChangeState(event.target.name, input)
              }}
              onBlur={() => handleUpdate("purpose", purpose)}
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Brainstorm ideas</label>
            <input
              className={styles.input}
              value={idea}
              name={"idea"}
              onChange={(value) =>
                handleChangeState(value.target.name, value.target.value)
              }
              onBlur={() => handleUpdate("idea", idea)}
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Description</label>
            <textarea
              className={styles.input}
              name={"description"}
              value={description}
              multiple
              onChange={(value) =>
                handleChangeState(value.target.name, value.target.value)
              }
              onBlur={() => handleUpdate("description", description)}
            ></textarea>
          </div>
        </div>

        <div className={styles.sidebar}>
          {planDetails?.goal?.notes?.map((note, index) => (
            <div className={styles.note}>
              <div className={styles.head}>
                <h4>Notes</h4>
                <Dropdown
                  trigger={["click"]}
                  className={styles.menuDots}
                  overlay={
                    <Menu
                      className="removeOption"
                      items={[
                        {
                          key: "1",
                          onClick: () => handleEditNoteModal(note?.id, note?.context),
                          label: (
                            <span style={{ color: "#1B2A3D" }}>
                              Edit Note
                            </span>
                          ),
                        },
                        {
                          key: "2",
                          onClick: () => handleRemoveNoteModal(note?.id),
                          label: (
                            <span style={{ color: "#f00" }}>
                              Delete Note
                            </span>
                          ),
                        },
                      ]}
                    />
                  }
                >
                  <DotsBorderLess className="" />
                </Dropdown>
              </div>
              <span>{moment(note?.created_at).fromNow()}</span>
              <p className="c-word-break">{note?.context}</p>
            </div>
          ))}

          <div
            className={styles.addNote}
            onClick={() => {
              handleChangeMain("noteModal", true);
              handleChangeMain("notetype", "goal");
            }}
          >
            <PlusOutlined />
            <p>Add new note</p>
          </div>
        </div>
      </div>

      <DeleteNoteModal
        visible={deleteNoteModal}
        heading={"Delete Note"}
        onClose={() => handleChangeMain("deleteNoteModal", false)}
        descriptions={"Are you sure you want to delete the note?"}
        handleRemove={handleRemoveNotePress}
        loading={loadingNoteDelete}
      />
    </>
  );
}
