import React, { useState } from "react";
import classes from "scenes/DecisionMatrix/components/compare/compare.module.scss";
import { EllipsisOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { Axios } from "../../../../api/axios";
import { apiBaseURL } from "../../../../utils/constants";

const Mitigation = ({
  con,
  editCons,
  mitigationRef,
  addMitigation,
  setAddMitigation,
  cons,
  setCons,
}) => {
  return (
    <div key={+con.id * 0.33} className={classes.prosWrapper}>
      {con?.mitigation && addMitigation !== con?.id ? (
        <div className={classes.mitigation}>
          <div>{con?.mitigation}</div>
        </div>
      ) : (
        addMitigation === con?.id && (
          <input
            onBlur={() => {
              editCons(con?.id);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                editCons(con?.id);
                // setShowInput('')
              }
            }}
            // onChange={(e) => localUpdateCons(e.target.value)}
            ref={mitigationRef}
            value={mitigationRef?.curent?.value}
            placeholder={"Enter a mitigation (Optional)"}
            className={classes.inputMitigation}
            type="text"
            autoFocus={true}
          />
        )
      )}
      {/* <hr className={classes.br} /> */}
    </div>
  );
};

export { Mitigation };
