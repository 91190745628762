import {
  bulkCreateCriteria,
  bulkDeleteCriteria,
  bulkUpdateCriteria,
} from "api";
import { Error } from "utils/Response";
import {
  UpdateDecisionCriterias,
  DeleteDecisionCriterias,
  AssignDecisionCriterias,
  GetDecisionDetails,
} from "../GetDecisionDetails/action";
import { RequestEditDecisionLoading } from "../UpdateDecision/action";
import { ActionTypes } from "./types";

export const UpdateCriteriaRows = (payload) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    if (payload.update.length) {
      const updateResponse = await bulkUpdateCriteria(payload);

      if (updateResponse) {
        await dispatch(UpdateDecisionCriterias(updateResponse.data));
      }
    }
    if (payload.delete.length) {
      await bulkDeleteCriteria(payload);
      await dispatch(DeleteDecisionCriterias(payload.delete));
    }

    if (payload.create.length) {
      const createResponse = await bulkCreateCriteria(payload);

      if (createResponse) {
        await dispatch(AssignDecisionCriterias(createResponse.data));
      }
    }

    await dispatch(GetDecisionDetails(payload.decision, false));

    dispatch({
      type: ActionTypes.SUCCESS,
    });

    dispatch(RequestEditDecisionLoading("second"));

    /* dispatch({
      type: ActionTypes.TOGGLE_POPUP
    }); */
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

/* export const TogglePopup = () => async dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_POPUP
  });
}; */
