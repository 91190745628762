import { Row, Divider, Menu, Dropdown } from "antd";
import React from "react";
import { ReactComponent as Dots } from "assets/svg/dots.svg";
import { ReactComponent as Health } from "assets/svg/sortgroup/Health.svg";
import styles from "./Header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "modules/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  readAllProjectNotification,
  readProjectNotification,
} from "modules/actions/UserActions";

export default function Notification({ open, handleChange }) {
  const count = 100;
  const { notifications } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRead = (notification) => {
    const payload = {
      notification,
    };
    dispatch(readProjectNotification(payload));
  };

  const handleReadAll = () => {
    dispatch(readAllProjectNotification());
  };

  const handleClick = (item) => {
    navigate(
      `/project-planner/plan-details/${item?.project_plan}/?hierarchy=${false}`
    );
    handleRead(item?.id);
  };

  return (
    <div className={styles.NotificationView}>
      <Row
        justify="space-between"
        style={{ padding: 15, paddingTop: 25, zIndex: 999 }}
      >
        <div className="text_black">Notification</div>
        <Row className="d-flex">
          <div className="text_primary c-pointer" onClick={handleReadAll}>
            Mark all as read
          </div>
          {/* <Dropdown
            overlay={
              <Menu
                className="removeOption"
                subMenuCloseDelay={0.1}
                items={[
                  {
                    key: "1",
                    onClick: () => navigate("/settings"),
                    label: <span className="text_red upercase">Settings</span>,
                  },
                ]}
              />
            }
          >
            <Dots className={styles.vdots} />
          </Dropdown> */}
        </Row>
      </Row>
      <Divider style={{ marginTop: 5, marginBottom: 0 }} />
      {notifications?.data?.length > 0 &&
        notifications?.data?.map((item, index) => (
          <>
            <Row
              key={index}
              onClick={() =>
                item?.project_plan
                  ? handleClick(item)
                  : console.log("no plan id")
              }
              style={{ backgroundColor: !item?.read ? "#f3f3f3" : "white" }}
              className={styles.notificationCard}
              justify="space-between"
            >
              {/* <Health /> */}
              <div className="font-12 text_black width70">
                <div className="font-12 text_black">{item?.title}</div>
                <div className="font-12 text_light_grey c-word-break">
                  {item?.body?.slice(0, count) +
                    (item?.body?.length > count ? "..." : "")}
                </div>
              </div>
              <div className="flex-end">
                <div className="font-12 text_black">
                  {moment(item?.created_at).fromNow()}
                </div>
                <Dropdown
                  overlay={
                    <Menu
                      subMenuCloseDelay={0.1}
                      className="removeOption"
                      items={[
                        {
                          key: "1",
                          onClick: () => handleRead(item?.id),
                          label: (
                            <span className="text_red upercase">
                              Mark as read
                            </span>
                          ),
                        },
                      ]}
                    />
                  }
                >
                  <Dots className={styles.hdots} />
                </Dropdown>
              </div>
            </Row>
          </>
        ))}
    </div>
  );
}
