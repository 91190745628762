import React, { useEffect, useRef, useState } from "react";
import cc from "classnames";
import { AppLoading } from "components/AppLoading";
import { AppLayout } from "components/index";
import { v4 as uuidv4 } from "uuid";
import classes from "./EditDecision.module.scss";
import ChooseDecisionHeader from "scenes/DecisionMatrix/components/ChooseDecisionHeader/ChooseDecisionHeader";
import { Checkbox, Col, Popover, Row, Slider } from "antd";
import { ReactComponent as AddIcon } from "./add.svg";
import { ReactComponent as AddIcon2 } from "./add_2.svg";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateCriteriaRows } from "redux/DecisionMatrix/UpdateCriteriaRows/action";
import { ReactComponent as OptionsIcon } from "./options.svg";
import { ReactComponent as Back } from "assets/svg/Back.svg";
import { ScoringRubricModal } from "scenes/DecisionMatrix/components/ScoringRubricModal/scoringRubricModal";
import {
  GetDecisionDetails,
  SetDecisionDetails,
} from "redux/DecisionMatrix/GetDecisionDetails/action";
import {
  RequestEditDecisionLoading,
  UpdateDecision,
} from "redux/DecisionMatrix/UpdateDecision/action";
import FormInput from "components/FormInput/FormInput";
import HelpPopup from "components/HelpPopup";
import { weightDistributionV4 } from "utils/utility";
import {
  AIDefinitionModal,
  AIGenerateCriteriaModal,
  AIScoringRubicModal,
  PurposeModal,
} from "scenes/DecisionMatrix/components";
import moment from "moment";

const EditDecision = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { decisionId } = useParams();

  const [isViewRubric, setIsViewRubric] = useState(null);
  const [criterias, setCriterias] = useState([]);
  const [isWeightInput, setIsWeightInput] = useState("");
  const [isShowInactive, setIsShowInactive] = useState(false);
  const [addCriteriaVal, setAddCriteriaVal] = useState(1);
  const [dragDisabled, setDragDisabled] = useState(false);
  const [isPurposeModal, setIsPurposeModal] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [isRubricModal, setIsRubricModal] = useState(false);
  const [isDefinitionModal, setIsDefinitionModal] = useState(false);
  const [isGenerateCriteriaModal, setIsGenerateCriteriaModal] = useState(false);
  const [popoverID, setPopoverID] = useState(null);
  const [popover1ID, setPopover1ID] = useState(null);
  // const [submitState, setSubmitState] = useState({ first: 0, second: 0 });

  const { success: decision, loading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const { edit } = useSelector((state) => state.updateDecisionReducer);
  const { loading: updateLoading } = useSelector(
    (state) => state.updateCriteriaRowsReducer
  );

  useEffect(() => {
    if (edit.first === 2 && edit.second === 2) {
      dispatch(RequestEditDecisionLoading("reset"));
      navigate(`/decision-manager/${decisionId}`);
    }
  }, [edit]);

  const $formik = useRef(null);

  const schema = yup.object({
    problem: yup
      .string()
      .required("This is a required field")
      .max(250, "Only 250 characters are allowed")
      .matches(/[^\s*].*[^\s*]/g, "This field cannot contain only blankspaces"),
    description: yup
      .string()
      .min(3, "It should be min 3 characters")
      .max(500, "Only 500 characters are allowed")
      .required("This is a required field")
      .matches(/[^\s*].*[^\s*]/g, "This field cannot contain only blankspaces"),
    purpose: yup
      .string()
      .min(3, "It should be min 3 characters")
      .max(500, "Only 500 characters are allowed")
      .required("This is a required field")
      .matches(/[^\s*].*[^\s*]/g, "This field cannot contain only blankspaces"),
    priority: yup.number().required("This is a required field"),
    // startline: yup.date().min(new Date(), "Startline date must be today or later").required("This is a required field"),
    // deadline: yup.date().min(yup.ref("startline"), "Deadline date cannot be before startline date").required("This is a required field"),
    startline: yup
      .date()
      .required("This is a required field")
      .test(
        'not-past',
        "Startline cannot be in the past",
        value => moment().startOf('day').isSameOrBefore(moment(value))
      )
      .when("deadline", (deadline, schema) =>
        deadline ? schema.max(moment(deadline), "Startline must be earlier than the deadline") : schema
      ),
    deadline: yup
      .date()
      .required("This is a required field")
      .min(yup.ref("startline"), "Deadline must be greater than the startline"),
    num_criteria: !decision
      ? yup
          .number()
          .min(3, "Value must be greater than or equal to 3.")
          .typeError("The amount invalid")
          .required("This is a required field")
          .test(
            "no-leading-zero",
            "Leading zero is not allowed",
            (value, context) => {
              return (
                context.originalValue && !context.originalValue.startsWith("0")
              );
            }
          )
      : yup.number(),
  });

  const onFormSubmit = async (values) => {
    dispatch(RequestEditDecisionLoading("init"));

    saveCriterias();

    dispatch(
      UpdateDecision(
        { id: decision.id, body: { ...values, num_criteria: +3 } },
        decisionId && true
      )
    );
  };

  useEffect(() => {
    if (!decisionId) return;
    dispatch(GetDecisionDetails(decisionId));
    return () => {
      dispatch(SetDecisionDetails(null));
    };
  }, [decisionId]);

  let dtToday = new Date();

  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var minDate = year + "-" + month + "-" + day;

  // useEffect(() => {
  //   if (decision?.criteria) {
  //     setCriterias(
  //       [...decision?.criteria?.sort((a, b) => b.weight - a.weight)].map(
  //         (c) => ({ ...c })
  //       )
  //     );
  //   }
  // }, [decision?.criteria]);
  useEffect(() => {
    if (decision?.criteria) {
      setCriterias((prevCriterias) => {
        decision.criteria.sort((a, b) => {
          if (b.weight !== a.weight) {
            return b.weight - a.weight;
          } else {
            return a.title.localeCompare(b.title);
          }
        });

        return decision.criteria.map((c) => ({ ...c }));
      });
    }
  }, [decision?.criteria]);

  const setField = (e, field) => {
    setCriterias(
      criterias.map((criteria) =>
        criteria.id === e.id ? { ...criteria, [field]: e[field] } : criteria
      )
    );
  };

  const onSliderChangeHandle = (id) => (value) => {
    let criteriasraw = criterias
      .filter((c) => c.active && !c.is_deleted)
      .map((obj) => Object.assign({}, obj));
    let criteriasAbove = [...criteriasraw];
    let criteriasBelow = [...criteriasraw];

    const index = criteriasBelow.findIndex((obj) => obj.id === id);

    if (index >= 0) {
      criteriasAbove.splice(index, criteriasAbove.length);
      criteriasBelow.splice(0, index + 1);
    }

    let criteriasAboveTotalWeight = 0;

    for (let i = 0; i < criteriasAbove.length; i++) {
      criteriasAboveTotalWeight += criteriasAbove[i].weight;
    }

    let newValue = 100 - value - criteriasAboveTotalWeight;

    criteriasBelow = [
      ...criteriasraw,
      ...weightDistributionV4(criteriasBelow, newValue),
    ];

    const newCriterias = criteriasraw.map((criteria) =>
      criteria.id === id ? { ...criteria, weight: value } : criteria
    );
    const newWeight = newCriterias.reduce(
      (accumulator, currentValue) => accumulator + currentValue.weight,
      0
    );

    if (
      newWeight === 100 &&
      !newCriterias.find((criteria) => criteria.weight === undefined)
    ) {
      setCriterias(
        criteriasraw.map((criteria) =>
          criteria.id === id ? { ...criteria, weight: value } : criteria
        )
      );
    }
  };

  useEffect(() => {
    if (!activeCriterias) return;
    onSliderChangeHandle(activeCriterias[0]?.id)(activeCriterias[0]?.weight);
  }, []);

  const onAddCriteriaHandle = () => {
    const criteriasModified = [
      ...criterias,
      {
        decision: decisionId,
        title: "",
        description: "",
        rubric: "",
        weight: 100 - totalWeight > 0 ? 100 - totalWeight : 1,
        active: true,
        id: new Date().getTime(),
      },
    ];

    const criteriasWeight = criteriasModified.filter(
      (c) => c.active && !c.is_deleted
    );
    const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

    setCriterias([
      ...criteriasModified.filter((c) => c.is_deleted || !c.active),
      ...criteriasFinal,
    ]);

    setTimeout(() => {
      const result = document.getElementsByClassName("criteria-name");
      const lastItem = result[result.length - 1];

      lastItem.focus();
    }, 150);
  };

  // const onAddAICriteriaHandle = (list) => {
  //   const criteriasModified = [...criterias];
  //   list?.forEach((element) => {
  //     criteriasModified.push({
  //       decision: decisionId,
  //       title: element?.criteria_name,
  //       description: element?.criteria_description,
  //       rubric: "",
  //       weight: 100 - totalWeight > 0 ? 100 - totalWeight : 1,
  //       active: true,
  //       id: new Date().getTime(),
  //     });
  //   });

  //   const criteriasWeight = criteriasModified.filter(
  //     (c) => c.active && !c.is_deleted
  //   );
  //   const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

  //   setCriterias([
  //     ...criteriasModified.filter((c) => c.is_deleted || !c.active),
  //     ...criteriasFinal,
  //   ]);
  //   setIsGenerateCriteriaModal(false);
  // };
  const onAddAICriteriaHandle = (list) => {
    const criteriasModified = [
      ...criterias?.filter((item) => item?.title),
      ...criterias
        ?.filter((item) => !item?.title && !item?.description)
        .map((item) => {
          return { ...item, is_deleted: true };
        }),
    ];

    list?.forEach((element) => {
      criteriasModified.push({
        decision: decisionId,
        title: element?.criteria_name,
        description: element?.criteria_description,
        rubric: "",
        weight: 100 - totalWeight > 0 ? 100 - totalWeight : 1,
        active: true,
        id: uuidv4(),
      });
    });

    const criteriasWeight = criteriasModified.filter(
      (c) => c.active && !c.is_deleted
    );
    const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

    setCriterias([
      ...criteriasModified.filter((c) => c.is_deleted || !c.active),
      ...criteriasFinal,
    ]);
    setIsGenerateCriteriaModal(false);
  };
  const onShowInactiveToggleHandle = () => {
    setIsShowInactive(!isShowInactive);
  };

  const deleteCriteriaHandle = (criteria) => () => {
    if (criteria.created_at) {
      const criteriasPrepare = criterias.map((c) =>
        c.id === criteria.id ? { ...c, is_deleted: true } : c
      );

      const criteriasWeight = criteriasPrepare.filter(
        (c) => c.active && !c.is_deleted
      );
      const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

      setCriterias([
        ...criteriasPrepare.filter((c) => c.is_deleted || !c.active),
        ...criteriasFinal,
      ]);
    } else {
      const criteriasPrepare = criterias.filter((c) => c.id !== criteria.id);

      const criteriasWeight = criteriasPrepare.filter(
        (c) => c.active && !c.is_deleted
      );
      const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

      setCriterias([
        ...criteriasPrepare.filter((c) => c.is_deleted || !c.active),
        ...criteriasFinal,
      ]);
    }
  };

  const onActivateToggleHandle = (criteria) => () => {
    const prepareCriterias = criterias.map((c) =>
      c.id === criteria.id ? { ...c, active: !c.active } : c
    );

    setCriterias([
      ...weightDistributionV4(
        prepareCriterias
          .filter((c) => c.active)
          .map((c, i) => ({ ...c, order: i })),
        100
      ),
      ...prepareCriterias.filter((c) => !c.active),
    ]);
  };

  const saveCriterias = () => {
    const criteriasForCreate = criterias.filter((c) => !c.created_at);
    const criteriasForUpdate = criterias.filter((c) => {
      if (!c.created_at || c.is_deleted) return false;

      const o = decision?.criteria?.find((c2) => c2.id === c.id);

      if (
        o?.title === c?.title &&
        o?.description === c?.description &&
        o?.purpose === c?.purpose &&
        o?.weight === c?.weight &&
        o?.active === c?.active &&
        o?.rubric === c?.rubric
      )
        return false;

      return true;
    });

    const criteriasForDelete = criterias
      .filter((c) => c.created_at && c.is_deleted)
      .map((c) => c.id);

    dispatch(
      UpdateCriteriaRows({
        create: criteriasForCreate,
        update: criteriasForUpdate,
        delete: criteriasForDelete,
        decision: decisionId,
      })
    );
  };

  const activeCriterias = criterias?.filter((c) => c?.active && !c?.is_deleted);
  const inactiveCriterias = criterias?.filter(
    (c) => !c.active && !c.is_deleted
  );

  const totalWeight = activeCriterias.reduce((c, i) => (c += i.weight), 0);

  let objectToCopy = {
    active: true,
    decision: decisionId,
    description: "",
    purpose: "",
    rubric: "",
    id: "",
    title: "",
    weight: 100 - totalWeight > 0 ? 100 - totalWeight : 1,
  };

  const createCopies = (numCopies) => {
    let copies = [];
    for (let i = 0; i < numCopies; i++) {
      let copy = Object.assign({}, objectToCopy);
      copy.id = Date.now() + i;
      copies.push(copy);
    }
    return copies;
  };

  const handleAddCriterias = (e) => {
    e.preventDefault();

    if (addCriteriaVal === 0) return;

    let res = createCopies(addCriteriaVal);

    setAddCriteriaVal(1);

    const criteriasModified = [...criterias, ...res];

    const criteriasWeight = criteriasModified.filter(
      (c) => c.active && !c.is_deleted
    );
    const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

    setCriterias([
      ...criteriasModified.filter((c) => c.is_deleted || !c.active),
      ...criteriasFinal,
    ]);

    setTimeout(() => {
      const result = document.getElementsByClassName("criteria-name");
      const lastItem = result[result.length - 1];

      lastItem.focus();
    }, 150);
  };

  const onRubricClickHandle = (criteria) => () => {
    setIsViewRubric(criteria);
  };

  const onRubricSaveHandle = (criteria, rubrics) => {
    dispatch(
      UpdateCriteriaRows({
        update: decision?.criteria
          ?.filter((crit) => crit?.id === criteria)
          ?.map(({ rubric, ...rest }) => {
            return { ...rest, rubric: rubrics };
          }),
        decision: decisionId,
      })
    );
    setIsViewRubric(null);
  };

  const onSubmitHandle = () => {
    if ($formik.current) {
      $formik.current.handleSubmit();
    }
  };

  const onRubricModal = (criteria) => {
    setSelectedCriteria({ id: criteria?.id, title: criteria?.title });
    setPopoverID(null);
    setPopover1ID(null);
    setIsRubricModal(true);
  };
  const onDefinitionModal = (criteria) => {
    setSelectedCriteria({ id: criteria?.id, title: criteria?.title });
    setPopoverID(null);
    setPopover1ID(null);
    setIsDefinitionModal(true);
  };
  const handleOpenChange = (visible) => {
    if (!visible) {
      setPopoverID(null);
    }
  };
  const handleOpenChange1 = (visible) => {
    if (!visible) {
      setPopover1ID(null);
    }
  };

  const onRubricApplyModal = (criteria, rubric) => {
    setCriterias(
      criterias.map((c) => (c.id === criteria?.id ? { ...c, rubric } : c))
    );
    setSelectedCriteria(null);
    setIsRubricModal(false);
  };

  const handleApplyAi = ({ key, value }) => {
    console.log({ key, value });
    $formik?.current?.setFieldValue(key, value);
    setIsPurposeModal(false);
  };

  const onDefinitionApplyModal = (criteria, description) => {
    setCriterias(
      criterias.map((c) => (c.id === criteria?.id ? { ...c, description } : c))
    );
    setSelectedCriteria(null);
    setIsDefinitionModal(false);
  };

  const onDragEnd = (result) => {
    // if (!result.destination) {
    //   return;
    // }
    // let settedPiority = handlePriority(result, DragData);
    // let orderedArr = handleOrderAgain(
    //   settedPiority,
    //   result?.source?.index,
    //   result?.destination?.index
    // );
    // let obj = {
    //   goal: orderedArr[result?.destination?.index]?.goal?.id,
    //   priority: orderedArr[result?.destination?.index]?.goal?.priority,
    // };
    // setPayload([...payload, obj]);
    // setDragData(orderedArr);
    // handlePrioritize(orderedArr);
  };

  /* const getItemStyle = (isDragging, draggableStyle) => {
    let copyDraggableStyle = draggableStyle;
    copyDraggableStyle.position = isDragging && "fixed";
    return {
      userSelect: "none",
      background: "white",
      margin: "0px 0px",

      ...copyDraggableStyle,
    };
  }; */

  /* const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver && "transparent",
  }); */
  const getDays = (startline, deadline) => {
    const days = Math.ceil(
      (Date.parse(deadline) - Date.parse(startline)) / 1000 / 3600 / 24 || 0
    );
    if (days < 0) return "Invalid date selection";
    return `${days} days.`;
  };

  return (
    <div>
      <div className={cc(classes.body)}>
        <AppLayout>
          <ChooseDecisionHeader />

          <HelpPopup />

          {!!isViewRubric && (
            <ScoringRubricModal
              decision={decision}
              criteria={isViewRubric?.id}
              onSave={onRubricSaveHandle}
              onClose={() => setIsViewRubric(false)}
            />
          )}

          <div className={classes.mainContainer}>
            {loading ? (
              <div className={classes.loader}>
                <AppLoading style={{ margin: "0px" }} />
              </div>
            ) : (
              <div className={classes.templateContainer}>
                <>
                  <h2>Decision Information</h2>

                  <div className={classes.customForm}>
                    <>
                      <div className={classes.container}>
                        <Formik
                          initialValues={{
                            problem: decision?.problem || "",
                            description: decision?.description || "",
                            purpose: decision?.purpose || "",
                            startline: decision?.startline || "",
                            deadline: decision?.deadline || "",
                            num_criteria: decision?.num_criteria || "",
                            priority: decision?.priority || 1,
                          }}
                          validationSchema={schema}
                          validateOnChange={false}
                          onSubmit={async (values) => {
                            await onFormSubmit(values);
                          }}
                          innerRef={$formik}
                        >
                          {({
                            handleSubmit,
                            setFieldValue,
                            isValid,
                            touched,
                            values,
                            errors,
                            handleBlur,
                          }) => (
                            <form>
                              <Row gutter={8}>
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      height="44px"
                                      label={"Decision*"}
                                      name={"problem"}
                                      placeholder={"What is the problem?"}
                                      error={
                                        touched.problem && errors.problem
                                          ? errors.problem
                                          : null
                                      }
                                      setFieldValue={setFieldValue}
                                      value={values.problem}
                                      title={values.problem}
                                      onBlur={handleBlur}
                                      readOnly={decision?.is_sample}
                                    />
                                  </div>
                                </Col>
                                <Col span={4}>
                                  <div className={`${classes.inputField}`}>
                                    <FormInput
                                      height="44px"
                                      label={"Priority*"}
                                      name={"priority"}
                                      error={
                                        touched.priority && errors.priority
                                          ? errors.priority
                                          : null
                                      }
                                      setFieldValue={setFieldValue}
                                      value={values.priority}
                                      type="select"
                                      options={[
                                        { value: 3, label: "High" },
                                        { value: 2, label: "Medium" },
                                        { value: 1, label: "Low" },
                                      ]}
                                    />
                                  </div>
                                </Col>

                                <Col span={4}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      height="44px"
                                      label={"Startline*"}
                                      name={"startline"}
                                      placeholder={"YYYY-MM-DD"}
                                      error={
                                        touched.startline && errors.startline
                                          ? errors.startline
                                          : null
                                      }
                                      type={"date"}
                                      setFieldValue={setFieldValue}
                                      value={values.startline}
                                      min={minDate}
                                      max={values?.deadline}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </Col>
                                <Col span={4}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      height="44px"
                                      label={"Deadline*"}
                                      name={"deadline"}
                                      placeholder={"YYYY-MM-DD%"}
                                      error={
                                        touched.deadline && errors.deadline
                                          ? errors.deadline
                                          : null
                                      }
                                      type={"date"}
                                      max={"100"}
                                      setFieldValue={setFieldValue}
                                      value={values.deadline}
                                      min={values?.startline || minDate}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <div className={classes.duration_container}>
                                      <label>Duration</label>
                                      {values?.startline && values.deadline ? (
                                        <p>
                                          {getDays(
                                            values?.startline,
                                            values?.deadline
                                          )}
                                        </p>
                                      ) : (
                                        <p className={classes.warning}>
                                          Startline & Deadline not selected
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={8} className="mt-2 ">
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      onBlur={handleBlur}
                                      style={{ minHeight: "66px" }}
                                      type="textarea"
                                      label={"Objective*"}
                                      name={"description"}
                                      generateWithAI
                                      generateWithAIClick={() => {
                                        setIsPurposeModal("Objectives");
                                      }}
                                      placeholder={"Enter your description"}
                                      error={
                                        touched.description &&
                                        errors.description
                                          ? errors.description
                                          : null
                                      }
                                      setFieldValue={(n, v) => {
                                        let input = v;

                                        if (input.length > 256) {
                                          input = input.slice(0, 256);
                                        }

                                        setFieldValue(n, input);
                                      }}
                                      value={values.description}
                                    />
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      onBlur={handleBlur}
                                      style={{ minHeight: "66px" }}
                                      type="textarea"
                                      label={"Purpose*"}
                                      generateWithAI
                                      generateWithAIClick={() => {
                                        setIsPurposeModal("Purpose");
                                      }}
                                      name={"purpose"}
                                      placeholder={"Enter your description"}
                                      error={
                                        touched.purpose && errors.purpose
                                          ? errors.purpose
                                          : null
                                      }
                                      setFieldValue={(n, v) => {
                                        let input = v;

                                        if (input.length > 256) {
                                          input = input.slice(0, 256);
                                        }

                                        setFieldValue(n, input);
                                      }}
                                      value={values.purpose}
                                    />
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div
                                    className={classes.inputField_collaborator}
                                  >
                                    {!decision && (
                                      <FormInput
                                        onBlur={handleBlur}
                                        style={{
                                          width: "5rem",
                                          display: "flex",
                                        }}
                                        height="44px"
                                        label={"Criteria"}
                                        name={"num_criteria"}
                                        placeholder={"Add"}
                                        error={
                                          touched.num_criteria &&
                                          errors.num_criteria
                                            ? errors.num_criteria
                                            : null
                                        }
                                        setFieldValue={setFieldValue}
                                        value={
                                          values.num_criteria === 0
                                            ? ""
                                            : values.num_criteria
                                        }
                                        type={"number"}
                                        min="3"
                                      />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </>
                  </div>
                </>

                <div className={classes.decisionTemplateContainer}>
                  <div className={classes.border_top}>
                    <div className={classes.content}>
                      <h6>Criteria</h6>
                      <p>
                        Set up your decision by selecting criteria that fulfills
                        your optimal decision. Next weigh each criteria, with
                        100% being the most significant criteria.
                      </p>
                    </div>
                    <div className={classes.checkboxContainer}>
                      {/* {inactiveCriterias.length ? ( */}
                      <div className={classes.checkbox}>
                        <Checkbox
                          onChange={onShowInactiveToggleHandle}
                          checked={isShowInactive}
                        >
                          Show inactive criteria
                        </Checkbox>
                      </div>
                      {/* ) : null} */}
                    </div>
                  </div>

                  <Row>
                    <Col span={7}>
                      <div
                        onClick={() => setIsGenerateCriteriaModal(true)}
                        className={classes.generateWithAI}
                      >
                        Generate with AI
                      </div>
                    </Col>
                  </Row>
                  <div className={classes.container}>
                    <div className={classes.tableWrapper}>
                      <Row className={cc(classes.table, classes.head)}>
                        <Col span={7}>
                          <div className={classes.column}>
                            <div className={cc(classes.title, classes.first)}>
                              <span>Criteria</span>
                              <button
                                type="button"
                                className={classes.buttonAdd}
                                onClick={onAddCriteriaHandle}
                                disabled={loading || updateLoading}
                              >
                                <AddIcon2 />
                                <span>Add Criteria</span>
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={classes.column}>
                            <div className={classes.title}>
                              <span>Definition</span>
                            </div>
                          </div>
                        </Col>
                        <Col span={9}>
                          {" "}
                          <div className={classes.column}>
                            <div className={cc(classes.title, classes.last)}>
                              <span>Criteria Weight</span>
                              <div
                                className={cc(classes.warning, {
                                  [classes.equall]: totalWeight === 100,
                                })}
                              >
                                Sum of all weights: {totalWeight}%
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {activeCriterias &&
                        activeCriterias?.map((criteria, i) => (
                          <Row className={classes.table} key={criteria.id}>
                            <Col span={7}>
                              <div className={classes.box}>
                                <div
                                  className={classes.criteria}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginRight: "10px",
                                    }}
                                    className={classes.criteria_number}
                                  >
                                    {i + 1}
                                  </span>
                                  <input
                                    className={cc(
                                      classes.input,
                                      "criteria-name"
                                    )}
                                    placeholder={"Name..."}
                                    value={criteria.title}
                                    onChange={(e) =>
                                      setField(
                                        {
                                          title: e.target.value,
                                          id: criteria.id,
                                        },
                                        "title"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col span={8}>
                              <div className={classes.box}>
                                <textarea
                                  className={classes.textarea}
                                  placeholder={"Description..."}
                                  value={criteria.description}
                                  onChange={(e) =>
                                    setField(
                                      {
                                        description: e.target.value,
                                        id: criteria.id,
                                      },
                                      "description"
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col span={6}>
                              <div
                                className={cc(
                                  classes.box,
                                  classes.weight_box,
                                  "d-flex align-items-center"
                                )}
                              >
                                <div
                                  className={cc(
                                    classes.sliderWrapper,
                                    "flex-grow-1"
                                  )}
                                  onMouseOver={() => setDragDisabled(true)}
                                  onMouseLeave={() => setDragDisabled(false)}
                                >
                                  <div className={classes.slider_details}>
                                    <p>Weight</p>
                                    <span
                                      className={classes.sliderPercentage}
                                      onClick={() =>
                                        setIsWeightInput(criteria.id)
                                      }
                                    >
                                      {isWeightInput === criteria.id ? (
                                        <input
                                          className={classes.weightInput}
                                          value={criteria.weight}
                                          type="text"
                                          onChange={(e) =>
                                            setField(
                                              {
                                                weight: +e.target.value,
                                                id: criteria.id,
                                              },
                                              "weight"
                                            )
                                          }
                                          onBlur={() => setIsWeightInput("")}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                              setIsWeightInput("");
                                            }
                                          }}
                                        />
                                      ) : (
                                        <p>{criteria.weight}%</p>
                                      )}
                                    </span>
                                  </div>

                                  <Slider
                                    className="weight_slider"
                                    defaultValue={criteria.weight || 0}
                                    value={criteria.weight}
                                    min={1}
                                    onChange={onSliderChangeHandle(criteria.id)}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col span={3}>
                              <div
                                className={cc(
                                  classes.box,
                                  classes.actionsBox,
                                  classes.rubric_box,
                                  "d-flex justify-content-center align-items-center"
                                )}
                              >
                                <button
                                  className={classes.define_rubric_btn}
                                  type="button"
                                  onClick={onRubricClickHandle(criteria)}
                                >
                                  Define Rubric
                                </button>
                                <Popover
                                  placement="left"
                                  onOpenChange={handleOpenChange}
                                  open={criteria?.id === popoverID}
                                  overlayClassName={"editDecisionDropdown"}
                                  content={
                                    <div className={classes.settings}>
                                      <button
                                        type="button"
                                        onClick={onActivateToggleHandle(
                                          criteria
                                        )}
                                      >
                                        <span>Inactive</span>
                                        <div className={classes.checkbox}>
                                          <Checkbox
                                            checked={!criteria?.active}
                                          ></Checkbox>
                                        </div>
                                      </button>
                                      <Popover
                                        placement="left"
                                        onOpenChange={handleOpenChange1}
                                        open={criteria?.id === popover1ID}
                                        overlayClassName={
                                          "editDecisionDropdown"
                                        }
                                        content={
                                          <div className={classes.settings}>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                onRubricModal(criteria)
                                              }
                                            >
                                              <span>
                                                Suggest Scoring Rubric
                                              </span>
                                            </button>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                onDefinitionModal(criteria)
                                              }
                                            >
                                              Suggest Definition
                                            </button>
                                          </div>
                                        }
                                        trigger="click"
                                      >
                                        <button
                                          onClick={() =>
                                            setPopover1ID(criteria?.id)
                                          }
                                          className={classes.aiassistant}
                                          type="button"
                                        >
                                          <Back className="mr-1" /> AI Assistant
                                        </button>
                                      </Popover>
                                      <span className={classes.bar}></span>
                                      <button
                                        className={classes.delete}
                                        type="button"
                                        onClick={deleteCriteriaHandle(criteria)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  }
                                  trigger="click"
                                >
                                  <span
                                    onClick={() => setPopoverID(criteria?.id)}
                                    className={classes.dropdownToggle}
                                  >
                                    <OptionsIcon />
                                  </span>
                                </Popover>
                              </div>
                            </Col>
                          </Row>
                        ))}
                    </div>

                    <div className={classes.tableWrapper}>
                      {isShowInactive && (
                        <>
                          {inactiveCriterias &&
                            inactiveCriterias?.map((criteria, i) => (
                              <Row
                                className={cc(classes.table, classes.inactive)}
                                key={criteria.id}
                              >
                                <Col span={7}>
                                  <div
                                    className={cc(
                                      classes.box,
                                      classes.inactive
                                    )}
                                  >
                                    <div
                                      className={classes.criteria}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{ marginRight: "10px" }}
                                        className={classes.criteria_number}
                                      >
                                        {i + 1}
                                      </span>
                                      <input
                                        className={cc(
                                          classes.input,
                                          "criteria-name"
                                        )}
                                        placeholder={"Name..."}
                                        defaultValue={criteria.title}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col span={8}>
                                  <div
                                    className={cc(
                                      classes.box,
                                      classes.inactive
                                    )}
                                  >
                                    <textarea
                                      className={classes.textarea}
                                      placeholder={"Description..."}
                                      defaultValue={criteria.description}
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div
                                    className={cc(
                                      classes.box,
                                      classes.inactive,
                                      classes.weight_box,
                                      "d-flex align-items-center"
                                    )}
                                  >
                                    <div
                                      className={cc(
                                        classes.sliderWrapper,
                                        "flex-grow-1"
                                      )}
                                    >
                                      <div className={classes.slider_details}>
                                        <p>Weight</p>
                                        <span
                                          className={classes.sliderPercentage}
                                          onClick={() =>
                                            setIsWeightInput(criteria.id)
                                          }
                                        >
                                          {isWeightInput === criteria.id ? (
                                            <input
                                              className={classes.weightInput}
                                              value={criteria.weight}
                                              type="text"
                                              onChange={(e) =>
                                                setField(
                                                  {
                                                    weight: +e.target.value,
                                                    id: criteria.id,
                                                  },
                                                  "weight"
                                                )
                                              }
                                              onBlur={() =>
                                                setIsWeightInput("")
                                              }
                                              onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                  setIsWeightInput("");
                                                }
                                              }}
                                            />
                                          ) : (
                                            <p>{criteria.weight}%</p>
                                          )}
                                        </span>
                                      </div>
                                      <Slider
                                        className="weight_slider"
                                        defaultValue={criteria.weight || 0}
                                        min={1}
                                        onChange={onSliderChangeHandle(
                                          criteria.id
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col span={3}>
                                  <div
                                    className={cc(
                                      classes.box,
                                      classes.actionsBox,
                                      classes.rubric_box,
                                      "d-flex justify-content-center align-items-center"
                                    )}
                                  >
                                    <button
                                      className={classes.define_rubric_btn}
                                      type="button"
                                      onClick={onRubricClickHandle(criteria)}
                                    >
                                      Define Rubric
                                    </button>
                                    <Popover
                                      placement="left"
                                      overlayClassName={"editDecisionDropdown"}
                                      content={
                                        <div className={classes.settings}>
                                          <button
                                            type="button"
                                            onClick={onActivateToggleHandle(
                                              criteria
                                            )}
                                          >
                                            <span>Active</span>
                                            <div className={classes.checkbox}>
                                              <Checkbox
                                                checked={criteria?.active}
                                              ></Checkbox>
                                            </div>
                                          </button>

                                          <span className={classes.bar}></span>
                                          <button
                                            className={classes.delete}
                                            type="button"
                                            onClick={deleteCriteriaHandle(
                                              criteria
                                            )}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      }
                                      trigger="click"
                                    >
                                      <span className={classes.dropdownToggle}>
                                        <OptionsIcon />
                                      </span>
                                    </Popover>
                                  </div>
                                </Col>
                              </Row>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.footer}>
                  <form
                    className={classes.addOptionContainer}
                    //   onSubmit={onSubmitHandle}
                  >
                    <span>Add More Criteria</span>
                    <input
                      value={addCriteriaVal}
                      onChange={(e) => setAddCriteriaVal(e.target.value)}
                      type="number"
                      max={10}
                      min={1}
                      required
                    />
                    <button
                      onClick={(e) => handleAddCriterias(e)}
                      className={classes.addBtn}
                    >
                      <AddIcon />
                    </button>
                  </form>
                  <span></span>
                  <button
                    disabled={totalWeight !== 100 || loading || updateLoading}
                    onClick={onSubmitHandle}
                    // onClick={saveCriterias}
                  >
                    {loading || updateLoading ? "SAVING..." : "SAVE"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </AppLayout>
      </div>
      {!!isPurposeModal && (
        <PurposeModal
          handleApplyAi={(res) => {
            handleApplyAi(res);
          }}
          defaultValue={isPurposeModal}
          decisionID={decisionId}
          decisionTitle={decision?.problem}
          onClose={() => setIsPurposeModal(false)}
        />
      )}
      {isRubricModal && (
        <AIScoringRubicModal
          decisionID={decisionId}
          criteria={activeCriterias}
          handleApply={onRubricApplyModal}
          onClose={() => setIsRubricModal(false)}
          selectedCriteria={selectedCriteria}
          onSelectedCriteriaChange={(name, value) => {
            let res = activeCriterias?.find(
              (item) => item?.id === parseInt(value)
            );
            setSelectedCriteria({ id: res?.id, title: res?.title });
          }}
        />
      )}
      {isDefinitionModal && (
        <AIDefinitionModal
          decisionID={decisionId}
          criteria={activeCriterias}
          handleApply={onDefinitionApplyModal}
          onClose={() => setIsDefinitionModal(false)}
          selectedCriteria={selectedCriteria}
          onSelectedCriteriaChange={(name, value) => {
            let res = activeCriterias?.find(
              (item) => item?.id === parseInt(value)
            );
            setSelectedCriteria({ id: res?.id, title: res?.title });
          }}
        />
      )}
      {isGenerateCriteriaModal && (
        <AIGenerateCriteriaModal
          criteria={activeCriterias}
          decisionID={decisionId}
          decision={decision?.problem}
          handleApply={onAddAICriteriaHandle}
          onClose={() => setIsGenerateCriteriaModal(false)}
          selectedCriteria={selectedCriteria}
        />
      )}
    </div>
  );
};

export { EditDecision };
